import omit from "lodash/omit";
import { JOBS_RESULTS } from "../../router/routes";
import QueryBuilder from "./QueryBuilder";
import JobSearchQueryParamsDefaults from "./JobSearchQueryParamsDefaults";

export const LocationContainer = {
  city: "",
  state: "",
  latitude: "",
  longitude: "",
};

let isSearchClicked = false;
let openJobDetails = false;
let isFilteredJobs = false;

export class JobSearchQueryBuilder extends QueryBuilder {
  setIsSearchClicked = (action) => {
    isSearchClicked = action;
  };

  getIsSearchClicked = () => isSearchClicked;

  setOpenJobDetails = (action) => {
    openJobDetails = action;
  };

  getOpenJobDetails = () => openJobDetails;

  setIsFilteredJobs = (action) => {
    isFilteredJobs = action;
  };

  getIsFilteredJobs = () => isFilteredJobs;

  getDefaultSpecialties = (defaultSpecialties) => {
    const currentSpecialties = this.getCurrentSpecialties();
    if (currentSpecialties?.length > 0) {
      return currentSpecialties;
    }

    return defaultSpecialties;
  };

  formatSpecialtyWithCode = (item) => (item ? `${item[0]}` : "");

  formatSpecialtyWithCodeFromResults = (item) => `${item[0]} (${item[0]})`;

  parseSpecialtyAndCode = (specialtyStr) => [
    specialtyStr.split(" (")[1].split(")")[0],
    specialtyStr.split(" (")[0],
  ];

  getArrayOfCodesFromSpecialties = (specialties) => {
    const arr = [];
    specialties.forEach((item) => {
      arr.push(item[1]);
    });
    return arr;
  };

  getArrayOfDescriptionFromSpecialties = (specialties) => {
    const arr = [];
    specialties.forEach((item) => {
      if (item.name) {
        arr.push(item.name);
      }
    });
    return arr;
  };

  getSpecialtyNames = (item) => item[1];

  getArrayOfDescriptionAndCodesFromSpecialtiesSuggestions = (specialties) => {
    const arr = [];
    specialties.forEach((item) => {
      const str = this.formatSpecialtyWithCodeFromResults(item);
      arr.push(str);
    });
    return arr;
  };

  getArrayOfDescriptionAndCodesFromSpecialties = (specialties) => {
    const arr = [];
    specialties.forEach((item) => {
      const str = this.formatSpecialtyWithCode(item);
      arr.push(str);
    });
    return arr;
  };

  getArrayOfSpecialtyCodeAndNameFromSpecialties = (specialties) => {
    const arr = [];
    if (specialties && specialties.length > 0) {
      specialties.forEach((item) => {
        arr.push([item.name]);
      });
    }
    return arr;
  };

  getCurrentSpecialties = () => {
    const currentSpecialties = this.currentParams().specialty;
    return this.deserializeSpecialties(currentSpecialties);
  };

  getCurrentRelatedSpecialties = () => {
    const currentRelatedSpecialties = this.currentParams().relatedSpecialties;
    return this.deserializeSpecialties(currentRelatedSpecialties);
  };

  serializeSpecialties = (specialtiesObj) => JSON.stringify(specialtiesObj);

  deserializeSpecialties = (specialtiesStr) => {
    try {
      return JSON.parse(specialtiesStr);
    } catch (error) {
      return new Set();
    }
  };

  getDefaultLocation = () => {
    const defaultLocation = this.setInitialParams().location;
    return this.deserializeLocation(defaultLocation);
  };

  getCurrentLocation = () => {
    const currentLocation = this.currentParams().location;
    return this.deserializeLocation(currentLocation);
  };

  getLocationsArray = (locationParam) => {
    const states = (locationParam?.plainState ?? "")
      .split(",")
      .filter((item) => item.trim() !== "");
    const cityStateCodes = (locationParam?.cityStateCodes ?? "")
      .split(",")
      .filter((item) => item.trim() !== "");
    const cityStateCombination = (locationParam?.city ?? "")
      .split(",")
      .filter((item) => item.trim() !== "")
      .map((item, i) => `${item},${cityStateCodes[`${i}`] || ""}`);
    return [...states, ...cityStateCombination].sort();
  };

  getSpecialtiesArray = (currSpecCodeArray) => {
    let result = null;
    if (currSpecCodeArray && currSpecCodeArray.length > 0) {
      result = currSpecCodeArray.map((item) => (item[0] === item[1] ? item[0] : item));
    }
    return result;
  };

  serializeLocation = (locationObj) => JSON.stringify(locationObj);

  deserializeLocation = (locationStr) => {
    try {
      return JSON.parse(locationStr);
    } catch (error) {
      return {};
    }
  };

  specialtyCombine = (code, name) => `${name} (${code})`;

  specialtyParts = () => {};

  createQueryWithSpecsMap = (map) => {
    const obj = Object.fromEntries(map); // Convert Map to plain object.
    const json = JSON.stringify(obj);      // Stringify the object.
    return json
  };

  /**
 * Helper function to parse the specs map from the query string.
 * Expects a JSON-encoded object.
 *
 * @param {URLSearchParams} params - URLSearchParams instance.
 * @param {string} paramName - Name of the query parameter.
 * @returns {Map} - Returns a new Map or an empty Map if parsing fails.
 */
  getSpecsMapFromQuery = (params, paramName = "specsWithRelSpecs") => {
    const jsonString = params.get(paramName);;
    if (!jsonString) return new Map();
    try {
      const obj = JSON.parse(jsonString);
      return new Map(Object.entries(obj));
    }catch (error) {
      console.error("Error parsing specsAndRelatedSpecs query param:", error);
      return new Map();
    }
  };

  filterQueryObject = (queryObject) => {
    const apiQueryObject = omit(queryObject, ["specialties", "location", "company"]);
    if (apiQueryObject.latitude === "" || apiQueryObject.latitude === "null")
      delete apiQueryObject.latitude;
    if (apiQueryObject.longitude === "" || apiQueryObject.longitude === "null")
      delete apiQueryObject.longitude;
    return apiQueryObject;
  };
}

const jobSearchQueryBuilder = new JobSearchQueryBuilder(
  new JobSearchQueryParamsDefaults(),
  JOBS_RESULTS
);

export default jobSearchQueryBuilder;
