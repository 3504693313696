import { useState, useEffect, useMemo, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import times from "lodash/times";
import queryString from "query-string";
import { Container, Row, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import TuneIcon from "@material-ui/icons/Tune";
import Skeleton from "@material-ui/lab/Skeleton";
import SortIcon from "@material-ui/icons/Sort";
import { makeStyles, Theme } from "@material-ui/core/styles";
import JobsSortV2 from "src/components/common/MainContent/JobResults/JobsSortV2";
import JobListMapView from "src/components/common/MainContent/JobResults/JobListMapView";
import PaginationLimitSelect from "src/components/common/MainContent/JobResults/PaginationLimitSelect";
import JobCardContainerV2 from "src/components/common/MainContent/JobResults/JobCardContainerV2";
import Pagination from "src/components/common/MainContent/JobResults/PaginationV2";
import SearchResultMap from "src/components/common/MainContent/JobResults/SearchResultMap";
import SkeletonUi from "src/components/common/MainContent/Home/Skeleton";
import FilterMaster from "src/components/common/MainContent/Home/FilterMaster";
import DialogDefault from "src/components/common/MainContent/../Dialog/DialogDefault";
import NotificationBox from "src/components/common/MainContent/Home/NotificationBox";
import MyJobsFilterV2 from "src/components/common/MainContent/JobResults/MyJobsFilterV2";
import NoResults from "src/components/common/MainContent/Home/NoResults";
import { updateAggregations } from "src/redux/jobResults/action";
import FilterDistance from "src/components/common/MainContent/Home/FilterDistance";
import SaveSearchPopover from "src/components/common/MainContent/SavedSearches/SavedSearchesPopover";
import MySaveSearches from "src/components/common/MainContent/SavedSearches/MySaveSearches";
import services from "src/components/common/MainContent/SavedSearches/Services/Api/SaveSearchService";
import config from "src/config";
import API from "src/services/Api";
import useHash from "src/hooks/useHash";
import {
  myJobs as myJobsData,
  MyJobsFilters,
  MyJobsFiltered,
} from "src/components/common/MainContent/JobResults/common";
import { RecommendedJobsSourceTypes } from "src/types/receommendedJobsSource";
import { quickApplyEligibilty } from "src/constants/filterConstants";
import savedSearchIcon from "src/imagesV2/savedSearchIcon.svg"
import Image from "react-bootstrap/Image";
import { Divider } from "@material-ui/core";
import styles from "./MyJobsWrapper.module.scss";

const useStyles = makeStyles((theme: Theme & { palette: { colorPrimary: string } }) => ({
  distanceDropdown: {
    "& .MuiFormControl-root": {
      margin: "-4px 4px 0px 2px",
    },
  },
  distanceValue: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: styles?.colorPrimary,
    margin: "0px 2px",
    "@media(min-width: 0px) and (max-width: 768px)": {
      fontSize: "1.2rem",
      margin: "0px -2px 0px 2px",
    },
  },
  distanceValueStatic: {
    fontWeight: "bold",
    color: styles?.colorPrimary,
    fontSize: "0.8rem",
  },
  filterCountMob: {
    width: "18px",
    height: "18px",
    lineHeight: "18px",
    borderRadius: "50%",
    fontSize: "0.75rem",
    color: "#ffffff",
    textAlign: "center",
    marginLeft: "4px",
    backgroundColor: theme.palette.colorPrimary,
    fontWeight: 500,
  },
}));

interface RootState {
  auth: {
    isJobPreferences: boolean;
    candidateId: number;
  };
  myJobs: {
    myJobs: myJobsData;
    filteredJobs: MyJobsFiltered;
    currentPage: number;
    filterSelected: MyJobsFilters;
    loading: boolean;
  };
  candidate: {
    atsBullhornCandidateId: string;
  };
}

interface MyJobsWrapperV2Props {
  pageSort: string;
  pageSize: number;
  viewMap: boolean;
  detectSecondLimitChange: (value: boolean) => void;
  campaignCode: string;
  sourceType: string;
  url: string;
  jobsLoading: boolean;
  jobsSource: RecommendedJobsSourceTypes
}

const MyJobsWrapperV2 = ({
  pageSort,
  pageSize,
  viewMap,
  detectSecondLimitChange,
  campaignCode,
  sourceType,
  url,
  jobsLoading,
  jobsSource
}: MyJobsWrapperV2Props) => {
  let result;
  const { search } = useLocation();
  const filtersToCount = config.FILTERS_TO_COUNT;
  const queryStringValues = search ? queryString.parse(search) : {};
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isJobPreferences = useSelector((state: RootState) => state.auth.isJobPreferences);
  const enableJobLocationSearch = config.ENABLE_SEARCH_JOB_LOCATION === "true";
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogOpenFor, setDialogOpenFor] = useState("");
  const location = queryStringValues?.location;
  const locationObject = location ? JSON.parse(location as string) : {};
  let isExisitingSavedSearch = false
  if( locationObject?.state || locationObject?.latitude || locationObject?.longitude ) {
    isExisitingSavedSearch = true
  }
  const parsedLocationObject = enableJobLocationSearch && !isExisitingSavedSearch
      ? {
          city: locationObject?.city || null,
          states: locationObject?.states || null,
          plainState: locationObject?.plainState || null,
          latitudes: locationObject?.latitudes || null,
          longitudes: locationObject?.longitudes || null,
          cityStates: locationObject?.cityStates || null,
          cityStateCodes: locationObject?.cityStateCodes || null,
          compactStateCheckValue: locationObject?.compactStateCheckValue || null,
          isCompactStateDeleted: locationObject?.isCompactStateDeleted || null,
        }
      : {
          city: locationObject?.city || null,
          state: locationObject?.state || null,
          plainState: locationObject?.plainState || null,
          latitude: locationObject?.latitude || null,
          longitude: locationObject?.longitude || null,
        };
  const salaryMax = queryStringValues?.salaryMax;

  const [equalizeLimit, setEqualizeLimit] = useState(pageSize);
  const [savedSearches, setSavedSearches] = useState(null);
  const [hash, setHash] = useState(undefined);
  const myJobsFilter = useSelector((state: RootState) => state.myJobs.filterSelected);
  const candidateId = useSelector((state: RootState) => state.auth.candidateId);

  const isResultInPathname = window.location.pathname.includes("result");
  const jobsFromStore: myJobsData | MyJobsFiltered | null = useSelector((state: RootState) =>
    isResultInPathname ? state.myJobs.myJobs : state.myJobs.filteredJobs
  );
  const myJobsPage = useSelector((state: RootState) => state.myJobs.currentPage);
  const jobs: myJobsData | null = useMemo(() => {
    if (jobsFromStore) {
      return isResultInPathname ? jobsFromStore : jobsFromStore[String(myJobsFilter)];
    }
    return null;
  }, [jobsFromStore, myJobsFilter]);
  const [excludeQuickApplyFilterInHash, setExcludeQuickApplyFilterInHash] = useState(false)
  const jobsToDisplay = jobs?._embedded?.results ?? null;

  const totalJobs = jobs && jobs.page ? jobs.page.totalElements : null;

  const jobsResultsType = isResultInPathname ? jobs?._embedded?.resultOfType : null;

  const typeOfJobResults = (value: string) => {
    return value === jobsResultsType;
  };
  const jobResults = typeOfJobResults("FilterJobs");
  const isLocationEnteredAndNoJobsRendered = typeOfJobResults("LocationEnteredNoJobs");
  const isLocationEmptyAndNoJobsRendered = typeOfJobResults("LocationNotEnteredNoJobs");
  
  function createSpecialtiesArrayForHashing(specialties) {
    return specialties.map(specialty => {
      return [specialty[0], specialty[0]];
    });
  }

  const searchCriteria = useMemo(() => {
    const {
      durations,
      geoLocationRadius,
      refreshPayFilter,
      shiftLengths,
      shiftTypes,
      sort,
      specialty,
      startDate,
      msp,
      jobDetails,
      datePosted,
      shouldFilterByQuickApplyEligibility,
      relatedSpecialties,
      specsWithRelSpecs
    } = queryStringValues;
    const searchData = {
      specialty: specialty ? createSpecialtiesArrayForHashing(JSON.parse(specialty as string)) : null,
      location: parsedLocationObject,
      shiftTypes: typeof shiftTypes === "string" ? shiftTypes.split(",") : [""],
      shiftLengths: typeof shiftLengths === "string" ? shiftLengths.split(",") : [""],
      durations: typeof durations === "string" ? durations.split(",") : [""],
      jobDetails: typeof jobDetails === "string" ? jobDetails.split(",") : [""],
      startDate: typeof startDate === "string" ? startDate : "",
      salaryMax,
      geoLocationRadius,
      refreshPayFilter,
      sort,
      msp,
      datePosted,
      hasMultipleLocations: JSON.parse(sessionStorage.getItem("showSelectedItems") as string)?.length > 1,
      shouldFilterByQuickApplyEligibility: shouldFilterByQuickApplyEligibility?.toString() === "true",
      specialtyMapping: specsWithRelSpecs ? JSON.parse(specsWithRelSpecs as string) : {},
      relatedSpecialties: relatedSpecialties || ""
    };
    return searchData;
  }, [savedSearches, search]);
  useHash({
    object: searchCriteria || null,
    setHash,
    callBack: [savedSearches, search],
    excludeKeyList: ["size", "view", "sort", "tags", "refreshPayFilter", "hasMultipleLocations", "specialtyMapping"],
    excludeQuickApplyFilterInHash : excludeQuickApplyFilterInHash || queryStringValues?.shouldFilterByQuickApplyEligibility === undefined
  });

  const getSavedSearches = () => {
    services.getAll(candidateId, "savedSearches").then((savedSearchData) => {
      setSavedSearches(savedSearchData.savedSearches || null);
    });
  };
  useEffect(() => {
    if (candidateId && hash) {
      getSavedSearches();
    }
  }, [candidateId, hash]);

  useEffect(() => {
    setEqualizeLimit(pageSize);
  }, [pageSize]);

  const equalizePageLimit = (limit) => {
    setEqualizeLimit(limit);
  };

  const enableFilters = config.ENABLE_FILTERS === "true";
  const enableSavedSearch = config.ENABLE_SAVED_SEARCH === "true";
  const disableShiftTypeFilter = config.DISABLE_SHIFT_TYPE_FILTER === "true";

  const limitMyJob = [15, 30, 45, 60];
  const initialMyJobValue = 15;

  const limitJobSearch = enableFilters ? [15, 30, 45, 60] : [12, 20, 40, 60];
  const initialJobSearchValue = enableFilters ? 15 : 12;

  function checkItemExists<T extends object>(object: T, item: keyof T): boolean {
    return item in object;
  }

  const calculateFilterCount = () => {
    let filterCount = 0;
    if (queryStringValues && Object.keys(queryStringValues).length > 0) {
      filtersToCount.forEach((filter) => {
        if (
          checkItemExists(queryStringValues, filter) &&
          queryStringValues[`${filter}`]!.length > 0 && queryStringValues[`${filter}`] !=="false"
        ) {
          filterCount += 1;
        }
      });
    }
   
    return filterCount;
  };
  const fetchWeeklyPay = async () => {
    API.get(url).then((response) => {
      if (response.data._embedded.aggregations) {
        let aggregation = {};
        aggregation = {
          minSalary: 0,
          maxSalary:
            salaryMax && response.data._embedded.aggregations.max_salary < salaryMax
              ? salaryMax
              : response.data._embedded.aggregations.max_salary,
        };
        dispatch(updateAggregations(aggregation));
        localStorage.setItem("aggregationMinVal", "0");
        localStorage.setItem(
          "aggregationMaxVal",
          salaryMax && response.data._embedded.aggregations.max_salary < salaryMax
            ? salaryMax
            : response.data._embedded.aggregations.max_salary
        );
      }
    });
  };

  useEffect(() => {
    if (queryStringValues && queryStringValues.refreshPayFilter === "true") {
      fetchWeeklyPay();
    }
  }, [jobs]);

  const classes = useStyles();

  const isValidLocation = !!(
    location &&
    location !== "{}" &&
    locationObject.city &&
    locationObject.city !== ""
  );
  const disableDistanceFilter =
    (isLocationEnteredAndNoJobsRendered || jobResults) && isValidLocation;

  let isStateExist = false
  if(isExisitingSavedSearch) {
    isStateExist = !!(
      location &&
      location !== "{}" &&
      Object.prototype.hasOwnProperty.call(locationObject, "plainState")
    )
  } else {
    isStateExist = !!(
      location &&
      location !== "{}" &&
      locationObject.states &&
      locationObject.states !== null &&
      (!locationObject.latitudes || locationObject.latitudes === null) &&
      (!locationObject.longitudes || locationObject.longitudes === null)
    )
  }

  const isLocationEmpty = !!(
    location === "{}" ||
    (locationObject.states === null &&
      locationObject.latitudes === null &&
      locationObject.longitudes === null)
  );

  const hideMilageDropdown = enableJobLocationSearch && (isStateExist || isLocationEmpty );

  if (jobsLoading) {
    result = (
      <Container className="skeletonMain">
        <div className="jobCardsFlexWrapSkeleton">
          {isResultInPathname && enableFilters
            ? times(3, () => (
                <div data-testid="skeleton" key={Math.random()}>
                  <SkeletonUi />
                </div>
              ))
            : times(4, () => (
                <div data-testid="skeleton" key={Math.random()}>
                  <SkeletonUi />
                </div>
              ))}
        </div>
      </Container>
    );
  } else if (jobsToDisplay && jobsToDisplay.length > 0) {
    result = (
      <>
        {enableFilters &&
          (isLocationEmptyAndNoJobsRendered || isLocationEnteredAndNoJobsRendered) && (
            <div data-testid="recommendedJobsHeading" className="recommendedJobText">
              {t("noJobsMatch.recommendedJobsHeading")}
            </div>
          )}
        <JobCardContainerV2
          jobs={
            isResultInPathname
              ? jobsToDisplay
              : jobsToDisplay.slice((myJobsPage - 1) * 12).slice(0, 12)
          }
          campaignCode={campaignCode}
          sourceType={sourceType}
          viewMyJobs
          isRecommendedJobsOnResult={false}
          recommended={false}
          similarOrViewed=""
          jobsSource={jobsSource}
        />
      </>
    );
  } else {
    result = !isResultInPathname ? (
      <div className={`myJobs-noResults ${myJobsFilter}Tab`}>
        {t(`jobResults.noJobsText.${myJobsFilter}`)}
      </div>
    ) : (
      <Container>
        {!enableFilters ? <div>{t("jobResults.searchResult.noJobsMsg")}</div> : null}
      </Container>
    );
  }

  const renderSavedSearch = () => (
    <div>
      <div>
        {enableSavedSearch && (
          <span>
            <SaveSearchPopover
              savedSearches={savedSearches}
              hash={hash}
              candidateId={candidateId}
              searchCriteria={searchCriteria}
              setSavedSearches={setSavedSearches}
            />
          </span>
        )}
      </div>

      <div>
        {enableSavedSearch && Object.keys(savedSearches || {}).length > 0 && (
          <div>
            <div>
              <Divider variant="middle" className="filterDivider savesearch" />
            </div>
            <div>
              <MySaveSearches
                savedSearches={savedSearches}
                hash={hash}
                getSavedSearches={getSavedSearches}
                setExcludeQuickApplyFilterInHash={setExcludeQuickApplyFilterInHash}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
  
  const renderFilters = () => (
    <Container data-testid="searchFilter">
      <Row className="myJobsFilterChipMob">
        {!isMobile && (
          <Col
            sm={12}
            md={9}
            lg={9}
            xs={12}
            className={window.location.pathname.includes("my-jobs") ? "myJobsChipMob" : ""}
          >
            {isResultInPathname ? (
              <div className="mt-2">
                <span
                  className={
                    enableFilters ? "filterResultTxt" : "filterResultTxt filterResultTxtPos"
                  }
                >
                  {!hideMilageDropdown ? `${t("filter.result")} ` : `${t("filter.resultText")} `}
                </span>
                {!hideMilageDropdown && (
                  <span>
                    {enableFilters ? (
                      <span className={classes.distanceDropdown}>
                        <FilterDistance disable={disableDistanceFilter} />
                      </span>
                    ) : (
                      <span
                        className={
                          enableFilters ? classes.distanceValue : classes.distanceValueStatic
                        }
                      >
                        50
                      </span>
                    )}
                    <span className="filterResultTxt filterResultTxtPos">
                      {` ${t("filter.result1")} `}
                    </span>
                  </span>
                )}
                <span className="myJobsTxt">({totalJobs && jobResults ? totalJobs : 0})</span>               
              </div>
            ) : (
              <>
                <div className="sortPadding text-left myJobsMobile">
                  <span className="myJobsTitle">{`${t("jobs.primaryNavbar.myJobs")} `}</span>
                </div>
                <div>
                  <MyJobsFilterV2 />
                </div>
              </>
            )}
          </Col>
        )}

        {isResultInPathname && (
          <Col sm={12} md={3} lg={3} xs={12}>
            <Row>
              <div className="myJobSortWrapper">
                {/* Sort Jobs Component */}
                <Col sm={12} md={4} lg={4} xs={12} className="myJobsChipMob">
                  <JobsSortV2
                    pageSort={pageSort}
                    disabled={
                      enableFilters &&
                      (isLocationEmptyAndNoJobsRendered || isLocationEnteredAndNoJobsRendered)
                    }
                  />
                </Col>
                {/* Map View Component */}
                <Col sm={12} md={4} lg={4} xs={12} className="myJobsChipMob">
                  <JobListMapView
                    viewMap={viewMap}
                    disabled={
                      enableFilters &&
                      (isLocationEmptyAndNoJobsRendered || isLocationEnteredAndNoJobsRendered)
                    }
                  />
                </Col>
                {/* Pagination Limit  Component */}
                <Col sm={12} md={4} lg={4} xs={12} className="showingResV2">
                  <PaginationLimitSelect
                    pageSize={
                      +equalizeLimit ||
                      (isResultInPathname ? initialJobSearchValue : initialMyJobValue)
                    }
                    equalizePageLimit={equalizePageLimit}
                    detectSecondLimitChange={detectSecondLimitChange}
                    detectComponentSequence={1}
                    limit={isResultInPathname ? limitJobSearch : limitMyJob}
                    disabled={
                      enableFilters &&
                      (isLocationEmptyAndNoJobsRendered || isLocationEnteredAndNoJobsRendered)
                    }
                  />
                </Col>
              </div>
            </Row>
          </Col>
        )}
      </Row>
    </Container>
  );

  const renderPagination = () => {
    return (
      <>
        {jobs && !isLocationEmptyAndNoJobsRendered ? (
          <Pagination jobs={jobs} pageSize={jobs.page ? jobs.page.size : pageSize} />
        ) : (
          !isMobile && (
            <div>
              <p>
                <br />
                <br />
              </p>
            </div>
          )
        )}
        {isResultInPathname && (
          <div className="paginationV2 showingResV2">
            <PaginationLimitSelect
              pageSize={
                +equalizeLimit || (isResultInPathname ? initialJobSearchValue : initialMyJobValue)
              }
              equalizePageLimit={equalizePageLimit}
              detectSecondLimitChange={detectSecondLimitChange}
              detectComponentSequence={2}
              limit={isResultInPathname ? limitJobSearch : limitMyJob}
              disabled={
                enableFilters &&
                (isLocationEmptyAndNoJobsRendered || isLocationEnteredAndNoJobsRendered)
              }
            />
          </div>
        )}
      </>
    );
  };

  function renderContent() {
    if (jobsLoading) {
      return (
        <Container className="skeletonMain">
          <Row>
            <Col sm={4} md={4} lg={4} xs={4} />
            <Col sm={4} md={4} lg={4} xs={4}>
              <Skeleton animation="wave" className="paginationSkeletonBtm" />
            </Col>
            <Col sm={4} md={4} lg={4} xs={4}>
              <Skeleton animation="wave" className="paginaSkeletonBtm" />
            </Col>
          </Row>
        </Container>
      );
    }

    if (jobsToDisplay && jobsToDisplay.length !== 0) {
      if (isResultInPathname) {
        return (
          <Container>
            <Row>
              <div className={enableFilters ? "filterCompwidth" : "hidefilterComp"} />
              <div
                className={
                  enableFilters
                    ? "jobCardComp paginationResults"
                    : "jobCardComp pagiResultsNoFilter"
                }
              >
                {renderPagination()}
              </div>
            </Row>
          </Container>
        );
      }

      return (
        <Container>
          <Row>
            <span className="paginationMyJobs">{renderPagination()}</span>
          </Row>
        </Container>
      );
    }

    return null;
  }

  const handleDialogOpen = (type) => {
    setOpenDialog(!openDialog);
    setDialogOpenFor(type);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setDialogOpenFor("");
  };

  const savedSearchSkeleton=()=>(
    <div>
      <MobileView>
      <Skeleton animation="wave" className="savedSearchSkeleton" />
      </MobileView>
      <BrowserView>
      <Skeleton animation="wave" className="savedSearchSkeleton" />
      </BrowserView>
    </div>
  )
  const renderFiltersSkeleton = () => (
    <div className="saveSearchWidth">
      <Container className="skeletonMain">
        <MobileView>
          <div>
            <Skeleton animation="wave" className="filterSkeleton" />
            <Skeleton animation="wave" className="filterSkeleton" />
          </div>
          <div>
            <Skeleton animation="wave" className="filterSkeleton"/>
          </div>
        </MobileView>
        <BrowserView>
          <Row>
            <div className="myJobSortWrapper">
              <Col sm={0} md={6} lg={6} xs={0} className="myJobsChipMob">
                <Skeleton animation="wave" className="filterSkeleton" />
              </Col>
              <Col sm={0} md={6} lg={6} xs={0}>
                <Row>
                  <Col sm={0} md={5} lg={5} xs={0}>
                    <Skeleton animation="wave" className="sortSkeleton" />
                  </Col>
                  <Col sm={0} md={3} lg={3} xs={0}>
                    <Skeleton animation="wave" className="mapSkeleton" />
                  </Col>
                  <Col sm={0} md={3} lg={3} xs={0}>
                    <Skeleton animation="wave" className="paginationSkeleton" />
                  </Col>
                </Row>
              </Col>
            </div>
          </Row>
        </BrowserView>
      </Container>
    </div>
  );

  const renderJobsSearchFilters = () => (
    <div>
    <div className="saveSearchWidth">
      <MobileView>
        <div className="saveSearchMobMain">
          <div className={`searchFilterMob ${enableFilters ? "filterComp" : "hidefilterComp"}`}>
            <Button
              className="jobsTabFilterBtn"
              color="default"
              onClick={() => handleDialogOpen("filter")}
              startIcon={<TuneIcon className="rotate" />}
            >
              {t("jobResults.searchResult.filters").toUpperCase()}
              <span className={classes.filterCountMob}>{calculateFilterCount()}</span>
            </Button>
          </div>
          <div className="searchSortMob">
            <Button
              className="jobsTabFilterBtn"
              color="default"
              onClick={() => handleDialogOpen("sort")}
              startIcon={<SortIcon />}
            >
              {t("jobResults.popularTag.sort").toUpperCase()}
            </Button>
          </div>
        </div>
      </MobileView>
    </div>
      <BrowserView>{renderFilters()}</BrowserView>
    </div>
  );

  const renderMyJobsFilters = () => (
    <div>
      <MobileView>
        <div className="jobSortMobile">
          <p className="myJobsTitle">
            {t("jobs.primaryNavbar.myJobs")} &nbsp;
            {isResultInPathname && <span className="myJobsTxt">({totalJobs || 0})</span>}
          </p>
          {isResultInPathname && (
            <Button
              className="jobsTabFilterBtn"
              color="default"
              onClick={() => handleDialogOpen("sort")}
              startIcon={<SortIcon />}
            >
              {t("jobResults.popularTag.sort").toUpperCase()}
            </Button>
          )}
        </div>

        <MyJobsFilterV2 />
      </MobileView>
      <div>
      <BrowserView>{renderFilters()}</BrowserView>
      </div>
    </div>
  );
 
  const renderSavedSearchesContainer = () => (
    <Container>
      {!jobsLoading && isResultInPathname && enableSavedSearch && (
        <div>
          <div className="d-flex">
            <div className="Savedsearchescontainer">
              <div className="sortFilterstickyMob">
                <Row>
                  <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                    <div>
                      <Image src={savedSearchIcon} className="savedSearchIcon" />
                    </div>
                  </Col>
                  <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                    <div>
                      <span className="savedsearchsubheading">
                        {t("savedSearches.SaveSearchText")}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              {!isMobile && (
                <div>
                  <Row className="filtersRowV2">{!jobsLoading && renderSavedSearch()}</Row>
                </div>
              )}

              <div>{isMobile && !jobsLoading && renderSavedSearch()}</div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );

  const renderJobsSearchResults = () => (
    <Row>
      <div className={enableFilters ? "filterComp" : "hidefilterComp"}>
        <BrowserView>
        {!isMobile && jobsLoading&& savedSearchSkeleton()}  
          {renderSavedSearchesContainer()}
          <FilterMaster jobsLoading={jobsLoading} />
        </BrowserView>
      </div>
      <div className={enableFilters ? "jobCardComp" : "jobCardCompNoFilter"}>
      {!isMobile && jobsLoading && renderFiltersSkeleton()}
        {enableFilters &&
          (isLocationEnteredAndNoJobsRendered || isLocationEmptyAndNoJobsRendered  ) && (
            <Container>
            {(calculateFilterCount() === 1 &&
              (quickApplyEligibilty[0].isActive === true && (isLocationEnteredAndNoJobsRendered || isLocationEmptyAndNoJobsRendered))) ? (
                  <NoResults
                    noResultsTxt={t("noJobsMatch.noJobMatchQuickApply")}
                    noResultsSubTxt={t("noJobsMatch.noJobMatchQuickApplySubTxt")}
                    isCenter=""
                    showEditProfileButton
                  />
                ) : (
                  <NoResults
                    noResultsTxt={t("noJobsMatch.noJobMatchNoFilterTxt")}
                    noResultsSubTxt={t("noJobsMatch.noJobMatchNoFilterSubTxt")}
                    isCenter=""
                    showEditProfileButton={false}
                  />
                )
              }
            </Container>
          )}

        <Container>
          <Row>
            <Col sm={0} md={11} lg={11} xs={0}>
              {viewMap && (
                <div className={viewMap ? "filterMapViewV2" : "hideMapViewV2"}>
                  <SearchResultMap jobs={jobsToDisplay!} />
                </div>
              )}
            </Col>
          </Row>
        </Container>
        {result}
      </div>
    </Row>
  );
 
  return (
    <Container fluid className={isResultInPathname ? "resultsSubWrap" : "myJobsWrap"}>
     {isMobile && jobsLoading && renderFiltersSkeleton()}
     {isMobile && jobsLoading && savedSearchSkeleton()}
     {!jobsLoading && (
        <Container>
          <Row className="filtersRowV2">
            {isResultInPathname ? renderJobsSearchFilters() : renderMyJobsFilters()}
          </Row>
        </Container>
      )}
      {isJobPreferences && isResultInPathname && enableFilters && !disableShiftTypeFilter && (
        <BrowserView>
          <Container>
            <Row>
              <NotificationBox />
            </Row>
          </Container>
        </BrowserView>
      )}
      {isJobPreferences && isResultInPathname && enableFilters && !disableShiftTypeFilter && (
        <MobileView>
          <Container>
            <Row>
              <NotificationBox />
            </Row>
          </Container>
        </MobileView>
      )}
      {openDialog && dialogOpenFor === "filter" && isResultInPathname && (
        <MobileView>
          <DialogDefault open={openDialog} handleClose={handleDialogClose}>
            <FilterMaster jobsLoading={jobsLoading} />
          </DialogDefault>
        </MobileView>
      )}

      {openDialog && dialogOpenFor === "sort" && (
        <MobileView>
          <DialogDefault open={openDialog} handleClose={handleDialogClose}>
            {renderFilters()}
          </DialogDefault>
        </MobileView>
      )}

      <Container>
        {isMobile && isResultInPathname && enableSavedSearch && (
          <div>
            {renderSavedSearchesContainer()}
            <Row>
              <Col sm={12} md={6} lg={6} xs={12} className="mt-4">
                <span className="filterResultTxt">
                  {!hideMilageDropdown ? `${t("filter.result")} ` : `${t("filter.resultText")} `}
                </span>
                {!hideMilageDropdown && (
                  <span>
                    {enableFilters ? (
                      <span className={classes.distanceDropdown}>
                        <FilterDistance disable={disableDistanceFilter} />
                      </span>
                    ) : (
                      <span className={classes.distanceValue}>50</span>
                    )}
                    <span className="filterResultTxt pl-1">{` ${t("filter.result1")} `}</span>
                  </span>
                )}
                <span className="myJobsTxt">({totalJobs && jobResults ? totalJobs : 0})</span>
              </Col>
            </Row>
          </div>
        )}
      </Container>

      {viewMap && !isResultInPathname && (
        <Container fluid className="mapViewV2">
          <SearchResultMap jobs={jobsToDisplay!} />
        </Container>
      )}

      <Row>
        <Col sm={0} md={12} lg={12} xs={0}>
          <Container className="mainFilter">
            {isResultInPathname ? renderJobsSearchResults() : <Row>{result}</Row>}
          </Container>
        </Col>
      </Row>
      {renderContent()}
    </Container>
  );
};

export default memo(MyJobsWrapperV2);
