/* eslint-disable react/jsx-props-no-spreading */
import { jobDetailType } from "src/components/common/MainContent/JobResults/common";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import { getAccessToken } from "axios-jwt";
import { graphqlRequest } from "src/services/GraphQL/GraphQL";
import { SEARCH_JOBS_WITH_VIEWS } from "src/services/GraphQL/graph.query";
import getCandidateInformation from "src/components/common/CandidateInformation/CandidateInformation";
import BoltIcon from "@mui/icons-material/Bolt";
import QuickApplyBoltWhite from "src/imagesV2/boltWhite.svg";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import { RecommendedJobsSourceTypes } from "src/types/receommendedJobsSource";
import JobSlider from "../../job-card/slider/JobSlider";
import JobCard, { JobCardProps } from "../../job-card/JobCard";
import styles from "./QuickApplyEligible.module.scss";

interface SearchJobsWithViews {
  searchJobsWithViews: {
    embedded: {
      results: jobDetailType[];
    };
  };
}

interface QuickApplyEligibleProps {
  onboardingInfo: any;
  companyId: any;
  atsBullhornCandidateId: any;
  bhId: any;
}

const QuickApplyEligible = ({
  onboardingInfo,
  companyId,
  atsBullhornCandidateId,
  bhId
}: QuickApplyEligibleProps) => {
  const { t } = useTranslation();

  const [jobs, setJobs] = useState<jobDetailType[]>([]);
  const skeletonCardOptions = Array.from({ length: 4 }).map((_) => ({
    job: undefined,
    contentOptions: {},
    className: clsx(styles.card)
  } as JobCardProps));
  const jobCardOptions = jobs.map((job) => ({
    job,
    contentOptions: {
      secondaryInfo: "quick-apply"
    },
    className: clsx(styles.card),
    campaignCode: "Quick Apply Eligible",
    sourceType: "Homepage",
    jobsSource: RecommendedJobsSourceTypes.NONE,
  } as JobCardProps)) ?? [];

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasFeteched, setHasFetched] = useState<boolean>(false);

  const { specialtiesAndEducation: onboardingSpecialty, completedSteps, isCredentialsLoading } = onboardingInfo;

  const authToken = getAccessToken();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.only("sm"));

  useEffect(() => {
    if(!isCredentialsLoading){   
    const candidate = getCandidateInformation(onboardingInfo);
    const getJobs = async () => {
      const response = await graphqlRequest<SearchJobsWithViews>(SEARCH_JOBS_WITH_VIEWS, {
        professions: onboardingSpecialty?.specialty?.profession || null,
        specialties: onboardingSpecialty?.specialty?.primarySpecialty || null,
        companyId: Number(companyId),
        candidateId: Number(atsBullhornCandidateId || bhId),
        includeQuickApplyEligibility: Object.keys(completedSteps).length === 5,
        candidateInformation: Object.keys(completedSteps).length === 5 ? candidate : {},
        isProfileComplete: Object.keys(completedSteps).length === 5,
      }, authToken);

      setJobs(response?.searchJobsWithViews?.embedded?.results ?? []);
    }
    
    if ((atsBullhornCandidateId || bhId) && companyId && onboardingSpecialty && hasFeteched === false) {
      setHasFetched(true);
      
      getJobs().then(() => {
        setIsLoading(false);
      }).catch((error) => {
        console.error("Error:", error);
      });
    }
  }
  }, [(atsBullhornCandidateId || bhId), companyId, onboardingSpecialty, isCredentialsLoading]);

  if (!isLoading && (jobs.length === 0 || jobs.every(job => job?.quickApplyEligibility?.eligibilityPercentage=== 0)) ) {
    return null;
  }

  return (
    <div className={styles.main}>
      <div className={styles.sub}>
        <div className={styles.heading}>
          <BoltIcon />
          {t("dashboard.jobEligibility.quickApplyELigible")}
        </div>

        <div className={styles.jobSlider}>
          <div className={styles.description}>
            <div className={styles.descriptionHeading}>
              {t("dashboard.jobEligibility.heading")}
            </div>
            <div className={styles.descriptionText}>
              {t("dashboard.jobEligibility.quickApplyMobileText")}
            </div>
          </div>
          <JobSlider
            jobCardOptions={isLoading ? skeletonCardOptions : jobCardOptions}
            settings={{
              xs: {
                swipe: !isLoading,
              }
            }}
          />
        </div>

        <div className={styles.jobCardListContainer}>
          <div className={styles.jobCardList}>
            <div className={styles.description}>
              {isSmall && (
                <>
                  <div className={styles.descriptionHeading}>
                    {t("dashboard.jobEligibility.heading")}
                  </div>
                  <div className={styles.descriptionText}>
                    {t("dashboard.jobEligibility.quickApplyMobileText")}
                  </div>
                </>
              )}

              {!isSmall && (
                <>
                  <div className={styles.descriptionHeading}>
                    {t("dashboard.jobEligibility.heading")}
                  </div>
                  <div className={styles.descriptionText}>
                    {t("dashboard.jobEligibility.quickApplyDescStart")}
                    <strong>
                      <img className={styles.icon} src={QuickApplyBoltWhite} alt="bolt" />
                      {t("dashboard.jobEligibility.quickApply")}
                    </strong>
                    {t("dashboard.jobEligibility.quickApplyDescEnd")}
                  </div>
                  <div className={styles.descriptionText}>
                    {t("dashboard.jobEligibility.quickApplyDescStart2")}
                    <strong>
                      {t("dashboard.jobEligibility.quickApply")}
                    </strong>
                    {t("dashboard.jobEligibility.quickApplyDescEnd2")}
                  </div>
                </>

              )}
            </div>

            {(isLoading ? skeletonCardOptions : jobCardOptions).map((options) => (
              <JobCard
                key={options?.job?.id ?? uuidv4()}
                {...options}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  onboardingInfo: state.onBoarding,
  companyId: state.company.company.id,
  atsBullhornCandidateId: state.candidate.atsBullhornCandidateId,
  bhId: state.candidate.bullhornIdFromBullhorn
});

export default connect(mapStateToProps)(withTranslation()(QuickApplyEligible));