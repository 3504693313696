import { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getAccessToken } from "axios-jwt";
import { useSelector, useDispatch } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import queryString from "query-string";
import {
  JOBS_SEARCH,
  MY_JOBS,
  RECOMMENDED_JOBS,
  MATCH_RECOMMENDED_JOBS,
} from "src/constants/campaigns_constants";
import config from "src/config";
import API from "src/services/Api";
import LocationSpecialities from "src/components/common/MainContent/Home/LocationSpecialities";
import {
  FETCH_MY_JOBS_SUCCESS,
  fetchMyJobs,
  fetchMyJobsRequest,
  fetchMyJobsFailure,
  setMyJobsFilter,
} from "src/redux/jobs/MyJob/action";
import jobSearchQueryBuilder from "src/services/QueryBuilder/JobSearchQueryBuilder";
import Analytics from "src/services/Analytics";
import analyticsSegmentIdentify from "src/services/SegmentIdentify";
import MyJobsWrapperV2 from "src/components/common/MainContent/JobsTab/MyJobs/MyJobsWrapperV2";
import {
  EmailRecruiterV2Props,
  myJobs as MyJobs,
  MyJobsFiltered,
} from "src/components/common/MainContent/JobResults/common/index";
import getCandidateInformation, {
  CandidateBasicInfo,
  SpecialtiesAndEducation,
  Reference,
  SkillsChecklists,
  WorkExperience,
  CandidateCredentials,
} from "src/components/common/CandidateInformation/CandidateInformation";
import { RecommendedJobsSourceTypes } from "src/types/receommendedJobsSource";
import { quickApplyEligibilty } from "src/constants/filterConstants";

interface RootState {
  auth: any;
  company: { company: { code: string; id: string; name: string; url: string } };
  consultant: {
    recruiterDetails: EmailRecruiterV2Props["recruiterDetails"];
  };
  globalSearch: {
    specsAndRelatedSpecs: Map<string, string[]>;
  },
  onBoarding: {
    isCredentialsLoading: boolean;
    specialtiesAndEducation: {
      specialty: {
        profession: string;
        primarySpecialty: string;
        secondarySpecialty: null;
        yearsOfExperience: 2;
      };
      educationList: [
        {
          degree: string;
          graduationDate: Date;
          vocationalSchool: string;
          city: string;
          state: string;
          trainingCountry: null;
          certificationExpirationDate: null;
          graduationYear: null;
          visaScreenExpiration: null;
          nameOnDegree: string;
          course: null;
        }
      ];
      isUSCitizen: null;
      isMalpracticeClaim: false;
      isLegal: null;
    };
    basicInfo: {
      firstname: string;
      lastname: string;
      preferredName: null;
      email: string;
      phone: string;
      stateLicensesHeld: null;
      gender: null;
      permanentAddress: {
        streetAddress: string;
        apt: null;
        city: string;
        state: string;
        zip: string;
        country: string;
      };
      isCurrAddressDiff: false;
      currentAddress: {
        streetAddress: null;
        apt: null;
        city: null;
        state: null;
        zip: null;
        country: null;
      };
      emergencyContact: string;
      emergencyPhone: string;
      relation: string;
      isHcp: null;
      shifts: [];
      interests: [];
      dateAvailable: Date;
      referredBy: null;
      requestedRecruiter: null;
      travelDestinations: [];
      preferredWorkEnv: null;
      professionalJourney: null;
    };
    skillsChecklists: {
      active: string;
      categoryId: string;
      complete: string | null;
      completedDate: string | null;
      created: string;
      customerId: string;
      expiration: string;
      id: string;
      lastUpdated: string;
      name: string;
      required: string;
      useSubSkills: string;
    }[],
    candidateCredentials: CandidateCredentials[];
    onboardingInformation: {
      onboarding: {
        basicInfo: CandidateBasicInfo;
        skillsChecklists: SkillsChecklists[];
        specialtiesAndEducation: SpecialtiesAndEducation;
      }
    };
    references: Reference[];
    workExperience: WorkExperience[];  
  };
  candidate: {
    atsBullhornCandidateId: string;
    bullhornIdFromBullhorn: string;
  };
  myJobs: {
    myJobs: MyJobs;
    filteredJobs: MyJobsFiltered;
    loading: boolean;
  };
}

export interface GetRelatedSpecialtiesQueryType {
  professionDescriptions: string[];
  specialtyDescriptions: string[];
  companyId: number;
}

const enableJobLocationSearch = config.ENABLE_SEARCH_JOB_LOCATION === "true";
const enableEligibilityMeter = config.ENABLE_ELIGIBILITY_METER === "true";
const enableRelatedSpecialties: boolean = config.ENABLE_RELATED_SPECIALTIES === "true";

const JobsResultV2 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const queryStringValues = search ? queryString.parse(search) : {};
  const company = useSelector((state: RootState) => state.company.company);
  const bhId = useSelector((state: RootState) => state.candidate.bullhornIdFromBullhorn);
  const atsBullhornCandidateId = useSelector(
    (state: RootState) => state.candidate.atsBullhornCandidateId
  );
  const finalBhId = bhId || atsBullhornCandidateId;
  const [waitForJobs, setWaitForJobs] = useState(false);
  const currLocation = jobSearchQueryBuilder.getDefaultLocation();
  const defaultParams = jobSearchQueryBuilder.setInitialParams();
  const currSpecCodeArray = jobSearchQueryBuilder.getArrayOfCodesFromSpecialties(
    jobSearchQueryBuilder.getCurrentSpecialties()
  );
  let campaignCode;
  let sourceType;
  const [secondLimitChange, setSecondLimitChange] = useState(false);
  const detectSecondLimitChange = (val) => {
    setSecondLimitChange(val);
  };
  const [jobsSearchUrl, setJobsSearchUrl] = useState("");
  const [isDoingGlobalSearch, setIsDoingGlobalSearch] = useState<boolean>(true);
  const [includeQuickApplyEligibility] = useState<boolean>(true);
  const obj = {
    email: useSelector((state: RootState) => state.auth.email),
    company: useSelector((state: RootState) => state.company.company),
    userId: useSelector((state: RootState) => state.auth.userId),
    firstName: useSelector((state: RootState) => state.auth.firstName),
    lastName: useSelector((state: RootState) => state.auth.lastName),
    phone: useSelector((state: RootState) => state.auth.phone),
    recruiterDetails: useSelector((state: RootState) => state.consultant.recruiterDetails),
    onboardingSpecialtiesAndEducation: useSelector(
      (state: RootState) => state.onBoarding.specialtiesAndEducation
    ),
    onboardingBasicInfo: useSelector((state: RootState) => state.onBoarding.basicInfo),
    candidateInformation: useSelector((state: RootState) => {
      return getCandidateInformation(state.onBoarding);
    }),
    ...(enableRelatedSpecialties && {
      specsAndRelatedSpecsMapList: useSelector(
        (state: RootState) => state.globalSearch.specsAndRelatedSpecs
      ),
    }),
    jobsLoading: useSelector((state: RootState) => {
      if (window.location.pathname.includes("my-jobs")) {
        return state.myJobs.loading || !finalBhId;
      }
      return isDoingGlobalSearch;
    }),
  };
  const hcpProfession = obj.onboardingSpecialtiesAndEducation?.specialty?.profession;
  const hcpSpecialty = obj.onboardingSpecialtiesAndEducation?.specialty?.primarySpecialty;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };
  const prevEmail = usePrevious(obj.email);
  const prevRecruiterDetails = usePrevious(obj.recruiterDetails);
  let url = "";
  let res;

  const enableFilters = config.ENABLE_FILTERS === "true";

  const initialJobsSearch = enableFilters ? 15 : 12;

  let apiQueryString = "";
  let apiQueryObject;
  let apiQueryMergedObject: any = {};

  const prevUrl = usePrevious(url);

  const [authToken, setAuthToken] = useState<string | undefined>();
  const [jobs, setJobsData] = useState<MyJobs | null>(null);
  const [analyticsCalled, setAnalyticsCalled] = useState(false);
  const [filterAnalyticsCalled, setFilterAnalyticsCalled] = useState(false);

  useEffect(() => {
    const fetchJobs = async () => {
      if (window.location.pathname.includes("result")) {
        setIsDoingGlobalSearch(true);

        apiQueryObject = {
          profession: hcpProfession,
          specialty: currSpecCodeArray.join() || hcpSpecialty,
          companyId: company.id,
          tags: defaultParams.tags === "noTags" ? "" : defaultParams.tags,
          page: defaultParams.offset,
          size: defaultParams.size ? defaultParams.size : initialJobsSearch,
          geoLocationRadius: defaultParams.geoLocationRadius
            ? defaultParams.geoLocationRadius
            : config.GEO_LOCATION_RADIUS,
        };
        if (finalBhId) apiQueryObject.candidateId = finalBhId;


        if (!enableJobLocationSearch && currLocation.state) {
          apiQueryObject.city = currLocation.city ? currLocation.city : "null";
          apiQueryObject.state = currLocation.state ? currLocation.state : "null";
          apiQueryObject.latitude = currLocation.latitude ? currLocation.latitude : "";
          apiQueryObject.longitude = currLocation.longitude ? currLocation.longitude : "";
          apiQueryObject.location = jobSearchQueryBuilder.getDefaultLocation();
        }

        if (enableJobLocationSearch) {
          apiQueryObject.city = "null";
          // added below conditions for existing saved searches to work
          if (currLocation.states) {
            apiQueryObject.states = currLocation.states;
          } else if (currLocation.state && !currLocation.city) {
            apiQueryObject.states = currLocation.state;
          } else {
            apiQueryObject.states = "";
          }

          if (currLocation.latitudes && currLocation.longitudes) {
            apiQueryObject.latitudes = currLocation.latitudes;
            apiQueryObject.longitudes = currLocation.longitudes;
          } else if (currLocation.city && currLocation.latitude && currLocation.longitude) {
            apiQueryObject.latitudes = currLocation.latitude;
            apiQueryObject.longitudes = currLocation.longitude;
          } else {
            apiQueryObject.latitudes = "";
            apiQueryObject.longitudes = "";
          }
          apiQueryObject.location = jobSearchQueryBuilder.getDefaultLocation();
        }

        if (currSpecCodeArray.length > 0 || hcpSpecialty) {
          apiQueryMergedObject = Object.assign(
            jobSearchQueryBuilder.setInitialParams(),
            apiQueryObject
          );

        if(enableRelatedSpecialties && apiQueryMergedObject?.specsWithRelSpecs){
          delete apiQueryMergedObject.specsWithRelSpecs
        }

          const formattedApiObject = jobSearchQueryBuilder.filterQueryObject(apiQueryMergedObject);
          apiQueryString = jobSearchQueryBuilder.queryBuilder(formattedApiObject);
        }
        dispatch(setMyJobsFilter("favorite"));
        url = enableEligibilityMeter
          ? `${config.API_BASE_HOST}/ui/v1/jobs/globalSearch?${apiQueryString}&includeQuickApplyEligibility=true&includeSsnAndDobInEligibilityFilter=true`
            : `${config.API_BASE_HOST}/ui/v1/jobs/globalSearch?${apiQueryString}`;
        setJobsSearchUrl(url);
        try {
          dispatch(fetchMyJobsRequest());
          
          if(enableEligibilityMeter){
            res = await API.post(url, {
            candidateInformation: obj.candidateInformation
          })}else {
            res = await API.get(url);
          }
        
          setIsDoingGlobalSearch(false);
          if (res.status === 200) {
            dispatch({ type: FETCH_MY_JOBS_SUCCESS, payload: { myJobs: res.data } });
            setJobsData(res.data);
          }
        } catch (error) {
          dispatch(fetchMyJobsFailure(error));
          console.error(error);
        }
      } else if (finalBhId) {
        if(enableEligibilityMeter){
        await dispatch(fetchMyJobs(finalBhId, company.id, authToken, obj.candidateInformation, includeQuickApplyEligibility));
        } else{
        await dispatch(fetchMyJobs(finalBhId, company.id, authToken));
        }
      }
    };

    if (authToken) {
      fetchJobs();
    }
  }, [window.location.href, authToken, finalBhId]);

  useEffect(() => {
    if (obj.candidateInformation) {
      setAuthToken(getAccessToken());
    }
  }, [obj]);

  function getJobRecommendation(jobData) {
    let source = "";
    if (jobData?._embedded?.jobsSource) {
      source = jobData._embedded.jobsSource.toLowerCase();
    }

    let result;
    if (source === "legacy") {
      result = RECOMMENDED_JOBS;
    } else if (source === "match") {
      result = MATCH_RECOMMENDED_JOBS;
    } else {
      result = JOBS_SEARCH;
    }

    return result;
  }

  if (window.location.pathname.includes("result")) {
    campaignCode = getJobRecommendation(jobs);
    sourceType = JOBS_SEARCH;
  } else {
    campaignCode = MY_JOBS;
    sourceType = MY_JOBS;
  }
  history.listen((location) => {
    if (!location.pathname.includes("jobs")) {
      dispatch(setMyJobsFilter("favorite"));
    }
    if (
      localStorage.getItem("enableFilterScrollPos") !== "true" &&
      localStorage.getItem("locationPathname") !== location.pathname
    ) {
      window.scrollTo(0, 0);
      localStorage.setItem("locationPathname", location.pathname);
    }
  });
  const {
    durations,
    geoLocationRadius,
    refreshPayFilter,
    jobDetails,
    salaryMax,
    shiftLengths,
    shiftTypes,
    sort,
    specialty,
    relatedSpecialties,
    startDate,
    size,
    facilityNames,
  } = queryStringValues;
  const location = queryStringValues?.location as string;
  const locationObject = location ? JSON.parse(location) : {};
  const parsedLocationObject = {
    city: locationObject?.city || null,
    state: locationObject?.state || null,
    plainState: locationObject?.plainState || null,
    latitude: locationObject?.latitude || null,
    longitude: locationObject?.longitude || null,
  };

  function getLocationString(currentLocation) {
    let result = "";
    if (currentLocation.city && currentLocation.state) {
      result = `${currentLocation.city}, ${currentLocation.state}`;
    } else if (currentLocation.city) {
      result = currentLocation.city;
    } else if (currentLocation.state) {
      result = currentLocation.state;
    }
    return result;
  }
  function callAnalytics() {
    const where = getLocationString(currLocation);
    let segmentEventProps = {};
    let dimensions = {};
    const segmentEventName = t("segment.jobListViewed");
    segmentEventProps = {
      where,
      what: currSpecCodeArray && currSpecCodeArray.length > 0 ? currSpecCodeArray.join() : null,
      relatedSpecialties: typeof relatedSpecialties === "string" ? relatedSpecialties.split(",") : [""],
      sort: defaultParams.sort ? defaultParams.sort : null,
      noOfResults: jobs && jobs.page && jobs.page.totalElements ? jobs.page.totalElements : null,
      noOfPages: jobs && jobs.page && jobs.page.totalPages ? jobs.page.totalPages : null,
      origin: document.referrer,
    };
    dimensions = {
      dimension9:
        currSpecCodeArray && currSpecCodeArray.length > 0 ? currSpecCodeArray.join() : null,
      dimension6: where,
      dimension5:
        jobs && jobs.page && jobs.page.totalElements
          ? jobs.page.totalElements
          : t("googleAnalytics.jobNotFound"),
    };
    const event = {
      category: t("googleAnalytics.category.jobs"),
      action: t("googleAnalytics.action.executeJobSearch"),
      label: t("googleAnalytics.label.jobSearch"),
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
    Analytics.sendPageview(window.location.pathname, dimensions);
    Analytics.sendEvent(event);
    setAnalyticsCalled(true);
    jobSearchQueryBuilder.setIsSearchClicked(false);
  }

  function callFilterAnalytics() {
    const searchCriteriaObj = {
      specialty: specialty ? JSON.parse(specialty as string) : null,
      location: !isEmpty(locationObject) ? parsedLocationObject : {},
      shiftTypes: typeof shiftTypes === "string" ? shiftTypes.split(",") : [""],
      shiftLengths: typeof shiftLengths === "string" ? shiftLengths.split(",") : [""],
      durations: typeof durations === "string" ? durations.split(",") : [""],
      facilities: typeof facilityNames === "string" ? facilityNames.split(",") : [""],
      jobDetails: typeof jobDetails === "string" ? jobDetails.split(",") : [""],
      relatedSpecialties: typeof relatedSpecialties === "string" ? relatedSpecialties.split(",") : [""],
      noOfResults: jobs && jobs.page && jobs.page.totalElements ? jobs.page.totalElements : null,
      startDate,
      salaryMax,
      geoLocationRadius,
      refreshPayFilter,
      sort,
      size,
      ...(quickApplyEligibilty[0]?.isActive? {
        quickApplyEligibility
        : 100} : {}),
    };
    let segmentEventProps = {};
    let dimensions = {};
    const segmentEventName = t("segment.jobListFiltered");
    segmentEventProps = {
      searchCriteria: searchCriteriaObj,
    };
    const where = getLocationString(currLocation);
    dimensions = {
      dimension5:
        jobs && jobs.page && jobs.page.totalElements
          ? jobs.page.totalElements
          : t("googleAnalytics.jobNotFound"),
      dimension11: sort,
      dimension12: size,
      dimension13: durations || null,
      dimension14: salaryMax || null,
      dimension15: startDate || null,
      dimension16: shiftTypes || null,
      dimension17: geoLocationRadius || null,
      dimension18: shiftLengths || null,
      dimension19: facilityNames || null,
      dimension6: where,
      dimension9:
        currSpecCodeArray && currSpecCodeArray.length > 0 ? currSpecCodeArray.join() : null,
    };
    const event = {
      category: t("googleAnalytics.category.jobs"),
      action: t("googleAnalytics.action.filterJobResults"),
      label: t("googleAnalytics.label.filterJobs"),
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
    Analytics.sendPageview(window.location.pathname, dimensions);
    Analytics.sendEvent(event);
    setFilterAnalyticsCalled(true);
    jobSearchQueryBuilder.setIsFilteredJobs(false);
  }

  useEffect(() => {
    callAnalytics();
    callFilterAnalytics();

    const isSearchClicked = jobSearchQueryBuilder.getIsSearchClicked();
    setAnalyticsCalled(!isSearchClicked);
    const isFilterClicked = jobSearchQueryBuilder.getIsFilteredJobs();
    setFilterAnalyticsCalled(!isFilterClicked);
    const isPrevUrlDiff = url !== prevUrl;
    if (isPrevUrlDiff && isSearchClicked) {
      setWaitForJobs(true);
    }
    if (
      obj &&
      obj.userId &&
      (obj.email !== prevEmail || obj.recruiterDetails !== prevRecruiterDetails)
    ) {
      analyticsSegmentIdentify(obj);
    }

    if (secondLimitChange) window.scrollTo(0, 0);

    if (window.location.pathname.includes("result")) {
      campaignCode = getJobRecommendation(jobs);
      sourceType = JOBS_SEARCH;
    } else {
      campaignCode = MY_JOBS;
      sourceType = MY_JOBS;
    }
    if (
      window.location.pathname.includes("results") &&
      isSearchClicked &&
      !analyticsCalled &&
      waitForJobs
    ) {
      callAnalytics();
      setWaitForJobs(false);
    }
    if (window.location.pathname.includes("results") && isFilterClicked && !filterAnalyticsCalled) {
      callFilterAnalytics();
    }

    if (
      window.location.pathname.includes("results") &&
      isSearchClicked &&
      !analyticsCalled &&
      !waitForJobs &&
      jobs
    ) {
      callAnalytics();
    }
  }, [jobs, secondLimitChange, prevEmail, url]);
  const componentName = t("globalSearch.location.componentMyJobs");

  return (
    <div>
      <div className="JobBannerSection" id="job-banner" data-testid="job-banner">
        <div className="subJobBannerSection">
          <Row>
            <Col sm={12} md={12} lg={enableJobLocationSearch ? 12 : 2} xs={12} />
            <Col sm={12} md={12} lg={enableJobLocationSearch ? 12 : 8} xs={12} id="locSpec">
              <LocationSpecialities enableDashboardAd={false} translate={t} componentName={componentName} />
            </Col>
            <Col sm={12} md={12} lg={enableJobLocationSearch ? 12 : 2} xs={12} />
          </Row>
          </div>
      </div>
      <div
        className={
          window.location.pathname.includes("result")
            ? "myJobMain resultsWrap jobSearchBanner"
            : "myJobMain"
        }
      >
        <MyJobsWrapperV2
          url={jobsSearchUrl}
          pageSort={defaultParams.sort}
          pageSize={+defaultParams.size}
          viewMap={defaultParams.view === "map"}
          detectSecondLimitChange={detectSecondLimitChange}
          campaignCode={campaignCode}
          sourceType={sourceType}
          jobsLoading={obj.jobsLoading}
          jobsSource={RecommendedJobsSourceTypes.NONE}
        />
      </div>
    </div>
  );
};

export default JobsResultV2;
