import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { MuiThemeProvider, withStyles, Theme } from "@material-ui/core";
import CssBaseline from "@mui/material/CssBaseline";
import { ApolloProvider } from "@apollo/client";
import { jobDetailType } from "src/components/common/MainContent/JobResults/common";
import config from "src/config";
import { getAccessToken } from "axios-jwt";
import NativeInstallBanner from "./components/common/NativeInstallBanner/NativeInstallBanner";
import themes from "./theme/baseTheme";
import { changeSettingTheme } from "./redux/settings/actions";
import "bootstrap/dist/css/bootstrap.min.css";
import MainRouter from "./router/MainRouter";
import getCompanyFromDomain from "./services/Company";
import { setCompanyFromDomain } from "./redux/company/action";
import Analytics from "./services/Analytics";
import { apolloClient, graphqlRequest } from "./services/GraphQL/GraphQL";
import ThirdPartyScripts from "./components/common/ThirdPartyScripts/ThirdPartyScripts";
import { ADD_USER_PREFERENCE_TO_PINPOINT, LIST_USER_PINPOINT_ENDPOINTS, REMOVE_USER_PREFERENCE_FROM_PINPOINT } from "./services/GraphQL/graph.query";


const styles = () => ({});
const enableMobileAppInfo = config.ENABLE_MOBILE_APP_INFO === "true";
interface RootState {
  onBoarding: {
    completedSteps: any;
  }
}

interface AppProps {
  theme: Theme;
  changeTheme: (themeName: string) => void;
  setTheCompanyFromDomain: (company: any) => void;
  jobDetails: jobDetailType;
  companyDetails: {
    code: string;
  };
}

const App = ({
  theme,
  changeTheme,
  setTheCompanyFromDomain,
  jobDetails,
  companyDetails,
}: AppProps) => {
  const completedSteps = useSelector((state: RootState) => state.onBoarding.completedSteps);
  const isProfileComplete = Object.keys(completedSteps).length===5

  useEffect(()=>{
    const token = getAccessToken()
    async function listUserPinpointEndpoints(){
      const endpointDetails = await graphqlRequest(LIST_USER_PINPOINT_ENDPOINTS,null,token)
      return endpointDetails?.listScheduleNotificationsEndpoint?.data ??[]
    }
    if(token){
      listUserPinpointEndpoints().then((endpoints)=>{
        if(endpoints.length>0){
          const pinpointActionType = isProfileComplete?REMOVE_USER_PREFERENCE_FROM_PINPOINT:ADD_USER_PREFERENCE_TO_PINPOINT
            Promise.all(endpoints.map((value)=>{   
              const params={
                preferences:["profile-incomplete"],
                deviceId:value?.deviceId,
                platform:value?.platform
              }     
               return graphqlRequest(pinpointActionType,params,token)
            })) 
          
        }
      })
    }
  },[isProfileComplete])

  useEffect(() => {
    let companyCode;
    if (!companyDetails) {
      const theCompanyDetails = getCompanyFromDomain();
      companyCode = theCompanyDetails.code;
      setTheCompanyFromDomain(theCompanyDetails);
    } else {
      companyCode = companyDetails.code;
    }
    const globalVariable = {
      dimension4: companyCode,
    };
    Analytics.setGlobalVariables(globalVariable);
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <MuiThemeProvider theme={themes[`${theme}`]}>
        <>
          <CssBaseline />
          {enableMobileAppInfo && <NativeInstallBanner />}
          <MainRouter
            theme={theme}
            changeTheme={changeTheme}
            setTheCompanyFromDomain={setTheCompanyFromDomain}
            jobDetails={jobDetails}
            companyDetails={companyDetails}
          />
          <ThirdPartyScripts />
        </>
      </MuiThemeProvider>
    </ApolloProvider>
  );
};

const mapStateToProps = (state) => ({
  theme: state.settings.theme,
  jobDetails: state.jobDetails.jobDetails,
  companyDetails: state.company.company,
});

const mapDispatchToProps = (dispatch) => ({
  changeTheme: (themeName) => dispatch(changeSettingTheme(themeName)),
  setTheCompanyFromDomain: (company) => dispatch(setCompanyFromDomain(company)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withTranslation()(App)));
