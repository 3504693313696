import { useState, useEffect, useMemo, useCallback } from "react";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import { withTranslation, TFunction } from "react-i18next";
import { connect } from "react-redux";
import filter from "lodash/filter";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import queryString from "query-string";
import analyticsSegmentIdentify from "src/services/SegmentIdentify";
import Analytics from "src/services/Analytics";
import config from "src/config";
import jobSearchQueryBuilder from "src/services/QueryBuilder/JobSearchQueryBuilder";
import MultiSelectBox from "src/components/common/Header/MultiSelectBox";
import LocationBoxV2 from "src/components/common/Header/LocationBoxV2";
import clsx from "clsx";
import { useMediaQuery } from "@material-ui/core";
import {
  clearSpecsAndRelatedSpecsMapList,
  deleteSpecsFromMap,
  fetchRelatedSpecialties,
  setToggleRelatedSpecialties,
  updateSpecsAndRelatedSpecsMapList,
} from "src/redux/globalSearch/action";
import { GetRelatedSpecialtiesQueryType } from "../JobsTab/MyJobs/JobsResultV2";

const enableRelatedSpecialties: boolean = config.ENABLE_RELATED_SPECIALTIES === "true";

interface LocationSpecialitiesProps {
  translate: TFunction<"translation", undefined>;
  componentName: string;
  profession: string;
  primarySpecialty: string;
  specsAndRelatedSpecsMapList: Map<string, string[]>;
  toggleRelatedSpecialties: boolean;
  companyId: string;
  onboardingBasicInfo: {
    firstname: string;
    lastname: string;
    preferredName: string;
    email: string;
    phone: string;
    stateLicensesHeld: string;
    gender: string;
    permanentAddress: {
      streetAddress: string | null;
      apt: string | null;
      city: string | null;
      state: string | null;
      zip: string | null;
      country: string | null;
    };
    isCurrAddressDiff: boolean;
    currentAddress: {
      streetAddress: null;
      apt: string | null;
      city: string | null;
      state: string | null;
      zip: string | null;
      country: string | null;
    };
    emergencyContact: string | null;
    emergencyPhone: string | null;
    relation: string | null;
    isHcp: string | null;
    shifts: string[];
    interests: string[];
    dateAvailable: string;
    referredBy: string | null;
    requestedRecruiter: string | null;
    travelDestinations: [];
    preferredWorkEnv: string | null;
    professionalJourney: string | null;
  };
  userId: number;
  email: string;
  enableDashboardAd: boolean;
  hideHeading?: boolean;
  getRelatedSpecialties: (query: GetRelatedSpecialtiesQueryType, fetchForFilters: boolean) => void;
  updateRelatedSpecialties: (payload: Map<string, string[]>) => void;
  deleteSpecsFromRelSpecsMap: (payload: string[]) => void;
  clearRelatedSpecialties: () => void;
  setToggleRelatedSpecs: (payload: boolean) => void;
}

const enableJobLocationSearch = config.ENABLE_SEARCH_JOB_LOCATION === "true";

const LocationSpecialities = ({
  translate,
  profession,
  primarySpecialty,
  onboardingBasicInfo,
  userId,
  email,
  componentName,
  enableDashboardAd,
  hideHeading,
  companyId,
  specsAndRelatedSpecsMapList,
  toggleRelatedSpecialties,
  getRelatedSpecialties,
  updateRelatedSpecialties,
  deleteSpecsFromRelSpecsMap,
  clearRelatedSpecialties,
  setToggleRelatedSpecs,
}: LocationSpecialitiesProps) => {
  const isCompact = useMediaQuery("(max-width: 768px)");
  const { search } = useLocation();
  const showRelatedSpecialties: boolean =
    profession === translate("dashboard.searchBar.relatedSpecialtiesProfession") &&
    companyId === translate("dashboard.searchBar.relatedSpecialtiesCompanyId");
  const [location, setLocation] = useState(jobSearchQueryBuilder.getDefaultLocation());
  const [specialties, setSpecialties] = useState(
    jobSearchQueryBuilder.getDefaultSpecialties([[primarySpecialty, primarySpecialty]])
  );
  const [defaultLocation, setDefaultLocation] = useState(
    jobSearchQueryBuilder.serializeLocation(location)
  );

  const queryStringValues = search ? queryString.parse(search) : {};
  const {
    shiftTypes: queryShiftTypes,
    startDate: queryStartDate,
    shiftLengths: queryShiftLengths,
    durations: queryDurations,
    salaryMax: querySalaryMax,
    jobDetails: queryJobDetails,
    msp: queryMsp,
    datePosted: queryDatePosted,
    shouldFilterByQuickApplyEligibility,
  } = queryStringValues;

  useEffect(() => {
    const queriedStringValues = search ? queryString.parse(search) : {};
    if (queriedStringValues?.specialty) {
      const newSpeciality = jobSearchQueryBuilder.getDefaultSpecialties([
        [primarySpecialty, primarySpecialty],
      ]);
      setSpecialties(newSpeciality);
    }
    if (queriedStringValues?.location && typeof queriedStringValues.location === "string") {
      setDefaultLocation(queriedStringValues.location);
    }
  }, [search, primarySpecialty]);
  const specialtySetter = (newSpecialty) => {
    const specialtiesCopy: [string, string][] = specialties;
    if (!specialties.some((specialtyElement) => specialtyElement[0] === newSpecialty[0])) {
      specialtiesCopy.push(newSpecialty);
    }
    setSpecialties(Array.from(new Set(specialtiesCopy)));
  };

  //! TODO: This is an ugly fix to handle first time login users not seeing specialties. Fix this in future versions.
  useEffect(() => {
    setSpecialties(
      jobSearchQueryBuilder.getDefaultSpecialties([[primarySpecialty, primarySpecialty]])
    );
  }, [primarySpecialty]);

  const handleDelete = (item) => {
    if (isMobile) {
      const activeElement = document.activeElement as HTMLInputElement | HTMLTextAreaElement;
      activeElement.inputMode = "none";
      document.getElementById("jobSearchMainV2")!.scrollIntoView();
    }

    const copiedObject = filter(specialties, (specialty) => specialty[0] !== item);
    setSpecialties(copiedObject);
  };

  const handleDeleteRelatedSpecialty = useCallback(
    (option) => {
      // Create a new Map to avoid mutating the existing state.
      const updatedMap = new Map(specsAndRelatedSpecsMapList);

      // Iterate through each [specialty, relatedSpecs] pair.
      updatedMap.forEach((relatedSpecs, specKey) => {
        if (relatedSpecs.includes(option)) {
          // Filter out the deleted related specialty.
          const updatedRelatedSpecs = relatedSpecs.filter((item) => item !== option);
          updatedMap.set(specKey, updatedRelatedSpecs);
        }
      });

      // Dispatch the updated Map to the Redux store.
      updateRelatedSpecialties(updatedMap);
    },
    [specsAndRelatedSpecsMapList]
  );

  useEffect(() => {
    // If there's no specialty provided, clear out related specialties or skip fetching.
    if (
      enableRelatedSpecialties &&
      showRelatedSpecialties &&
      (!specialties || specialties.length === 0)
    ) {
      clearRelatedSpecialties();
      return;
    }

    let isMounted = true;

    async function loadRelatedSpecialties() {
      if (enableRelatedSpecialties && showRelatedSpecialties && toggleRelatedSpecialties) {
        try {
          // Flatten the specialties array (in case it's 2D) and remove duplicates.
          const flattened: string[] = specialties.flat();
          const uniqueSpecialties: string[] = [...new Set(flattened)];

          // It removes any specialty from the Map that is not present in the specialties array.
          deleteSpecsFromRelSpecsMap(uniqueSpecialties);

          // Filter out specialties that are already present as a key in the Map
          const specialtiesToFetch = uniqueSpecialties.filter(
            (specialty: string) => !specsAndRelatedSpecsMapList.has(specialty)
          );

          // If all specialties are already available, there's nothing to fetch.
          if (specialtiesToFetch.length === 0) {
            return;
          }

          // Build the query with only the missing specialties.
          specialtiesToFetch?.forEach((spec: string) => {
            const query: GetRelatedSpecialtiesQueryType = {
              professionDescriptions: [profession],
              specialtyDescriptions: [spec],
              companyId: parseInt(companyId, 10),
            };

            // Proceed to fetch if the query is valid and the component is still mounted.
            if (
              query.professionDescriptions &&
              query.specialtyDescriptions.length > 0 &&
              query.companyId &&
              isMounted
            ) {
              getRelatedSpecialties(query, false);
            }
          });
        } catch (error) {
          console.error("Error fetching related specialties:", error);
        }
      }
    }

    loadRelatedSpecialties();

    // eslint-disable-next-line consistent-return
    return () => {
      isMounted = false;
    };
  }, [specialties]);

  useEffect(() => {
    if (enableRelatedSpecialties && showRelatedSpecialties) {
      const params = new URLSearchParams(search);
      const newMap = jobSearchQueryBuilder.getSpecsMapFromQuery(params);
      // Dispatch the updated Map to Redux.
      if (params && newMap.size > 0) updateRelatedSpecialties(newMap);

      // Flatten the specialties array (in case it's 2D) and remove duplicates.
      const specialtyDetails = queryStringValues?.specialty
        ? (JSON.parse(queryStringValues?.specialty as string) as string[])
        : [];
      const flattened: string[] = specialtyDetails?.flat();
      const uniqueSpecialties: string[] = [...new Set(flattened)];

      const query: GetRelatedSpecialtiesQueryType = {
        professionDescriptions: [profession],
        specialtyDescriptions: uniqueSpecialties,
        companyId: parseInt(companyId, 10),
      };

      // Proceed to fetch if the query is valid and the component is still mounted.
      if (
        query.professionDescriptions &&
        query.specialtyDescriptions.length > 0 &&
        query.companyId
      ) {
        getRelatedSpecialties(query, true);
      }
    }
  }, [search]);

  const sortedRelatedSpecialties = useMemo(() => {
    if (
      enableRelatedSpecialties &&
      showRelatedSpecialties &&
      specsAndRelatedSpecsMapList?.size > 0
    ) {
      // Flatten all arrays from the Map into one array.
      const flattened = Array.from(specsAndRelatedSpecsMapList?.values()).flat();
      // Return a sorted copy of the flattened array.
      return [...flattened].sort((a, b) => a.localeCompare(b));
    }
    return [];
  }, [specsAndRelatedSpecsMapList, search, specialties]);

  const handleDeleteAll = () => {
    setSpecialties([]);
  };

  const handleDeleteAllRelatedSpecialties = (reason: string) => {
    // Flatten the specialties array (in case it's 2D) and remove duplicates.
    const flattened: string[] = specialties.flat();
    const uniqueSpecialties: string[] = [...new Set(flattened)];

    if (reason === translate("globalSearch.location.addAll")) {
      uniqueSpecialties?.forEach((spec) => {
        // Build the query with only the missing specialties.
        const query: GetRelatedSpecialtiesQueryType = {
          professionDescriptions: [profession],
          specialtyDescriptions: [spec],
          companyId: parseInt(companyId, 10),
        };

        // Proceed to fetch if the query is valid and the component is still mounted.
        if (
          query.professionDescriptions &&
          query.specialtyDescriptions.length > 0 &&
          query.companyId
        ) {
          getRelatedSpecialties(query, false);
        }
      });
      setToggleRelatedSpecs(true);
    }
    if (reason === translate("globalSearch.location.clearAll")) {
      clearRelatedSpecialties();
      setToggleRelatedSpecs(false);
    }
  };

  const enableFilters = config.ENABLE_FILTERS === "true";
  const initialJobsSearch = enableFilters ? 15 : 12;
  const enableJobDetailsFilter = config.ENABLE_JOB_DETAILS_FILTER === "true";
  const disableShiftTypeFilter = config.DISABLE_SHIFT_TYPE_FILTER === "true";

  const shiftTypes =
    enableFilters && onboardingBasicInfo && onboardingBasicInfo.shifts && !disableShiftTypeFilter
      ? onboardingBasicInfo.shifts
          .map((shift) => {
            const lowerCaseShift = shift.toLowerCase();
            if (lowerCaseShift === "day") {
              return "days";
            }
            if (lowerCaseShift === "mid") {
              return "evenings";
            }
            if (lowerCaseShift === "night") {
              return "nights";
            }
            return "";
          })
          .join(",")
      : null;

  const momentObject = enableFilters
    ? onboardingBasicInfo?.dateAvailable && moment(onboardingBasicInfo?.dateAvailable, "MM/DD/YYYY")
    : null;
  const startDate =
    momentObject && momentObject.diff(moment(), "day") >= 0
      ? moment(onboardingBasicInfo?.dateAvailable).format("YYYY-MM-DD")
      : null;

  const isJobResultPage = window.location.pathname.includes("result");

  const getStartDate = (
    shouldUseQueryStartDate: boolean,
    theQueryStartDate: string | (string | null)[] | null,
    enableTheJobDetailsFilter: boolean,
    defaultStartDate: string | null
  ): string | (string | null)[] | null => {
    if (shouldUseQueryStartDate) {
      return theQueryStartDate;
    }

    const shouldUseDefaultStartDate = !enableTheJobDetailsFilter;
    return shouldUseDefaultStartDate ? defaultStartDate : null;
  };

  const searchJobs = async () => {
    if (specialties.length === 0) {
      return;
    }

    jobSearchQueryBuilder.setIsSearchClicked(true);

    if (location && (document.getElementById("country-select") as HTMLInputElement)?.value !== "") {
      jobSearchQueryBuilder.setInitialParams();
      setDefaultLocation(jobSearchQueryBuilder.serializeLocation(location));
      jobSearchQueryBuilder.handleParamsChange({
        location: jobSearchQueryBuilder.serializeLocation(location),
        specialty: jobSearchQueryBuilder.serializeSpecialties(specialties),
        ...(enableRelatedSpecialties &&
          showRelatedSpecialties && {
            specsWithRelSpecs: jobSearchQueryBuilder.createQueryWithSpecsMap(
              specsAndRelatedSpecsMapList
            ),
          }),
        relatedSpecialties: sortedRelatedSpecialties.join(),
        tags: [],
        view: translate("jobSearch.defaultView"),
        geoLocationRadius: config.GEO_LOCATION_RADIUS,
        sort: translate("jobSearch.defaultSort"),
        offset: translate("jobSearch.defaultOffset"),
        startDate: getStartDate(isJobResultPage, queryStartDate, enableJobDetailsFilter, startDate),
        shiftTypes: isJobResultPage ? queryShiftTypes : shiftTypes,
        refreshPayFilter: "true",
        refreshFacilityFilter: "true",
        shiftLengths: isJobResultPage ? queryShiftLengths : "",
        durations: isJobResultPage ? queryDurations : "",
        facilityNames: "",
        salaryMax: isJobResultPage ? querySalaryMax : "",
        size: initialJobsSearch,
        jobDetails: isJobResultPage ? queryJobDetails : "",
        msp: isJobResultPage ? queryMsp : "",
        datePosted: isJobResultPage ? queryDatePosted : "",
        shouldFilterByQuickApplyEligibility: isJobResultPage
          ? shouldFilterByQuickApplyEligibility
          : "false",
      });
    } else {
      jobSearchQueryBuilder.setInitialParams();
      jobSearchQueryBuilder.handleParamsChange({
        specialty: jobSearchQueryBuilder.serializeSpecialties(specialties),
        ...(enableRelatedSpecialties &&
          showRelatedSpecialties && {
            specsWithRelSpecs: jobSearchQueryBuilder.createQueryWithSpecsMap(
              specsAndRelatedSpecsMapList
            ),
          }),
        location: "{}",
        relatedSpecialties: sortedRelatedSpecialties.join(),
        tags: [],
        view: translate("jobSearch.defaultView"),
        geoLocationRadius: config.GEO_LOCATION_RADIUS,
        sort: translate("jobSearch.defaultSort"),
        offset: translate("jobSearch.defaultOffset"),
        startDate: getStartDate(isJobResultPage, queryStartDate, enableJobDetailsFilter, startDate),
        shiftTypes: isJobResultPage ? queryShiftTypes : shiftTypes,
        refreshPayFilter: "true",
        refreshFacilityFilter: "true",
        shiftLengths: isJobResultPage ? queryShiftLengths : "",
        facilityNames: "",
        durations: isJobResultPage ? queryDurations : "",
        salaryMax: isJobResultPage ? querySalaryMax : "",
        size: initialJobsSearch,
        jobDetails: isJobResultPage ? queryJobDetails : "",
        msp: isJobResultPage ? queryMsp : "",
        datePosted: isJobResultPage ? queryDatePosted : "",
        shouldFilterByQuickApplyEligibility: isJobResultPage
          ? shouldFilterByQuickApplyEligibility
          : "false",
      });
    }
    const where = jobSearchQueryBuilder.getLocationsArray(location);
    if (
      userId &&
      email &&
      sessionStorage.getItem("isSegmentIdentifyCalledFromSearchJob") === null
    ) {
      sessionStorage.setItem("isSegmentIdentifyCalledFromSearchJob", "true");
      analyticsSegmentIdentify({ translate, primarySpecialty, onboardingBasicInfo, userId, email });
    }
    const relatedSpecialties = Array.from(specsAndRelatedSpecsMapList?.values()).flat();
    const relatedSpecialtiesValue = relatedSpecialties.length > 0 ? relatedSpecialties : [];
    const segmentEventName = translate("segment.jobSearched");
    const segmentEventProps = {
      segment_source_name: `${process.env.NODE_ENV}-web-gateway`,
      what: jobSearchQueryBuilder.getSpecialtiesArray(specialties),
      origin: document.referrer,
      where,
      has_add_compact_states_checked: location?.compactStateCheckValue || false,
      has_multiple_locations: where?.length > 1,
      relatedSpecialties: relatedSpecialtiesValue,
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
    window.scrollTo(0, 0);
  };

  return (
    <>
      {hideHeading !== true && !enableDashboardAd ? (
        <h3 className="jobSearchHeading" id="job-search-heading">
          Find your next destination
        </h3>
      ) : null}
      <div
        className={clsx(
          enableJobLocationSearch && "jobSearchSpecialNewFeature",
          !isMobile && enableDashboardAd && "mt-4"
        )}
      >
        <div
          className={clsx(
            "jobSearchLocation",
            enableDashboardAd && isMobile && "jobSearchInBanner"
          )}
        >
          <div className="jobLocationTxt">{translate("dashboard.searchBar.location")}</div>
          <LocationBoxV2
            enableDashboardAd={enableDashboardAd}
            defaultLocation={defaultLocation}
            setLocation={setLocation}
            componentName={componentName}
          />
        </div>
        <div
          id="specialityBox"
          className={clsx(
            "jobSearchSpecialties",
            enableDashboardAd && isMobile && "jobSearchInBanner"
          )}
        >
          <div className="jobSpecialtiesTxt">{translate("dashboard.searchBar.specialties")}</div>
          <MultiSelectBox
            specialties={specialties}
            specialtySetter={specialtySetter}
            handleDelete={handleDelete}
            handleDeleteRelatedSpecialty={handleDeleteRelatedSpecialty}
            handleDeleteAll={handleDeleteAll}
            handleDeleteAllRelatedSpecialties={handleDeleteAllRelatedSpecialties}
            enableDashboardAd={enableDashboardAd}
            relatedSpecialties={sortedRelatedSpecialties}
            showRelatedSpecialties={showRelatedSpecialties}
            toggleRelatedSpecialties={toggleRelatedSpecialties}
          />
        </div>
        <div className="jobSearchBtnMain">
          <Button className="jobSearchBtn" disabled={specialties.length === 0} onClick={searchJobs}>
            {!isCompact ? (
              <SearchIcon className="jobSearchIcon" />
            ) : (
              <>
                <SearchIcon className="jobSearchIcon mr-1" />{" "}
                {translate("dashboard.searchBar.search")}
              </>
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

LocationSpecialities.defaultProps = {
  hideHeading: false,
};

const mapStateToProps = (state) => ({
  email: state.auth.email,
  userId: state.auth.userId,
  companyId: state.company.company.id,
  primarySpecialty: state.onBoarding.specialtiesAndEducation?.specialty?.primarySpecialty,
  profession: state.onBoarding.specialtiesAndEducation?.specialty?.profession,
  onboardingBasicInfo: state.onBoarding.basicInfo,
  specsAndRelatedSpecsMapList: state.globalSearch.specsAndRelatedSpecs,
  toggleRelatedSpecialties: state.globalSearch.toggleRelatedSpecialties,
});

const mapDispatchToProps = (dispatch) => ({
  getRelatedSpecialties: (query: GetRelatedSpecialtiesQueryType, fetchForFilters: boolean) =>
    dispatch(fetchRelatedSpecialties(query, fetchForFilters)),
  updateRelatedSpecialties: (payload) => dispatch(updateSpecsAndRelatedSpecsMapList(payload)),
  deleteSpecsFromRelSpecsMap: (payload) => dispatch(deleteSpecsFromMap(payload)),
  clearRelatedSpecialties: () => dispatch(clearSpecsAndRelatedSpecsMapList()),
  setToggleRelatedSpecs: (payload) => dispatch(setToggleRelatedSpecialties(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(LocationSpecialities));
