import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import MultiSelect from "src/components/common/Header/MultiSelect";
import { fetchPrimarySpecialties } from "src/redux/globalSearch/action";
import jobsSearchQueryBuilder from "src/services/QueryBuilder/JobSearchQueryBuilder";

interface autoSuggest {
  aliases: any[];
  code: string;
  companyId: number;
  dateCreated: Date;
  id: number;
  isPrimary: boolean;
  lastUpdated: Date;
  name: string;
  professionCode: string;
}

interface MultiSelectBoxProps {
  t: (key: string) => string;
  specialties?: [string, string][];
  relatedSpecialties: string[];
  showRelatedSpecialties: boolean;
  toggleRelatedSpecialties: boolean
  getSpecialties: (
    term: string,
    isInitialSpecialty: boolean,
    cid: string,
    professionCode?: string
  ) => {};
  loading?: boolean;
  autoSuggestSpecialties?: autoSuggest[];
  handleDelete: (code: string) => void;
  handleDeleteRelatedSpecialty: (code: string) => void;
  handleDeleteAll: () => void;
  handleDeleteAllRelatedSpecialties: (reason: string) => void;
  specialtySetter: (input: any) => void;
  isSpecialtySelected?: boolean;
  company: {
    id: string;
    code: string;
    name: string;
    url: string;
  };
  enableDashboardAd:boolean
}

interface MultiSelectBoxStateProps {
  inputValue?: string;
  isSpecialtySelected?: boolean;
}

class MultiSelectBox extends React.Component<MultiSelectBoxProps, MultiSelectBoxStateProps> {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      isSpecialtySelected: false,
    };
  }

  /* Used for detecting the changes in input's value. */
  handleChangeInput = (inputVal: string) => {
    const selectedSpecialtyArray =
      jobsSearchQueryBuilder.getArrayOfDescriptionAndCodesFromSpecialties(this.props.specialties);
    if (this.state.isSpecialtySelected) {
      selectedSpecialtyArray.forEach((spec) => {
        const s: string = inputVal;
        if (spec === s.substring(0, s.indexOf("(")).trim()) {
          inputVal = "";
          this.setState({ inputValue: inputVal });
        }
      });
    }

    if (inputVal && inputVal.length > 0) {
      this.props.getSpecialties(inputVal, false, this.props.company.id);
    } else {
      this.props.getSpecialties("", false, this.props.company.id);
    }

    if (inputVal && inputVal.length > 0) {
      const t = inputVal.split(",");
      if (JSON.stringify(t) !== JSON.stringify(selectedSpecialtyArray)) {
        this.setState({ inputValue: inputVal });
      }
    } else this.setState({ inputValue: "" });
  };

  render() {
    return (
      <Row>
        <Col sm={12} xs={12} lg={12} md={12}>
          <MultiSelect
            onInputValueChange={this.handleChangeInput}
            inputValue={this.state.inputValue}
            loading={this.props.loading}
            availableItems={this.props.autoSuggestSpecialties}
            specialties={this.props.specialties}
            relatedSpecialties={this.props.relatedSpecialties}
            handleDelete={this.props.handleDelete}
            handleDeleteRelatedSpecialty={this.props.handleDeleteRelatedSpecialty}
            handleDeleteAll={this.props.handleDeleteAll}
            handleDeleteAllRelatedSpecialties={this.props.handleDeleteAllRelatedSpecialties}
            defaultPlaceholderText={this.props.t("globalSearch.specialty.defaultPlaceholderText")}
            specialtySetter={this.props.specialtySetter}
            noOptionSelectPlaceholderText={this.props.t("globalSearch.specialty.noOptionSelectPlaceholderText")}
            noSpecialtyResultText={this.props.t("globalSearch.specialty.noSpecialtyResultText")}
            noSpecialtySelectedText={this.props.t("globalSearch.specialty.noSpecialtySelectedText")}
            enableDashboardAd={this.props.enableDashboardAd}
            showRelatedSpecialties={this.props.showRelatedSpecialties}
            toggleRelatedSpecialties={this.props.toggleRelatedSpecialties}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  autoSuggestSpecialties: state.globalSearch.specialty,
  company: state.company.company,
  loading: state.globalSearch.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getSpecialties: (term, isInitialSpecialty, cid, professionCode) =>
    dispatch(fetchPrimarySpecialties(term, isInitialSpecialty, cid, professionCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MultiSelectBox));
