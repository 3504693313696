import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Popover from "@mui/material/Popover";
import { makeStyles } from "@material-ui/core/styles";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
import { isMobile } from "react-device-detect";
import jobsSearchQueryBuilder from "../../../../services/QueryBuilder/JobSearchQueryBuilder";
import services from "./Services/Api/SaveSearchService";
import Analytics from "../../../../services/Analytics";
import { PrimaryButton, SecondaryButton, TertiaryButton, SuccessButton } from "../../Buttons";
import EmailNotificationRadio from "./EmailNotificationRadio";
import NotificationSwitch from "./NotificationSwitch";
import config from "src/config";
import zIndex from "@material-ui/core/styles/zIndex";
import { Media } from "react-bootstrap";
import { fontSize } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: theme.palette.mainBorder,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: `1px solid${theme.palette.colorPrimary}`,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1px solid${theme.palette.colorPrimary}`,
    },
    "& .MuiOutlinedInput-input": {
      padding: "9px 8px !important",
      "@media(min-width: 0px) and (max-width: 768px)": {
        padding: "15px 8px !important",
      },
    },
  },
  popoverMainOverlay: {
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "1000",
  },
  popoverMain: {
    width: "336px",
    padding: "20px",
    borderRadius: "20px !important",
    zIndex: "1001",
    position: "relative",
    "@media(min-width: 0px) and (max-width: 768px)": {
      width: "100%",
    },
  },
  popoverHeadTxt: {
    color: "#626C7D",
    textAlign: "left",
    fontSize: "0.8rem",
    fontWeight: "bold",
    marginBottom: "15px",
    "@media(min-width: 0px) and (max-width: 768px)": {
      fontSize: "1rem",
    },
  },
  nameSearchTxtBox: {
    color: "#626C7D",
    fontSize: "0.8rem",
    marginBottom: "10px",
    "@media(min-width: 0px) and (max-width: 768px)": {
      fontSize: "1rem",
    },
  },
  textFieldWidth: {
    width: "100%",
  },
  primarySaveSearchButton: {
    width: "296px",
    marginLeft: "-10px",
    fontSize:"16px",
    "@media(min-width: 0px) and (max-width: 768px)": {
      width: "100%", 
      "marginLeft": 0,  
   },
  },
  saveSearchIcon: {
    fontSize: "1.3rem !important",
  },
  disableSuccessBtn: {
    width: "296px",
    marginLeft: "-10px",
    pointerEvents: "none",
    fontSize:"16px",
    "@media(min-width: 1024px) and (max-width: 1280px)": {
      padding: "4px 8px",
    },
  },
  saveSearchBtnPos: {
    display: "inline-flex",
    marginBottom: "10px",
    height: "44px",
    width: "100%",
    marginLeft: "11px",
    marginTop: -5,
    "@media(min-width: 1024px) and (max-width: 1280px)": {
      marginRight: 7,
    },
    "@media(min-width: 0px) and (max-width: 768px)": {
      position: "relative",
      marginLeft: 0,
      marginTop: "20px",
      marginBottom:"20px",
    },
  },
  searchSavedBtnSuccessMob: {
    "@media(min-width: 0px) and (max-width: 768px)": {
      backgroundColor: "#F0F1F4 !important",
      marginLeft: "0px !important",
      border: "1px solid #C4C4C4 !important",
      fontSize: "1rem !important",
      padding: "6px !important",
      width: "100% !important",
    },
  },
}));

const SaveSearchTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#fff !important",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff !important",
    width: "130px",
    padding: "10px 10px",
    textAlign: "center",
    fontSize: "0.9rem",
    color: `${theme.palette.colorPrimary} !important`,
  },
}));

export default function SaveSearchPopover(props) {
  const history = useHistory();
  const { candidateId, savedSearches, hash, searchCriteria, setSavedSearches } = props;
  const atsBullhornCandidateId = useSelector((state) => state.candidate.atsBullhornCandidateId);
  const onboardingSpecialties = useSelector((state) => state.onBoarding.specialtiesAndEducation);
  const profession = onboardingSpecialties?.specialty?.profession;
  const [anchorEl, setAnchorEl] = useState(null);
  const [saveSearchName, setSaveSearchName] = useState("");
  const [isEmailNotification, setIsEmailNotification] = useState(true);
  const [emailNotificationType, setEmailNotificationType] = useState("daily");
  const [saveSuccess, setSaveSuccess] = useState(false);
  const { search } = useLocation();
  const queryStringValues = search ? queryString.parse(search) : {};
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : null;
  const classes = useStyles();
  const { t } = useTranslation();
  const enableJobLocationSearch = config.ENABLE_SEARCH_JOB_LOCATION === "true";

  useEffect(() => {
    if (savedSearches && hash) {
      const newSearchCriteria = getHashedObject(searchCriteria);
      const isExistingSearchCriteria = Object.keys(savedSearches).includes(
        Object.entries(newSearchCriteria)[0][0]
      );
      setSaveSuccess(isExistingSearchCriteria);
    }
  }, [candidateId, hash]);

  const handleClick = (event) => {
    const { location } = queryStringValues;
    const locationObject = location ? JSON.parse(location) : {};
    let parsedSpecialty = jobsSearchQueryBuilder.getCurrentSpecialties();
    let relatedSpecialtiesArray = queryStringValues?.specsWithRelSpecs
      ? Object.values(JSON.parse(queryStringValues.specsWithRelSpecs))?.flat()
      : [];
    parsedSpecialty = parsedSpecialty ? parsedSpecialty[0][1] : "";
    const showSelectedItems = JSON.parse(sessionStorage.getItem("showSelectedItems"));
    let locationName = "";
    if (enableJobLocationSearch) {
      if (showSelectedItems && showSelectedItems.length > 0) {
        locationName = showSelectedItems[0].city
          ? `${showSelectedItems[0].city}, ${showSelectedItems[0].stateCode}`
          : showSelectedItems[0].plainState;
      }

      let sortedLocationAlphabetically = [];
      let firstLocation = "";
      if (showSelectedItems && showSelectedItems.length > 0) {
        sortedLocationAlphabetically = showSelectedItems.sort((a, b) =>
          a.cityStateCombination.localeCompare(b.cityStateCombination)
        );
        firstLocation =
          sortedLocationAlphabetically[0].city || sortedLocationAlphabetically[0].plainState;
      }

      let specialtyListArray = jobsSearchQueryBuilder
        .getCurrentSpecialties()
        .map((item) => item[0]);
      if (relatedSpecialtiesArray.length > 0) {
        specialtyListArray = specialtyListArray.concat(relatedSpecialtiesArray)
      }
      if (
        showSelectedItems &&
        showSelectedItems.length > 0 &&
        sortedLocationAlphabetically?.length > 1
      ) {
        setSaveSearchName(`${firstLocation} +${sortedLocationAlphabetically.length - 1}`);
      } else if (
        (!showSelectedItems || showSelectedItems?.length === 0) &&
        specialtyListArray?.length > 1
      ) {
        setSaveSearchName(`${parsedSpecialty} +${specialtyListArray.length - 1}`);
      } else {
        setSaveSearchName(locationName || parsedSpecialty);
      }
    } else {
      locationName =
        locationObject && locationObject.city
          ? `${locationObject.city}, ${locationObject.state}`
          : locationObject.plainState;
      setSaveSearchName(locationName || parsedSpecialty);
    }
    setAnchorEl(event.currentTarget);
  };

  const saveSearch = () => {
    handleClose();
    saveSearchedCriteria();
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsEmailNotification(true);
    setEmailNotificationType("daily");
  };

  const getHashedObject = (obj) => ({
    [hash]: {
      name: saveSearchName,
      dateCreated: moment.utc().format(),
      searchCriteria: obj || searchCriteria,
      isEmailNotification,
      emailNotificationType,
    },
  });

  const saveSearchedCriteria = () => {
    const newSearchCriteria = getHashedObject();
    const newSearchObject = Object.assign(savedSearches, newSearchCriteria);
    const reqObj = {
      preferences: {
        bullhornId: atsBullhornCandidateId,
        profession: profession,
        savedSearches: newSearchObject,
      },
    };

    services
      .createOrUpdate(candidateId, reqObj, "savedSearches")
      .then((result) => {
        if (result.statusCode === 200) {
          setSavedSearches({ ...newSearchObject });
          const segmentEventProps = {
            savedSearches: Object.values(newSearchObject),
            segment_source_name: `${process.env.NODE_ENV}-web-gateway`,
          };
          Analytics.segmentTrackEvent(t("segment.jobSavedSearch"), segmentEventProps);
          const savedSearchKey = Object.keys(newSearchCriteria)[0];
          const gaSpecialty = [
            ...new Set(newSearchCriteria[savedSearchKey].searchCriteria.specialty.flat(1)),
          ];
          const gaEvent = {
            category: t("googleAnalytics.category.jobSearch"),
            action: t("googleAnalytics.action.jobSearchSaved"),
            label: t("googleAnalytics.label.savedJobSearch"),
          };
          const { location } = newSearchCriteria[savedSearchKey].searchCriteria;
          const where =
            location.city && location.state
              ? `${location.city}, ${location.state}`
              : location.city
              ? location.city
              : location.state
              ? location.state
              : null;
          const dimensions = {
            dimension11: newSearchCriteria[savedSearchKey].searchCriteria.sort,
            dimension6: where,
            dimension13: newSearchCriteria[savedSearchKey].searchCriteria.durations
              ? newSearchCriteria[savedSearchKey].searchCriteria.durations.join()
              : newSearchCriteria[savedSearchKey].searchCriteria.durations,
            dimension14: newSearchCriteria[savedSearchKey].searchCriteria.salaryMax
              ? parseInt(newSearchCriteria[savedSearchKey].searchCriteria.salaryMax)
              : newSearchCriteria[savedSearchKey].searchCriteria.salaryMax,
            dimension9: gaSpecialty.join(),
            dimension15: newSearchCriteria[savedSearchKey].searchCriteria.startDate,
            dimension16: newSearchCriteria[savedSearchKey].searchCriteria.shiftTypes
              ? newSearchCriteria[savedSearchKey].searchCriteria.shiftTypes.join()
              : newSearchCriteria[savedSearchKey].searchCriteria.shiftTypes,
            dimension17: newSearchCriteria[savedSearchKey].searchCriteria.geoLocationRadius,
            dimension18: newSearchCriteria[savedSearchKey].searchCriteria.shiftLengths
              ? newSearchCriteria[savedSearchKey].searchCriteria.shiftLengths.join()
              : newSearchCriteria[savedSearchKey].searchCriteria.shiftLengths,
            dimension19: newSearchCriteria[savedSearchKey].isEmailNotification,
            dimension20: newSearchCriteria[savedSearchKey].emailNotificationType,
          };
          Analytics.sendPageview(history.location, dimensions, false);
          Analytics.sendEvent(gaEvent);
        }
      })
      .catch((error) => {
        console.error("Error occurred while saving savedSearches:", error);
      });
  };

  const handleChangeWhiteSpace = (value) => value?.replace(/\s+/g, " ").trim();

  const handleBlur = (e) => {
    setSaveSearchName(handleChangeWhiteSpace(e.target.value));
  };

  const handleChange = (e) => {
    setSaveSearchName(e.target.value);
  };

  const handleEmailNotificationChange = (event) => {
    setIsEmailNotification(event.target.checked);
  };

  const handleEmailNotificationTypeChange = (event) => {
    setEmailNotificationType(event.target.value);
  };

  const disableSaveSearch = Object.keys(savedSearches || {}).includes(hash);
  return (
    <span className={classes.saveSearchBtnPos}>
      {disableSaveSearch ? (
        <SuccessButton
          className={isMobile ? classes.searchSavedBtnSuccessMob : classes.disableSuccessBtn}
          size="small"
          startIcon={<CheckIcon className={classes.saveSearchIcon} />}
        >
          {t("savedSearches.searchSavedBtn")}
        </SuccessButton>
      ) : (
        <PrimaryButton
          size="small"
          onClick={handleClick}
          className={classes.primarySaveSearchButton}
        >
          {t("savedSearches.mainBtnPopOverBtn")}
        </PrimaryButton>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            top:"-20px",
            marginTop:"-80px",
            '@media (max-width: 768px)': {
        transform: "none", 
        marginTop: "0px",
      },
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            pointerEvents: "none",
          },
        }}
      >
        <div>
        <div className={classes.popoverMainOverlay} onClick={handleClose}></div>
          <div
            className={classes.popoverMain}
          >
            <div className={classes.popoverHeadTxt}>{t("savedSearches.popoverHeadTxt")}</div>
            <div className={classes.nameSearchTxtBox}>{t("savedSearches.nameSearchTxtLabel")}</div>
            <div>
              <TextField
                variant="outlined"
                className={classes.root}
                value={saveSearchName}
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={(e) => {
                  handleBlur(e);
                }}
              />
            </div>
            <div className="mt-2">
              <NotificationSwitch
                checked={isEmailNotification}
                onChange={handleEmailNotificationChange}
              />
            </div>
            <div className="d-flex">
              <SecondaryButton
                size="small"
                onClick={saveSearch}
                disabled={!handleChangeWhiteSpace(saveSearchName)}
              >
                {t("savedSearches.mainBtnPopOverBtn")}
              </SecondaryButton>
              <span className="ml-3">
                <TertiaryButton onClick={handleClose} size="small">
                  {t("savedSearches.saveSearchCancelBtn")}
                </TertiaryButton>
              </span>
            </div>
          </div>
        </div>
      </Popover>
    </span>
  );
}
