import {
  FETCH_LOCATION_REQUEST,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATION_FAILURE,
  DETECT_LOCATION_INPUT_CLICK,
  FETCH_SPECIALTIES_REQUEST,
  FETCH_SPECIALTIES_SUCCESS,
  FETCH_SPECIALTIES_FAILURE,
  FETCH_RELATED_SPECIALTIES_REQUEST,
  FETCH_RELATED_SPECIALTIES_SUCCESS,
  FETCH_RELATED_SPECIALTIES_FAILURE,
  FETCH_RELATED_SPECIALTIES_FOR_FILTERS_FAILURE,
  FETCH_RELATED_SPECIALTIES_FOR_FILTERS_SUCCESS,
  UPDATE_SPECS_AND_RELATED_SPECS,
  DELETE_SPECS_FROM_MAP,
  CLEAR_SPECS_AND_RELATED_SPECS,
  FETCH_PRIMARY_SPECIALTIES_REQUEST,
  FETCH_PRIMARY_SPECIALTIES_SUCCESS,
  FETCH_PRIMARY_SPECIALTIES_FAILURE,
  FETCH_SELECTED_SPECIALTIES,
  FLUSH_SPECIALTIES,
  TOGGLE_RELATED_SPECIALTIES
} from "./action";

const initialState = {
  location: [],
  selectedLocation: "",
  error: "",
  loading: false,
  locationInputClicked: false,
  specialty: [],
  selectedSpecialties: [],
  SelectedSpecialtiesWithCode: [],
  selectedSpecialtyName: [],
  specsAndRelatedSpecs: new Map(),
  toggleRelatedSpecialties: true,
  filterRelatedSpecs: [],
};
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOCATION_REQUEST:
      return { ...state, loading: true, location: [] };

    case FETCH_LOCATION_SUCCESS:
      return { ...state, loading: false, location: action.payload, error: "" };

    case FETCH_LOCATION_FAILURE:
      return { ...state, loading: false, error: action.payload, location: [] };

    case DETECT_LOCATION_INPUT_CLICK:
      return { ...state, locationInputClicked: action.payload };

    case FETCH_SPECIALTIES_REQUEST:
      return { ...state, loading: true };

    case FETCH_SPECIALTIES_SUCCESS:
      return { ...state, loading: false, specialty: action.payload, error: "" };

    case FETCH_SPECIALTIES_FAILURE:
      return { ...state, loading: false, error: action.payload, specialty: [] };

    case FETCH_RELATED_SPECIALTIES_SUCCESS: {
      // action.payload is an object mapping a specialty to an array of related specialties,
      // e.g. { spec1: ['relSpec1', 'relSpec2'] }
      const payloadData = action.payload;

      // Clone the existing Map to avoid mutating state directly.
      const updatedMap = new Map(state.specsAndRelatedSpecs);

      // Precompute a union of all related specialties from the current map.
      // Array.from(...).flat() returns all the values in a single array.
      const unionSet = new Set(Array.from(updatedMap.values()).flat());

      // Process each payload entry using Object.entries and forEach.
      Object.entries(payloadData).forEach(([spec, relatedSpecs]) => {
        if (updatedMap.has(spec)) {
          // If the specialty already exists, merge its related specialties.
          const existing = updatedMap.get(spec);
          const merged = Array.from(new Set([...existing, ...relatedSpecs]));
          updatedMap.set(spec, merged);
          merged.forEach((item) => unionSet.add(item));
        } else {
          // For a new specialty, check if it or any of its relatedSpecs already exists.
          const conflictFound = unionSet.has(spec) || relatedSpecs.some((rs) => unionSet.has(rs));

          if (conflictFound) {
            // If conflict exists: add the new specialty with an empty array...
            updatedMap.set(spec, []);
            // ... and remove the new specialty from any existing specialty's relatedSpecs.
            Array.from(updatedMap.entries()).forEach(([key, arr]) => {
              if (key !== spec && arr.includes(spec)) {
                updatedMap.set(
                  key,
                  arr.filter((x) => x !== spec)
                );
              }
            });
            unionSet.delete(spec);
          } else {
            // No conflict: add the new specialty as provided.
            updatedMap.set(spec, relatedSpecs);
            relatedSpecs.forEach((rs) => unionSet.add(rs));
          }
        }
      });

      return {
        ...state,
        loading: false,
        specsAndRelatedSpecs: updatedMap,
        error: "",
      };
    }

    case FETCH_RELATED_SPECIALTIES_FAILURE:
      return { ...state, loading: false, error: action.payload, relatedSpecialties: [] };

    case FETCH_RELATED_SPECIALTIES_FOR_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        filterRelatedSpecs: action.payload,
        error: "",
      };

    case FETCH_RELATED_SPECIALTIES_FOR_FILTERS_FAILURE:
      return { ...state, loading: false, error: action.payload, filterRelatedSpecs: [] };

    case UPDATE_SPECS_AND_RELATED_SPECS: {
      return {
        ...state,
        // Update the Map directly (since Map is mutable, make sure to pass a new Map instance)
        specsAndRelatedSpecs: action.payload,
      };
    }

    /*
      Delete the Specialties from specsAndRelatedSpecs which are not available
      in user selected specialties array
    */
    case DELETE_SPECS_FROM_MAP: {
      const stateMap = new Map(state.specsAndRelatedSpecs)
      const specsSelectedByUser = action.payload

      // Iterate over each key in the Map.
      stateMap?.forEach((_, key) => {
        // If a specialty from the Map is not in the specialties array, delete it.
        if (!specsSelectedByUser.includes(key)) {
          stateMap.delete(key);
        }
      });

      return {
        ...state,
        specsAndRelatedSpecs: stateMap
      }
    }

    case CLEAR_SPECS_AND_RELATED_SPECS:
      return {
        ...state,
        specsAndRelatedSpecs: new Map(),
      };

    case TOGGLE_RELATED_SPECIALTIES:
      return { ...state, loading: false, error: "", toggleRelatedSpecialties: action.payload }

    case FLUSH_SPECIALTIES:
      return { ...state, loading: false, specialty: [] };

    case FETCH_PRIMARY_SPECIALTIES_REQUEST:
      return { ...state, loading: true };

    case FETCH_PRIMARY_SPECIALTIES_SUCCESS:
      return { ...state, loading: false, specialty: action.payload, error: "" };

    case FETCH_PRIMARY_SPECIALTIES_FAILURE:
      return { ...state, loading: false, error: action.payload, specialty: [] };

    case FETCH_SELECTED_SPECIALTIES:
      return {
        ...state,
        selectedSpecialtyName: action.payload,
        selectedSpecialties: action.specialtyCode,
        SelectedSpecialtiesWithCode: action.specialtiesWithCode,
      };
    default:
      return state;
  }
};

export default rootReducer;
