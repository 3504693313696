import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { parse } from "date-fns";
import update from "immutability-helper";
import { withRouter } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import Chip from "@material-ui/core/Chip";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { isMobile } from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";
import { fetchProfessions } from "../../../redux/profession/action";
import { fetchSpecialties } from "../../../redux/globalSearch/action";
import {
  updateCompleteFlag,
  updateActiveFlag,
  clearBasicInfoResponse,
  saveUserProfile,
  updateOnboardingCompletedSections,
  updateWorkExperience,
} from "../../../redux/onBoarding/action";
import emrExpList from "../../../constants/onBoardingEmr.json";
import reasonForLeavingList from "../../../constants/onBoardingReasonForLeaving.json";
import Analytics from "../../../services/Analytics";
import { EDU_SPEC_PAGE, REFERENCES_PAGE, DASHBOARD } from "../../../router/routes";
import workExpConstraints from "../../../services/WorkExpConstraint";
import MultipleSelect from "./onBBasicInfoSelect";
import facilityType from "../../../constants/facilityType.json";
import onboardingFields from "../../../constants/onboardingFieldNames.json";
import { WORK_EXPERIENCE, SPECIALTIES_AND_EDUCATION } from "../../../constants/onboardingEventName";
import validationsService from "../../../services/Validations";
import positionType from "../../../constants/positionType.json";
import PopupModal from "./OnBoardingErrorMsgModal";
import { OnBoardingConfirmModal } from "./OnBoardingConfirmModal";
import analyticsSegmentIdentify from "../../../services/SegmentIdentify";
import { PrimaryButton, SecondaryButton, TertiaryButton } from "../Buttons";
import { SET_WORK_EXPERIENCE, UPDATE_WORK_EXP_STATUS } from "src/services/GraphQL/graph.query.ts";
import { apolloClient, graphqlRequest as request } from "src/services/GraphQL/GraphQL";
import config from "src/config";
import "./OnBoardingStyles/WorkExperience.scss";
import { SuccessButton } from "src/components/common/Buttons";
import moment from "moment";
import { uuid } from "uuidv4";
import Image from "react-bootstrap/Image";
import OnBWorkExperienceVerify from "src/images/onboarding/onBWorkExperienceVerify.svg";
import SnackbarReturnToSource from "src/components/common/SnackbarReturnToSource/SnackbarReturnToSource.tsx";
import MyProfileNavWrapper from "./MyProfileNavWrapper";

const enableReferences = config.ENABLE_REFERENCES === "true";
const perStepPercentage = enableReferences ? 20 : 25;
const workHistoryAutomationXperienceId = config.WORK_HISTORY_AUTOMATION_XPERIENCE_ID
  ? config.WORK_HISTORY_AUTOMATION_XPERIENCE_ID
  : "0";
const defaultReasonForLeaving = "Assignment Completed";
const enableWorkHistory = config.ENABLE_WORK_HISTORY === "true";
const workExpIsDeleted = config.WORK_EXPERIENCES_IS_DELETED === "true";
const workHistoryAutomation = config.ENABLE_WORK_HISTORY_AUTOMATION === "true";
const enableIsLegalQuestion = config.ENABLE_IS_LEGAL_QUESTION === "true";
const styles = (theme) => ({
  nextBtnMarginleft: {
    marginLeft: "auto",
  },
  outlineBtnColor: {
    border: "1px solid #707070 !important",
    color: "#707070",
  },
  iconPaddingBottom: {
    paddingBottom: 2,
  },
});

class OnBoardingWorkExperience extends Component {
  constructor(props) {
    super(props);
    validationsService.init();
    validationsService.setCustomWorkExpValidations();
    const onboardingWorkExFromDbArray = [];
    if (this.props.onboardingWorkExperience && this.props.onboardingWorkExperience.length > 0) {
      for (let i = 0; i < this.props.onboardingWorkExperience.length; i++) {
        const onboardingWorkExFromDb = {
          meta: this.props.onboardingWorkExperience[i]?.meta
            ? this.props.onboardingWorkExperience[i]?.meta
            : { id: null, associations: [] },
          facilityName: this.props.onboardingWorkExperience[i].facilityName
            ? this.props.onboardingWorkExperience[i].facilityName
            : null,
          city: this.props.onboardingWorkExperience[i].city
            ? this.props.onboardingWorkExperience[i].city
            : null,
          profession: this.props.onboardingWorkExperience[i].profession
            ? this.props.onboardingWorkExperience[i].profession
            : null,
          specialty: this.props.onboardingWorkExperience[i].specialty
            ? this.props.onboardingWorkExperience[i].specialty
            : null,
          position: this.props.onboardingWorkExperience[i].position
            ? validationsService.verifyDropdownValues(
                "single",
                "position",
                this.props.onboardingWorkExperience[i].position,
                positionType.type,
                "workExperience"
              )
            : null,
          supervisor: this.props.onboardingWorkExperience[i].supervisor
            ? this.props.onboardingWorkExperience[i].supervisor
            : null,
          supervisorPhone: this.props.onboardingWorkExperience[i].supervisorPhone
            ? this.props.onboardingWorkExperience[i].supervisorPhone
            : null,
          email: this.props.onboardingWorkExperience[i].email
            ? this.props.onboardingWorkExperience[i].email
            : null,
          shiftWorked: this.props.onboardingWorkExperience[i].shiftWorked
            ? this.props.onboardingWorkExperience[i].shiftWorked
            : null,
          emrTechnology: this.props.onboardingWorkExperience[i].emrTechnology
            ? validationsService.verifyDropdownValues(
                "single",
                "emrTechnology",
                this.props.onboardingWorkExperience[i].emrTechnology,
                emrExpList.emrExp,
                "workExperience"
              )
            : null,
          floatingPlace: this.props.onboardingWorkExperience[i].floatingPlace
            ? this.props.onboardingWorkExperience[i].floatingPlace
            : null,
          isCurrentlyWorking:
            this.props.onboardingWorkExperience[i].isCurrentlyWorking ||
            this.props.onboardingWorkExperience[i].isCurrentlyWorking === false
              ? this.props.onboardingWorkExperience[i].isCurrentlyWorking.toString()
              : null,
          startDate: this.props.onboardingWorkExperience[i].startDate
            ? this.props.onboardingWorkExperience[i].startDate
            : null,
          endDate: this.props.onboardingWorkExperience[i].endDate
            ? this.props.onboardingWorkExperience[i].endDate
            : null,
          reasonForLeaving: this.props.onboardingWorkExperience[i].reasonForLeaving
            ? validationsService.verifyDropdownValues(
                "single",
                "reasonForLeaving",
                this.props.onboardingWorkExperience[i].reasonForLeaving,
                reasonForLeavingList.reasonForLeaving,
                "workExperience"
              )
            : workHistoryAutomation
            ? defaultReasonForLeaving
            : null,
          isTempJob:
            this.props.onboardingWorkExperience[i].isTempJob ||
            this.props.onboardingWorkExperience[i].isTempJob === false
              ? this.props.onboardingWorkExperience[i].staffingAgency &&
                this.props.onboardingWorkExperience[i].isTempJob === false
                ? "true"
                : workHistoryAutomation
                ? "true"
                : this.props.onboardingWorkExperience[i].isTempJob.toString()
              : workHistoryAutomation
              ? "true"
              : null,
          staffingAgency: this.props.onboardingWorkExperience[i].staffingAgency
            ? this.props.onboardingWorkExperience[i].staffingAgency
            : workHistoryAutomation
            ? this.props.t("onBoarding.workExp.defaultStaffingAgency")
            : null,
          facilityType: this.props.onboardingWorkExperience[i].facilityType
            ? validationsService.verifyDropdownValues(
                "single",
                "facilityType",
                this.props.onboardingWorkExperience[i].facilityType,
                facilityType.types,
                "workExperience"
              )
            : null,
          patientRatio: this.props.onboardingWorkExperience[i].patientRatio
            ? this.props.onboardingWorkExperience[i].patientRatio
            : null,
          teachingFacility:
            this.props.onboardingWorkExperience[i].teachingFacility ||
            this.props.onboardingWorkExperience[i].teachingFacility === false
              ? this.props.onboardingWorkExperience[i].teachingFacility.toString()
              : null,
          chargeExperience:
            this.props.onboardingWorkExperience[i].chargeExperience ||
            this.props.onboardingWorkExperience[i].chargeExperience === false
              ? this.props.onboardingWorkExperience[i].chargeExperience.toString()
              : null,
          isVerified:
            this.props?.onboardingWorkExperience[i]?.isVerified ||
            this.props?.onboardingWorkExperience[i]?.isVerified === false
              ? this.props?.onboardingWorkExperience[i]?.isVerified?.toString()
              : null,
          showVerifyNowButton: this.displayVerifyNow(this.props.onboardingWorkExperience[i]),
        };
        onboardingWorkExFromDbArray.push(onboardingWorkExFromDb);
      }
    }
    this.state = {
      backButtonClicked: false,
      workExpFields: onboardingFields.workHistory,
      gray: true,
      disableButton: !(
        onboardingWorkExFromDbArray.length > 0 &&
        this.props.onboardingSpecialtiesAndEducation.isMalpracticeClaim !== null &&
        (enableIsLegalQuestion && this.props.onboardingSpecialtiesAndEducation.isLegal !== null)
      ),
      openSpecialty: false,
      openProfession: false,
      openUnitOfFloating: false,
      addWorkHistoryDisableBtn: true,
      addWorkHistoryBtnClicked: onboardingWorkExFromDbArray.length > 0,
      typeOfPositionValues: positionType.type,
      facilityName: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      city: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      profession: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      specialty: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      position: {
        value: "",
      },
      supervisor: {
        value: "",
        errors: false,
        errorText: "",
      },
      supervisorPhone: {
        value: "",
        errors: false,
        errorText: "",
      },
      email: {
        value: "",
        errors: false,
        errorText: "",
      },
      shiftWorked: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      emrTechnology: {
        value: "",
      },
      floatingPlace: {
        value: null,
      },
      isCurrentlyWorking: {
        value: workHistoryAutomation ? "true" : null,
        errors: !workHistoryAutomation,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      startDate: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      endDate: {
        value: null,
        errors: false,
        errorText: null,
      },
      reasonForLeaving: {
        value: null,
        errors: false,
        errorText: null,
      },
      malpractices: {
        value:
          this.props.onboardingSpecialtiesAndEducation &&
          (this.props.onboardingSpecialtiesAndEducation.isMalpracticeClaim ||
            this.props.onboardingSpecialtiesAndEducation.isMalpracticeClaim === false)
            ? this.props.onboardingSpecialtiesAndEducation.isMalpracticeClaim.toString()
            : null,
        errors: !(
          this.props.onboardingSpecialtiesAndEducation &&
          (this.props.onboardingSpecialtiesAndEducation.isMalpracticeClaim ||
            this.props.onboardingSpecialtiesAndEducation.isMalpracticeClaim === false)
        ),
        errorText:
          this.props.onboardingSpecialtiesAndEducation &&
          (this.props.onboardingSpecialtiesAndEducation.isMalpracticeClaim ||
            this.props.onboardingSpecialtiesAndEducation.isMalpracticeClaim === false)
            ? ""
            : this.props.t("onBoarding.errorMessage.required"),
      },
      ...(enableIsLegalQuestion && { isLegalQuestion: {
        value:
          this.props.onboardingSpecialtiesAndEducation &&
          (this.props.onboardingSpecialtiesAndEducation.isLegal ||
            this.props.onboardingSpecialtiesAndEducation.isLegal === false)
            ? this.props.onboardingSpecialtiesAndEducation.isLegal.toString()
            : null,
        errors: !(
          this.props.onboardingSpecialtiesAndEducation &&
          (this.props.onboardingSpecialtiesAndEducation.isLegal ||
            this.props.onboardingSpecialtiesAndEducation.isLegal === false)
        ),
        errorText:
          this.props.onboardingSpecialtiesAndEducation &&
          (this.props.onboardingSpecialtiesAndEducation.isLegal ||
            this.props.onboardingSpecialtiesAndEducation.isLegal === false)
            ? ""
            : this.props.t("onBoarding.errorMessage.required"),
      } }),
      isTempJob: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      facilityType: {
        value: "",
        errors: false,
        errorText: null,
      },
      patientRatio: {
        value: "",
        errors: false,
        errorText: null,
      },
      teachingFacility: {
        value: null,
        errors: false,
        errorText: null,
      },
      chargeExperience: {
        value: null,
        errors: false,
        errorText: null,
      },
      staffingAgency: { value: "", errors: false, errorText: null },
      workExperiences: onboardingWorkExFromDbArray,
      index: 0,
      disableCancelButton: true,
      isRecordToEdit: false,
      isRecordUpdated: false,
      disableActions: false,
      disableUpdateButton: true,
      autocompleteKey: new Date().getTime(),
      isOptionOpen: false,
      isUnitOfFloatToEdit: false,
      hideWorkExpForm: !!(onboardingWorkExFromDbArray && onboardingWorkExFromDbArray.length > 0),
      workExpReqObj: null,
      specialtyAndEduReqObj: null,
      openModal: false,
      isVerified: false,
      hasUnsavedChanges: false,
      isRecordAdded: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.userId && this.props.email) {
      analyticsSegmentIdentify(this.props);
    }
    const profileProgress = this.profileProgressInPercentage();
    const segmentEventName = this.props.t("segment.profileViewed");
    const segmentEventProps = {
      section: this.props.t("myProfileSubNav.workExperience"),
      progress: profileProgress,
      origin: document.referrer,
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
    this.handleIsVerifyClicked();
  }

  profileProgressInPercentage() {
    let completedPercentage = 0;
    let noOfStepsCompleted = 0;
    for (const prop in this.props.onBoardingCompletedSteps) {
      if (Object.prototype.hasOwnProperty.call(this.props.onBoardingCompletedSteps, prop)) {
        noOfStepsCompleted = noOfStepsCompleted + 1;
      }
    }

    completedPercentage = noOfStepsCompleted * perStepPercentage;

    return completedPercentage;
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.email !== prevProps.email ||
      this.props.recruiterDetails !== prevProps.recruiterDetails
    ) {
      analyticsSegmentIdentify(this.props);
    }
    if (
      this.props.onboardingWorkExperience !== prevProps.onboardingWorkExperience ||
      this.props.saveBasicInfoResponse !== prevProps.saveBasicInfoResponse
    ) {
      if (
        this.props.saveBasicInfoResponse &&
        this.props.saveBasicInfoResponse.content &&
        this.props.saveBasicInfoResponse.content.length > 0
      ) {
        this.setState({
          addWorkHistoryBtnClicked: this.state.workExperiences.length > 0,
          workExperiences: this.state.workExperiences,
        });
      } else {
        const onboardingWorkExFromDbArray = [];
        if (this.props.onboardingWorkExperience && this.props.onboardingWorkExperience.length > 0) {
          for (let i = 0; i < this.props.onboardingWorkExperience.length; i++) {
            const onboardingWorkExFromDb = {
              facilityName: this.props.onboardingWorkExperience[i].facilityName
                ? this.props.onboardingWorkExperience[i].facilityName
                : null,
              city: this.props.onboardingWorkExperience[i].city
                ? this.props.onboardingWorkExperience[i].city
                : null,
              profession: this.props.onboardingWorkExperience[i].profession
                ? this.props.onboardingWorkExperience[i].profession
                : null,
              specialty: this.props.onboardingWorkExperience[i].specialty
                ? this.props.onboardingWorkExperience[i].specialty
                : null,
              position: this.props.onboardingWorkExperience[i].position
                ? validationsService.verifyDropdownValues(
                    "single",
                    "position",
                    this.props.onboardingWorkExperience[i].position,
                    positionType.type,
                    "workExperience"
                  )
                : null,
              supervisor: this.props.onboardingWorkExperience[i].supervisor
                ? this.props.onboardingWorkExperience[i].supervisor
                : null,
              supervisorPhone: this.props.onboardingWorkExperience[i].supervisorPhone
                ? this.props.onboardingWorkExperience[i].supervisorPhone
                : null,
              email: this.props.onboardingWorkExperience[i].email
                ? this.props.onboardingWorkExperience[i].email
                : null,
              shiftWorked: this.props.onboardingWorkExperience[i].shiftWorked
                ? this.props.onboardingWorkExperience[i].shiftWorked
                : null,
              emrTechnology: this.props.onboardingWorkExperience[i].emrTechnology
                ? validationsService.verifyDropdownValues(
                    "single",
                    "emrTechnology",
                    this.props.onboardingWorkExperience[i].emrTechnology,
                    emrExpList.emrExp,
                    "workExperience"
                  )
                : null,
              floatingPlace: this.props.onboardingWorkExperience[i].floatingPlace
                ? this.props.onboardingWorkExperience[i].floatingPlace
                : null,
              isCurrentlyWorking:
                this.props.onboardingWorkExperience[i].isCurrentlyWorking ||
                this.props.onboardingWorkExperience[i].isCurrentlyWorking === false
                  ? this.props.onboardingWorkExperience[i].isCurrentlyWorking.toString()
                  : null,
              startDate: this.props.onboardingWorkExperience[i].startDate
                ? this.props.onboardingWorkExperience[i].startDate
                : null,
              endDate: this.props.onboardingWorkExperience[i].endDate
                ? this.props.onboardingWorkExperience[i].endDate
                : null,
              reasonForLeaving: this.props.onboardingWorkExperience[i].reasonForLeaving
                ? validationsService.verifyDropdownValues(
                    "single",
                    "reasonForLeaving",
                    this.props.onboardingWorkExperience[i].reasonForLeaving,
                    reasonForLeavingList.reasonForLeaving,
                    "workExperience"
                  )
                : null,
              isTempJob:
                this.props.onboardingWorkExperience[i].isTempJob ||
                this.props.onboardingWorkExperience[i].isTempJob === false
                  ? this.props.onboardingWorkExperience[i].staffingAgency &&
                    this.props.onboardingWorkExperience[i].isTempJob === false
                    ? "true"
                    : this.props.onboardingWorkExperience[i].isTempJob.toString()
                  : null,
              staffingAgency: this.props.onboardingWorkExperience[i].staffingAgency
                ? this.props.onboardingWorkExperience[i].staffingAgency
                : null,
              facilityType: this.props.onboardingWorkExperience[i].facilityType
                ? validationsService.verifyDropdownValues(
                    "single",
                    "facilityType",
                    this.props.onboardingWorkExperience[i].facilityType,
                    facilityType.types,
                    "workExperience"
                  )
                : null,
              patientRatio: this.props.onboardingWorkExperience[i].patientRatio
                ? this.props.onboardingWorkExperience[i].patientRatio
                : null,
              teachingFacility:
                this.props.onboardingWorkExperience[i].teachingFacility ||
                this.props.onboardingWorkExperience[i].teachingFacility === false
                  ? this.props.onboardingWorkExperience[i].teachingFacility.toString()
                  : null,
              chargeExperience:
                this.props.onboardingWorkExperience[i].chargeExperience ||
                this.props.onboardingWorkExperience[i].chargeExperience === false
                  ? this.props.onboardingWorkExperience[i].chargeExperience.toString()
                  : null,
              isVerified:
                this.props?.onboardingWorkExperience[i]?.isVerified ||
                this.props?.onboardingWorkExperience[i]?.isVerified === false
                  ? this.props?.onboardingWorkExperience[i]?.isVerified?.toString()
                  : null,
              showVerifyNowButton: this.displayVerifyNow(this.props.onboardingWorkExperience[i]),
            };
            onboardingWorkExFromDbArray.push(onboardingWorkExFromDb);
          }
        }
        this.setState({
          addWorkHistoryBtnClicked: onboardingWorkExFromDbArray.length > 0,
          workExperiences: onboardingWorkExFromDbArray,
          workExpReqObj: null,
          specialtyAndEduReqObj: null,
          hideWorkExpForm: !!(
            onboardingWorkExFromDbArray && onboardingWorkExFromDbArray.length > 0
          ),
         ...(![this.state.isRecordAdded, this.state.isRecordUpdated].some(value => value) && { malpractices: {
            ...this.state.malpractices,
            value:
              this.props.onboardingSpecialtiesAndEducation &&
              (this.props.onboardingSpecialtiesAndEducation.isMalpracticeClaim ||
                this.props.onboardingSpecialtiesAndEducation.isMalpracticeClaim === false)
                ? this.props.onboardingSpecialtiesAndEducation.isMalpracticeClaim.toString()
                : null,
          }}),
          ...(enableIsLegalQuestion && ![this.state.isRecordAdded, this.state.isRecordUpdated].some(value => value) && {
            isLegalQuestion: {
              ...this.state.isLegalQuestion,
              value:
                this.props.onboardingSpecialtiesAndEducation &&
                  (this.props.onboardingSpecialtiesAndEducation.isLegal ||
                    this.props.onboardingSpecialtiesAndEducation.isLegal === false)
                  ? this.props.onboardingSpecialtiesAndEducation.isLegal.toString()
                  : null,
            }
          }),
        });
      }
    }
  }

  /* Triggered when input value changes */
  handleChange = async (fieldType, e) => {
    this.setState({ openModal: true });
    const fieldValue = e.target.value && e.target.value.trim() !== "" ? e.target.value : "";
    await this.setState((prevState) => {
      const validators = validationsService.validateSingleField(
        fieldValue,
        workExpConstraints.workExpFormConstraints[fieldType]
      );
      if (
        fieldType === "weeklyHours" ||
        fieldType === "facilityBeds" ||
        fieldType === "facilityUnitBeds" ||
        fieldType === "supervisor" ||
        fieldType === "supervisorPhone"
      ) {
        return {
          [fieldType]: {
            value: fieldValue,
            errors: !!(validators && fieldValue.trim() !== ""),
            errorText: validators && fieldValue.trim() !== "" ? validators[0] : null,
          },
        };
      } else {
        return {
          [fieldType]: {
            value: fieldValue,
            errors: !!validators,
            errorText: validators ? validators[0] : null,
          },
        };
      }
    });
    this.setState({
      addWorkHistoryDisableBtn: await this.checkSaveBtnStatus(),
      disableCancelButton: false,
    });
  };

  renderAutocomplete(type) {
    let id;
    let inputValue;
    let options;
    let open;
    if (type === "specialty") {
      id = "specialty";
      inputValue = this.state.specialty.value;
      options = this.props.specialtyAutosuggest.slice(0, 5);
      open = this.state.openSpecialty;
    }
    if (type === "profession") {
      id = "profession";
      inputValue = this.state.profession.value;
      options = this.props.professionAutosuggest.slice(0, 5);
      open = this.state.openProfession;
    }
    if (type === "floatingPlace") {
      id = "floatingPlace";
      inputValue = this.state.floatingPlace.value;
      options = this.props.specialtyAutosuggest.slice(0, 5);
      open = this.state.openUnitOfFloating;
    }
    return (
      <FormControl>
        <Autocomplete
          id={id}
          className="onBWorkAuto"
          inputValue={inputValue ? inputValue : ""}
          autoHighlight
          options={options}
          getOptionLabel={(option) => option.name}
          renderOption={this.renderOption.bind(this, type)}
          renderInput={this.renderTextField.bind(this, type)}
          onInputChange={this.onInputChange.bind(this, type)}
          onChange={this.onChange.bind(this, type)}
          open={open}
          key={this.state.autocompleteKey}
          helperText={
            this.state.autocompleteKey.errors ? this.state.autocompleteKey.errorText : null
          }
        />
      </FormControl>
    );
  }

  /* Used for displaying specialty/profession auto-suggest options*/
  renderOption(type, option) {
    let optionText;
    if (type === "specialty") optionText = option.name;
    if (type === "profession") optionText = option.name;
    if (type === "floatingPlace") optionText = `${option.name}(${option.code})`;

    return <>{optionText}</>;
  }

  /* Used for rendering specialty/profession TextField */
  renderTextField(type, params) {
    let label;
    let helperText;
    type === "specialty"
      ? (label = this.props.t("onBoarding.workExp.specialty"))
      : (label = this.props.t("onBoarding.workExp.profession"));
    if (type === "specialty") {
      label = this.props.t("onBoarding.workExp.specialty");
      this.state.specialty.errors
        ? (helperText = this.state.specialty.errorText)
        : (helperText = null);
    }
    if (type === "profession") {
      label = this.props.t("onBoarding.workExp.profession");
      this.state.profession.errors
        ? (helperText = this.state.profession.errorText)
        : (helperText = null);
    }
    if (type === "floatingPlace") {
      label = this.props.t("onBoarding.workExp.whrFloatToLbl");
    }
    return (
      <TextField
        {...params}
        label={label}
        helperText={helperText}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  onInputChange = async (type, event, value) => {
    this.setState({ openModal: true });
    let fieldName;
    let validators;
    const eventType = event ? event.type : null;
    if (type === "specialty") fieldName = "openSpecialty";
    if (type === "profession") fieldName = "openProfession";
    if (type === "floatingPlace") {
      fieldName = "openUnitOfFloating";
      if (this.state.isRecordToEdit && this.state.isUnitOfFloatToEdit) {
        value = this.state.floatingPlace.value ? this.state.floatingPlace.value : value;
      }
    }
    if (eventType) {
      validators = validationsService.validateSingleField(
        value,
        workExpConstraints.workExpFormConstraints[type]
      );
      await this.setState({
        [type]: {
          value: value.trim() !== "" ? value : "",
          errors: !!validators,
          errorText: validators ? validators[0] : null,
        },
        isOptionOpen: !(validators || value.trim() === ""),
        isUnitOfFloatToEdit: false,
      });
      this.setState({
        [fieldName]: !!this.state.isOptionOpen,
        addWorkHistoryDisableBtn: await this.checkSaveBtnStatus(),
      });

      if (eventType && eventType.trim().toString() === "blur") {
        await this.setState({
          [fieldName]: false,
          [type]: {
            value: value.trim() !== "" ? value : "",
            errors: !!validators,
            errorText: validators ? validators[0] : null,
          },
        });
      }
      if (type === "specialty" || type === "floatingPlace")
        this.props.getSpecialties(value, false, this.props.company.id);
      if (type === "profession") this.props.getProfessions(value, this.props.company.id);
    }
  };

  /* Used when profession's/specialty's autosuggest's option is selected. */
  onChange = async (type, event, value) => {
    this.setState({ openModal: true });
    let fieldName;
    if (type === "specialty") fieldName = "openSpecialty";
    if (type === "profession") fieldName = "openProfession";
    if (type === "floatingPlace") fieldName = "openUnitOfFloating";
    if (value) {
      const validator = validationsService.validateSingleField(
        value.name,
        workExpConstraints.workExpFormConstraints[type]
      );
      await this.setState({
        [type]: {
          value: value.name,
          errors: !!validator,
          errorText: validator ? validator[0] : null,
        },
        [fieldName]: false,
        isOptionOpen: false,
        isUnitOfFloatToEdit: false,
        disableCancelButton: false,
      });
      this.setState({
        addWorkHistoryDisableBtn: await this.checkSaveBtnStatus(),
      });
    }
  };

  radioBtnHandleChange = async (type, event) => {
    this.setState({ openModal: true });
    if (type === "isTempJob") {
      if (event.target.value === "false") {
        this.setState((prevState) => ({
          staffingAgency: { value: null, errors: false, errorText: null },
        }));
      } else {
        this.setState({
          staffingAgency: {
            value: "",
            errors: true,
            errorText: this.props.t("onBoarding.errorMessage.required"),
          },
        });
      }
      const validator = validationsService.validateSingleField(
        event.target.value,
        workExpConstraints.workExpFormConstraints[type]
      );
      await this.setState({
        [type]: {
          value: event.target.value,
          errors: !!validator,
          errorText: validator ? validator[0] : null,
        },
      });
    }
    if (type === "isCurrentlyWorking") {
      if (event.target.value === "false") {
        this.setState({
          endDate: {
            value: null,
            errors: true,
            errorText: this.props.t("onBoarding.errorMessage.required"),
          },
          reasonForLeaving: {
            value: "",
            errors: true,
            errorText: this.props.t("onBoarding.errorMessage.required"),
          },
        });
      } else {
        this.setState({
          endDate: { value: null, errors: false, errorText: null },
          reasonForLeaving: { value: "", errors: false, errorText: null },
        });
      }
      const validator = validationsService.validateSingleField(
        event.target.value,
        workExpConstraints.workExpFormConstraints[type]
      );
      await this.setState({
        [type]: {
          value: event.target.value,
          errors: !!validator,
          errorText: validator ? validator[0] : null,
        },
      });
    }
    if (type === "malpractices") {
      const validator = validationsService.validateSingleField(
        event.target.value,
        workExpConstraints.workExpPageConstraints[type]
      );
      await this.setState({
        [type]: {
          value: event.target.value,
          errors: !!validator,
          errorText: validator ? validator[0] : null,
        },
      });
    }
    if (type === "isLegalQuestion") {
      const validator = validationsService.validateSingleField(
        event.target.value,
        workExpConstraints.workExpPageConstraints[type]
      );
      await this.setState({
        [type]: {
          value: event.target.value,
          errors: !!validator,
          errorText: validator ? validator[0] : null,
        },
      });
    }
    if (type === "teachingFacility") {
      await this.setState({
        [type]: {
          value: event.target.value,
          errors: false,
          errorText: null,
        },
      });
    }
    if (type === "chargeExperience") {
      await this.setState({
        [type]: {
          value: event.target.value,
          errors: false,
          errorText: null,
        },
      });
    }
    this.setState({
      addWorkHistoryDisableBtn: await this.checkSaveBtnStatus(),
      disableCancelButton: false,
    });
  };

  handleDateChange = async (type, date, value) => {
    this.setState({ openModal: true });
    const validator = validationsService.validateSingleField(
      value,
      workExpConstraints.workExpFormConstraints[type]
    );
    if ((!value || (value && value.trim === "")) && type === "endDate") {
      await this.setState({
        [type]: {
          value,
          errors: true,
          errorText: this.props.t("onBoarding.errorMessage.required"),
        },
      });
    } else {
      await this.setState({
        [type]: {
          value,
          errors: !!validator,
          errorText: validator ? validator[0] : null,
        },
      });
    }

    this.setState({
      addWorkHistoryDisableBtn: await this.checkSaveBtnStatus(),
      disableCancelButton: false,
    });
  };

  convertToDateFormat = (newDate) => {
    return newDate
      ? parse(moment.utc(newDate).format("MM/DD/YYYY"), "MM/dd/yyyy", new Date())
      : null;
  };

  handleMultipleSelect = async (event, type) => {
    await this.setState({
      [type]: { value: event.target.value, errors: false, errorText: null },
      openModal: true,
    });
    this.setState({
      addWorkHistoryDisableBtn: await this.checkSaveBtnStatus(),
      disableCancelButton: false,
    });
  };

  renderAddMoreExperienceBtn(type, index) {
    if (this.state.hideWorkExpForm) {
      return (
        <Row>
          <Col sm={12} xs={12} md={12} lg={12}>
            <div className="mt-4">
              <PrimaryButton
                onClick={this.addWorkExperience.bind(this)}
                size="medium"
                className="onBFullWidthMob"
              >
                <AddIcon fontSize="small" className={this.props.classes.iconPaddingBottom} />
                <span>&nbsp;{this.props.t("onBoarding.workExp.addMoreExpBtnLbl")}</span>
              </PrimaryButton>
            </div>
          </Col>
        </Row>
      );
    }
  }

  renderButtons(type, index) {
    if (this.state.isRecordToEdit && !this.state.isRecordUpdated) {
      return (
        <Row>
          <Col sm={12} xs={12} md={12} lg={12}>
            <div className="onBWorkExpSaveBtn d-flex justify-content-center">
              <PrimaryButton
                size="medium"
                onClick={this.updateRecord.bind(this, this.state.index)}
                disabled={this.state.disableUpdateButton}
              >
                {this.props.t("onBoarding.edu&spec.updateBtnLabel")}
              </PrimaryButton>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <TertiaryButton
                size="medium"
                className={this.props.classes.outlineBtnColor}
                onClick={this.cancelUpdate.bind(this)}
              >
                {this.props.t("onBoarding.edu&spec.cancelBtnLabel")}
              </TertiaryButton>
            </div>
          </Col>
        </Row>
      );
    }
    return (
      <Row>
        <Col sm={12} xs={12} md={12} lg={12}>
          <div className="onBWorkExpSaveBtn">
            <div>
              {this.state.workExperiences.length > 0 ? (
                <div className="d-flex justify-content-center">
                  <PrimaryButton
                    onClick={this.saveWorkExp.bind(this)}
                    disabled={this.state.addWorkHistoryDisableBtn}
                    size="medium"
                  >
                    {this.props.t("onBoarding.workExp.saveBtnLbl")}
                  </PrimaryButton>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <TertiaryButton
                    size="medium"
                    className={this.props.classes.outlineBtnColor}
                    onClick={this.cancelUpdate.bind(this)}
                    disabled={this.state.disableCancelButton}
                  >
                    {this.props.t("onBoarding.edu&spec.cancelBtnLabel")}
                  </TertiaryButton>
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <PrimaryButton
                    onClick={this.saveWorkExp.bind(this)}
                    disabled={this.state.addWorkHistoryDisableBtn}
                    size="medium"
                  >
                    {this.props.t("onBoarding.workExp.saveBtnLbl")}
                  </PrimaryButton>
                </div>
              )}
            </div>
          </div>
          <div className="onBBorderBtm" />
        </Col>
      </Row>
    );
  }

  handleBackBtnClick = async () => {
    const { workExperienceReqObj, canBeSavedPartially, specialtyAndEduReqObj } =
      await this.normalizeSpecialtiesAndEducationJson();

    this.setState(
      {
        openModal: false,
      },
      () => {
        if (canBeSavedPartially) {
          //Save Data PopUp
          this.setState({
            backButtonClicked: true,
            specialtyAndEduReqObj,
            workExpReqObj: workExperienceReqObj,
          });
          this.disableScroll();
        } else {
          //Invalid Fields PopUp
          this.setState({
            btnType: "back",
            canBeSavedPartially,
          });
        }
      }
    );
  };

  handleYesClicked = async () => {
    this.saveData("Back");
    if (
      this.props.saveBasicInfoResponse &&
      this.props.saveBasicInfoResponse.content &&
      this.props.saveBasicInfoResponse.content.length > 0
    ) {
      this.props.clearBasicInfoResponse();
    }
    this.enableScroll();
  };

  handleNoClicked = async () => {
    this.setState({
      backButtonClicked: false,
    });
    this.props.updateActiveFlag(1);
    this.enableScroll();
    this.props.history.push(EDU_SPEC_PAGE);
  };

  handleClose = async () => {
    this.setState({
      backButtonClicked: false,
    });
  };

  disableScroll = async () => {
    window.addEventListener("scroll", this.noScroll.bind(this));
  };

  enableScroll = async () => {
    window.removeEventListener("scroll", this.noScroll.bind(this));
  };

  noScroll = async () => {
    document.getElementById("backButtonPopUp") &&
      document.getElementById("backButtonPopUp").scrollIntoView({ block: "center" });
  };

  handleBtnClick = async (redirection) => {
    const { workExperienceReqObj, canBeSavedPartially, specialtyAndEduReqObj, validators } =
      await this.normalizeSpecialtiesAndEducationJson();

    this.setState({ openModal: false });
    if (canBeSavedPartially) {
      await this.setState({
        workExpReqObj: workExperienceReqObj,
        specialtyAndEduReqObj,
      });
      this.saveData(redirection);
    } else {
      //Show Error Message
      const fieldsWithError = Object.keys(validators);
      const errorMessages = [];
      fieldsWithError.forEach((field) => {
        if (!validators[field].includes("* Required")) {
          if (validators[field].includes("* ")) {
            validators[field] = validators[field].replace("* ", "");
          }
          errorMessages.push(validators[field]);
        }
      });
      await this.setState({
        btnType: "next",
        canBeSavedPartially,
        errorMessages,
      });
      if (!this.state.canBeSavedPartially) {
        window.scrollTo(0, 0);
      }
    }
  };

  normalizeSpecialtiesAndEducationJson = async () => {
    const workExpReqObj = [];
    this.state.workExperiences.forEach((workExp) => {
      const workExpObj = enableWorkHistory ? { meta: workExp?.meta } : {};
      this.state.workExpFields.forEach((value) => {
        if (workExp[value] && workExp[value].length > 0) {
          if (value === "startDate" || value === "endDate") {
            let date = workExp[value];
            date = date ? moment.utc(date).format("MM/DD/YYYY") : null;
            workExpObj[value] = date;
          } else {
            workExpObj[value] = workExp[value];
          }
        }
      });
      workExpReqObj.push(workExpObj);
    });
    const existingSpecialtyAndEducation = this.props.onboardingSpecialtiesAndEducation;
    if (this.state.malpractices.value) {
      existingSpecialtyAndEducation.isMalpracticeClaim = this.state.malpractices.value;
    }
    if (enableIsLegalQuestion && this.state.isLegalQuestion.value) {
      existingSpecialtyAndEducation.isLegal = this.state.isLegalQuestion.value;
    }
    const pageValidationObj = {
      malpractices: this.state.malpractices.value,
      ...(enableIsLegalQuestion && { isLegalQuestion: this.state.isLegalQuestion.value }),
      workExperience: workExpReqObj,
    };
    const { validators, canBeSavedPartially } = await this.validatePage(pageValidationObj);
    let specialtyAndEduReqObj;
    let workExperienceReqObj;
    if (canBeSavedPartially) {
      specialtyAndEduReqObj = {
        event: SPECIALTIES_AND_EDUCATION,
        candidateId: this.props.candidateId,
        email: this.props.email,
        companyId: parseInt(this.props.company.id),
        sqsEvent: true,
        profile: {
          meta: {
            lastVisitedPage: "2",
            completedSections: {
              basicInfo: !!this.props.onBoardingCompletedSteps[0],
              specialtiesAndEducation: !!this.props.onBoardingCompletedSteps[1],
              workExperience: !validators,
              skillsChecklists: !!(
                (enableReferences && this.props.onBoardingCompletedSteps[4]) ||
                (!enableReferences && this.props.onBoardingCompletedSteps[3])
              ),
              references: !!(enableReferences && this.props.onBoardingCompletedSteps[3]),
            },
          },
          specialtiesAndEducation: existingSpecialtyAndEducation,
        },
      };

      workExperienceReqObj = {
        event: WORK_EXPERIENCE,
        candidateId: this.props.candidateId,
        email: this.props.email,
        companyId: parseInt(this.props.company.id),
        sqsEvent: true,
        profile: {
          meta: {
            lastVisitedPage: "2",
            completedSections: {
              workExperience: !validators,
            },
          },
          workExperience: workExpReqObj,
        },
      };
    }
    return {
      workExperienceReqObj,
      canBeSavedPartially,
      specialtyAndEduReqObj,
      validators,
    };
  };

  validatePage = async (specialtiesAndEducationReqObj) => {
    const validators = validationsService.validateSetOfFields(
      specialtiesAndEducationReqObj,
      workExpConstraints.workExpPageConstraints
    );
    let canBeSavedPartially;
    if (
      validators &&
      validators["workExperience"] &&
      !validators["workExperience"].includes("* Required")
    ) {
      canBeSavedPartially = false;
      validators["workExperience"] = this.props.t("onBoarding.errorMessage.workExpErrorMsg");
    } else if (
      validators &&
      validators["malpractices"] &&
      !validators["malpractices"].includes("* Required")
    ) {
      canBeSavedPartially = false;
    } else if (
      validators &&
      enableIsLegalQuestion &&
      validators["isLegalQuestion"] &&
      !validators["isLegalQuestion"].includes("* Required")
    ) {
      canBeSavedPartially = false;
    } else canBeSavedPartially = true;
    return { validators, canBeSavedPartially };
  };

  async saveData(redirection) {
    const workExpReqObj = this.state.workExpReqObj;
    const references = this.props.onBoardingReferences;
    const pageValidationObj = {
      malpractices: this.state.malpractices.value,
      ...(enableIsLegalQuestion && { isLegalQuestion: this.state.isLegalQuestion.value }),
      workExperience: this.props.onboardingWorkExperience,
    };
    const { validators } = await this.validatePage(pageValidationObj);
    if (enableWorkHistory) {
      this.props
        .saveUserProfile(
          this.state.specialtyAndEduReqObj,
          references,
          this.props.onboardingWorkExperience,
          this.props.onboardingBasicInfo
        )
        .then(
          (res) => {
            if (redirection === "Back") {
              this.props.updateActiveFlag(1);
              this.props.history.push(EDU_SPEC_PAGE);
            } else {
              const dimensions = {
                dimension9:
                  this.props.candidateSpecialties && this.props.candidateSpecialties.length > 0
                    ? this.props.candidateSpecialties.toString()
                    : null,
              };
              const event = {
                category: this.props.t("googleAnalytics.category.onboarding"),
                action: this.props.t("googleAnalytics.action.submitWorkExp"),
                label: this.props.t("googleAnalytics.label.workExp"),
              };
              Analytics.sendPageview(this.props.history.location, dimensions);
              Analytics.sendEvent(event);
              let updateWorkExperienceStatusInput = {
                "email": this.props.email,
                "isDeleted": workExpIsDeleted,
                "workExperienceStatus": !validators,
              };
              let isWorkExpComplete = false;
              apolloClient
                .mutate({
                  mutation: UPDATE_WORK_EXP_STATUS,
                  variables: { updateWorkExperienceStatusInput: updateWorkExperienceStatusInput },
                })
                .then((res) => {
                  const onBoardingCompletedStepsFinal = this.props.onBoardingCompletedSteps;
                  if (!validators) {
                    onBoardingCompletedStepsFinal[2] = true;
                    isWorkExpComplete = true;
                  }
                  this.props.updateCompleteFlag(onBoardingCompletedStepsFinal);
                })
                .catch((err) => console.error(err));

              if (redirection === "Next") {
                this.props.updateActiveFlag(3);
                this.props.history.push(REFERENCES_PAGE);
              } else if (redirection === "Done") {
                if (isWorkExpComplete) {
                  const event = {
                    category: this.props.t("googleAnalytics.category.onboarding"),
                    action: this.props.t("googleAnalytics.action.completeApplication"),
                    label: this.props.t("googleAnalytics.label.application"),
                  };
                  const dimensions = {
                    dimension9:
                      this.props.candidateSpecialties && this.props.candidateSpecialties.length > 0
                        ? this.props.candidateSpecialties.toString()
                        : null,
                  };
                  Analytics.sendPageview(this.props.history.location, dimensions);
                  Analytics.sendEvent(event);
                }
                this.props.history.push(DASHBOARD);
              }
            }
            const profileProgress = this.profileProgressInPercentage();
            const segmentEventName = this.props.t("segment.profileUpdated");
            const lineItem = [];
            if (
              workExpReqObj &&
              workExpReqObj.profile &&
              workExpReqObj.profile.workExperience &&
              workExpReqObj.profile.workExperience.length > 0
            ) {
              workExpReqObj.profile.workExperience.forEach((workExp) => {
                lineItem.push(workExp.facilityName);
              });
            }
            const segmentEventProps = {
              section: this.props.t("myProfileSubNav.workExperience"),
              progress: profileProgress,
              origin: document.referrer,
              lineItem,
            };
            Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
          },
          (err) => {}
        );
      this.setState({ ...this.state, hasUnsavedChanges: false });
    } else {
      this.props
        .saveUserProfile(
          this.state.specialtyAndEduReqObj,
          references,
          this.props.onboardingWorkExperience,
          this.props.onboardingBasicInfo
        )
        .then(
          (res) => {
            this.props
              .saveUserProfile(
                workExpReqObj,
                references,
                this.props.onboardingWorkExperience,
                this.props.onboardingBasicInfo
              )
              .then(
                (res) => {
                  if (redirection === "Back") {
                    this.props.updateActiveFlag(1);
                    this.props.history.push(EDU_SPEC_PAGE);
                  } else {
                    const dimensions = {
                      dimension9:
                        this.props.candidateSpecialties &&
                        this.props.candidateSpecialties.length > 0
                          ? this.props.candidateSpecialties.toString()
                          : null,
                    };
                    const event = {
                      category: this.props.t("googleAnalytics.category.onboarding"),
                      action: this.props.t("googleAnalytics.action.submitWorkExp"),
                      label: this.props.t("googleAnalytics.label.workExp"),
                    };
                    Analytics.sendPageview(this.props.history.location, dimensions);
                    Analytics.sendEvent(event);
                    const onBoardingCompletedStepsFinal = this.props.onBoardingCompletedSteps;
                    let isWorkExpComplete = false;
                    if (
                      this.state.malpractices.value &&
                      this.state.workExperiences.length > 0
                    ) {
                      onBoardingCompletedStepsFinal[2] = true;
                      isWorkExpComplete = true;
                    }
                    if (enableIsLegalQuestion && this.state.isLegalQuestion.value) {
                      onBoardingCompletedStepsFinal[2] = true;
                      isWorkExpComplete = true;
                    }
                    this.props.updateCompleteFlag(onBoardingCompletedStepsFinal);
                    if (redirection === "Next") {
                      this.props.updateActiveFlag(3);
                      this.props.history.push(REFERENCES_PAGE);
                    } else if (redirection === "Done") {
                      if (isWorkExpComplete) {
                        const event = {
                          category: this.props.t("googleAnalytics.category.onboarding"),
                          action: this.props.t("googleAnalytics.action.completeApplication"),
                          label: this.props.t("googleAnalytics.label.application"),
                        };
                        const dimensions = {
                          dimension9:
                            this.props.candidateSpecialties &&
                            this.props.candidateSpecialties.length > 0
                              ? this.props.candidateSpecialties.toString()
                              : null,
                        };
                        Analytics.sendPageview(this.props.history.location, dimensions);
                        Analytics.sendEvent(event);
                      }
                      this.props.history.push(DASHBOARD);
                      // Added the condition for null check and work experience completion steps
                      if (
                        this.state.malpractices.value &&
                        this.state.workExperiences.length > 0
                      ) {
                        onBoardingCompletedStepsFinal[2] = true;
                        isWorkExpComplete = true;
                      }
                      if (enableIsLegalQuestion && this.state.isLegalQuestion.value) {
                        onBoardingCompletedStepsFinal[2] = true;
                        isWorkExpComplete = true;
                      }
                    }
                  }
                  const profileProgress = this.profileProgressInPercentage();
                  const segmentEventName = this.props.t("segment.profileUpdated");
                  const lineItem = [];
                  if (
                    workExpReqObj &&
                    workExpReqObj.profile &&
                    workExpReqObj.profile.workExperience &&
                    workExpReqObj.profile.workExperience.length > 0
                  ) {
                    workExpReqObj.profile.workExperience.forEach((workExp) => {
                      lineItem.push(workExp.facilityName);
                    });
                  }
                  const segmentEventProps = {
                    section: this.props.t("myProfileSubNav.workExperience"),
                    progress: profileProgress,
                    origin: document.referrer,
                    lineItem,
                  };
                  Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
                },
                (err) => {}
              );
            this.setState({ ...this.state, hasUnsavedChanges: false });
          },
          (err) => {}
        );
    }
  }

  saveWorkExp = async () => {
    document.getElementById("onBoardingTableId").scrollIntoView({ block: "center" });
    this.setState({ onBGrayButton: !this.state.onBGrayButton });
    let record = enableWorkHistory
      ? {
          meta: {
            id: uuid(),
            createdDate: moment.utc().format(),
            createdBy: workHistoryAutomationXperienceId,
            associations: [],
          },
        }
      : {};
    this.state.workExpFields.forEach((field) => {
      record[field] =
        this.state[field].value && this.state[field].value.trim() !== ""
          ? this.state[field].value
          : field === "isVerified"
          ? "true"
          : null;
    });
    await this.setState((preState) => ({
      workExperiences: [...preState.workExperiences, record],
    }));
    await this.setState({
      facilityName: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      city: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      profession: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      specialty: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      supervisor: {
        value: "",
        errors: false,
        errorText: "",
      },
      supervisorPhone: {
        value: "",
        errors: false,
        errorText: "",
      },
      position: { value: "" },
      email: { value: "", errors: false },
      shiftWorked: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      emrTechnology: { value: "" },
      floatingPlace: { value: null },
      isCurrentlyWorking: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      startDate: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      endDate: { value: null, errors: false, errorText: "" },
      isTempJob: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      staffingAgency: { value: null, errors: false, errorText: "" },
      reasonForLeaving: { value: "", errors: false, errorText: "" },
      facilityType: { value: "", errors: false, errorText: null },
      patientRatio: { value: "", errors: false, errorText: "" },
      teachingFacility: { value: null, errors: false, errorText: "" },
      chargeExperience: { value: null, errors: false, errorText: "" },
      addWorkHistoryBtnClicked: true,
      disableCancelButton: true,
      disableActions: false,
      hideWorkExpForm: true,
      isRecordToEdit: false,
      isVerified: false,
      hasUnsavedChanges: true,
    });
    this.setState({
      autocompleteKey: new Date().getTime(),
      addWorkHistoryDisableBtn: await this.checkSaveBtnStatus(),
      openModal: true,
    });
    if (enableWorkHistory) {
      await this.saveWorkExpInProfile(record);
    }
  };

  saveWorkExpInProfile = async (record) => {
    record["chargeExperience"] =
      record["chargeExperience"] === "true"
        ? true
        : record["chargeExperience"] === "false"
        ? false
        : null;
    record["isCurrentlyWorking"] = record["isCurrentlyWorking"] === "true" ? true : false;
    record["isTempJob"] = record["isTempJob"] === "true" ? true : false;
    record["isVerified"] = record["isVerified"] === "true" ? true : false;
    record["teachingFacility"] =
      record["teachingFacility"] === "true"
        ? true
        : record["teachingFacility"] === "false"
        ? false
        : null;
    record["meta"]["associations"] = [
      {
        "tenantId": "",
        "tenantEntityId": "",
        "tenantLastIngressSyncDate": "",
        "tenantLastEgressSyncDate": "",
        "isDeleted": false,
      },
    ];
    record["atsBullhornCandidateId"] = this.props.atsBullhornCandidateId;
    delete record.supervisorPhone;
    delete record.email;
    const pageValidationObj = {
      malpractices: this.state.malpractices.value,
      ...(enableIsLegalQuestion && { isLegalQuestion: this.state.isLegalQuestion.value }),
      workExperience: [...this.props.onboardingWorkExperience, record],
    };
    const { validators } = await this.validatePage(pageValidationObj);
    let workExperienceInput = {};
    workExperienceInput = {
      "email": this.props.email,
      "companyId": parseInt(this.props.company.id),
      "event": "addWorkExperience",
      "isDeleted": workExpIsDeleted,
      "candidateWorkExperience": {
        "meta": {
          "lastVisitedPage": "2",
          "completedSections": {
            workExperience: !validators,
          },
        },
        "workExperience": [record],
      },
    };
    apolloClient
      .mutate({
        mutation: SET_WORK_EXPERIENCE,
        variables: { workExperienceInput: workExperienceInput },
      })
      .then((res) => {
        let onbCompSec = this.props.onBoardingCompletedSteps;
        if (!validators) {
          onbCompSec[2] = true;
          this.props.updateOnboardingCompletedSections(onbCompSec);
        }
        let workExp = res.data.setWorkExperience.content.candidateWorkExperience.workExperience;
        this.props.updateWorkExperience(workExp);
      })
      .catch((err) => console.error(err));

      await this.setState({
        isRecordAdded: true
      })
  };

  updateRecord = async (index, type) => {
    document.getElementById("onBoardingTableId").scrollIntoView({ block: "center" });
    let record = {};
    if (enableWorkHistory) {
      record = { meta: this?.props?.onboardingWorkExperience[index]?.meta };
      record.meta["updatedBy"] = workHistoryAutomationXperienceId;
      record.meta["updatedDate"] = moment.utc().format();
      record.meta["__typename"] = undefined;
      record.meta.associations[0]["__typename"] = undefined;
      record["atsBullhornCandidateId"] = this.props.atsBullhornCandidateId;
    }
    this.state.workExpFields.forEach((field) => {
      record[field] =
        this.state[field].value && this.state[field].value.trim() !== ""
          ? this.state[field].value
          : field === "isVerified"
          ? "true"
          : null;
    });
    const updatedEdRecords = update(this.state.workExperiences, {
      $splice: [[index, 1, record]],
    });
    updatedEdRecords[index]["chargeExperience"] =
      updatedEdRecords[index]["chargeExperience"] === "true"
        ? true
        : updatedEdRecords[index]["chargeExperience"] === "false"
        ? false
        : null;
    updatedEdRecords[index]["isCurrentlyWorking"] =
      updatedEdRecords[index]["isCurrentlyWorking"] === "true" ? true : false;
    updatedEdRecords[index]["isTempJob"] =
      updatedEdRecords[index]["isTempJob"] === "true" ? true : false;
    updatedEdRecords[index]["isVerified"] =
      updatedEdRecords[index]["isVerified"] === "true" ? true : false;
    updatedEdRecords[index]["teachingFacility"] =
      updatedEdRecords[index]["teachingFacility"] === "true"
        ? true
        : updatedEdRecords[index]["teachingFacility"] === "false"
        ? false
        : null;
    delete updatedEdRecords[index].supervisorPhone;
    delete updatedEdRecords[index].email;
    if (enableWorkHistory) {
      const pageValidationObj = {
        malpractices: this.state.malpractices.value,
        ...(enableIsLegalQuestion && { isLegalQuestion: this.state.isLegalQuestion.value }),
        workExperience: updatedEdRecords,
      };
      const { validators } = await this.validatePage(pageValidationObj);
      let workExperienceInput = {};
      workExperienceInput = {
        "email": this.props.email,
        "companyId": parseInt(this.props.company.id),
        "event": "updateWorkExperience",
        "isDeleted": workExpIsDeleted,
        "candidateWorkExperience": {
          "meta": {
            "lastVisitedPage": "2",
            "completedSections": {
              workExperience: !validators,
            },
          },
          "workExperience": [updatedEdRecords[index]],
        },
      };
      apolloClient
        .mutate({
          mutation: SET_WORK_EXPERIENCE,
          variables: { workExperienceInput: workExperienceInput },
        })
        .then((res) => {
          let onbCompSec = this.props.onBoardingCompletedSteps;
          if (!validators &&
            this.props.onboardingSpecialtiesAndEducation.isMalpracticeClaim !== null &&
            this.props.onboardingSpecialtiesAndEducation.isLegal !== null
          ) {
            onbCompSec[2] = true;
            this.props.updateOnboardingCompletedSections(onbCompSec);
          }
          let workExp = res.data.setWorkExperience.content.candidateWorkExperience.workExperience;
          this.props.updateWorkExperience(workExp);
        })
        .catch((err) => console.error(err));
    }
    await this.setState({
      workExperiences: updatedEdRecords,
      isRecordToEdit: false,
      isRecordUpdated: true,
      hasUnsavedChanges: true,
    });

    await this.setState({
      facilityName: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      city: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      profession: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      specialty: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      supervisor: {
        value: "",
        errors: false,
        errorText: "",
      },
      supervisorPhone: {
        value: "",
        errors: false,
        errorText: "",
      },
      position: { value: "" },
      email: { value: "", errors: false },
      shiftWorked: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      emrTechnology: { value: "", errors: false, errorText: null },
      floatingPlace: { value: null },
      isCurrentlyWorking: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      startDate: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      endDate: { value: null, errors: false, errorText: "" },
      reasonForLeaving: { value: "", errors: false, errorText: "" },
      isTempJob: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      staffingAgency: { value: "", errors: false, errorText: null },
      facilityType: { value: "", errors: false, errorText: null },
      patientRatio: { value: "", errors: false, errorText: null },
      teachingFacility: { value: null, errors: false, errorText: "" },
      chargeExperience: { value: null, errors: false, errorText: "" },
      addWorkHistoryBtnClicked: true,
      disableCancelButton: true,
      disableUpdateButton: true,
      disableActions: false,
      index: 0,
      autocompleteKey: new Date().getTime(),
      addWorkHistoryDisableBtn: true,
      hideWorkExpForm: true,
      openModal: true,
    });
  };

  cancelUpdate() {
    this.setState({
      openModal: this.state.hasUnsavedChanges,
      facilityName: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      city: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      profession: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      specialty: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      supervisor: {
        value: "",
        errors: false,
        errorText: "",
      },
      supervisorPhone: {
        value: "",
        errors: false,
        errorText: "",
      },
      position: { value: "" },
      email: { value: "", errors: false },
      shiftWorked: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      emrTechnology: { value: "" },
      floatingPlace: { value: null },
      isCurrentlyWorking: {
        value: workHistoryAutomation ? "true" : null,
        errors: !workHistoryAutomation,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      startDate: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      endDate: { value: null, errors: false, errorText: "" },
      reasonForLeaving: { value: "", errors: false, errorText: "" },
      isTempJob: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      staffingAgency: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      facilityType: { value: "", errors: false, errorText: null },
      patientRatio: { value: "", errors: false, errorText: "" },
      teachingFacility: { value: null, errors: false, errorText: "" },
      chargeExperience: { value: null, errors: false, errorText: "" },
      addWorkHistoryBtnClicked: true,
      disableActions: false,
      isRecordToEdit: false,
      isRecordUpdated: false,
      disableCancelButton: true,
      index: 0,
      autocompleteKey: new Date().getTime(),
      addWorkHistoryDisableBtn: true,
      hideWorkExpForm: true,
      isRecordAdded: false
    });
  }

  async editRecord(index, type) {
    if (!isNaN(index)) {
      const workFormValidations = validationsService.validateSetOfFields(
        this.state.workExperiences[index],
        workExpConstraints.workExpFormConstraints
      );
      await this.setState((prevState) => ({
        isRecordToEdit: true,
        isRecordUpdated: false,
        disableCancelButton: false,
        disableActions: true,
        disableUpdateButton: true,
        isOptionOpen: false,
        isUnitOfFloatToEdit: true,
        index,
        facilityName: {
          value: prevState.workExperiences[index].facilityName
            ? prevState.workExperiences[index].facilityName
            : null,
          errors: !!(workFormValidations && workFormValidations.facilityName),
          errorText:
            workFormValidations && workFormValidations.facilityName
              ? workFormValidations.facilityName[0]
              : "",
        },
        city: {
          value: prevState.workExperiences[index].city
            ? prevState.workExperiences[index].city
            : null,
          errors: !!(workFormValidations && workFormValidations.city),
          errorText:
            workFormValidations && workFormValidations.city ? workFormValidations.city[0] : "",
        },
        profession: {
          value: prevState.workExperiences[index].profession
            ? prevState.workExperiences[index].profession
            : null,
          errors: !!(workFormValidations && workFormValidations.profession),
          errorText:
            workFormValidations && workFormValidations.profession
              ? workFormValidations.profession[0]
              : "",
        },
        specialty: {
          value: prevState.workExperiences[index].specialty
            ? prevState.workExperiences[index].specialty
            : null,
          errors: !!(workFormValidations && workFormValidations.specialty),
          errorText:
            workFormValidations && workFormValidations.specialty
              ? workFormValidations.specialty[0]
              : "",
        },
        position: {
          value: prevState.workExperiences[index].position
            ? prevState.workExperiences[index].position
            : "",
          errors: !!(workFormValidations && workFormValidations.position),
          errorText:
            workFormValidations && workFormValidations.position
              ? workFormValidations.position[0]
              : "",
        },
        supervisor: {
          value: prevState.workExperiences[index].supervisor
            ? prevState.workExperiences[index].supervisor
            : null,
          errors: !!(workFormValidations && workFormValidations.supervisor),
          errorText:
            workFormValidations && workFormValidations.supervisor
              ? workFormValidations.supervisor[0]
              : "",
        },
        supervisorPhone: {
          value: prevState.workExperiences[index].supervisorPhone
            ? prevState.workExperiences[index].supervisorPhone
            : "",
          errors: !!(workFormValidations && workFormValidations.supervisorPhone),
          errorText:
            workFormValidations && workFormValidations.supervisorPhone
              ? workFormValidations.supervisorPhone[0]
              : "",
        },
        email: {
          value: prevState.workExperiences[index].email
            ? prevState.workExperiences[index].email
            : "",
          errors: !!(workFormValidations && workFormValidations.email),
          errorText:
            workFormValidations && workFormValidations.email ? workFormValidations.email[0] : "",
        },
        shiftWorked: {
          value: prevState.workExperiences[index].shiftWorked
            ? prevState.workExperiences[index].shiftWorked
            : null,
          errors: !!(workFormValidations && workFormValidations.shiftWorked),
          errorText:
            workFormValidations && workFormValidations.shiftWorked
              ? workFormValidations.shiftWorked[0]
              : "",
        },
        emrTechnology: {
          value: prevState.workExperiences[index].emrTechnology
            ? prevState.workExperiences[index].emrTechnology
            : "",
          errors: !!(workFormValidations && workFormValidations.emrTechnology),
          errorText:
            workFormValidations && workFormValidations.emrTechnology
              ? workFormValidations.emrTechnology[0]
              : "",
        },
        floatingPlace: {
          value: prevState.workExperiences[index].floatingPlace
            ? prevState.workExperiences[index].floatingPlace
            : null,
          errors: !!(workFormValidations && workFormValidations.floatingPlace),
          errorText:
            workFormValidations && workFormValidations.floatingPlace
              ? workFormValidations.floatingPlace[0]
              : "",
        },
        isCurrentlyWorking: {
          value: prevState.workExperiences[index].isCurrentlyWorking
            ? prevState.workExperiences[index].isCurrentlyWorking.toString()
            : null,
          errors: !!(workFormValidations && workFormValidations.isCurrentlyWorking),
          errorText:
            workFormValidations && workFormValidations.isCurrentlyWorking
              ? workFormValidations.isCurrentlyWorking[0]
              : "",
        },
        startDate: {
          value: prevState.workExperiences[index].startDate
            ? prevState.workExperiences[index].startDate
            : null,
          errors: !!(workFormValidations && workFormValidations.startDate),
          errorText:
            workFormValidations && workFormValidations.startDate
              ? workFormValidations.startDate[0]
              : "",
        },
        endDate: {
          value: prevState.workExperiences[index].endDate
            ? prevState.workExperiences[index].endDate
            : null,
          errors: !!(workFormValidations && workFormValidations.endDate),
          errorText:
            workFormValidations && workFormValidations.endDate
              ? workFormValidations.endDate[0]
              : "",
        },
        reasonForLeaving: {
          value: prevState.workExperiences[index].reasonForLeaving
            ? prevState.workExperiences[index].reasonForLeaving
            : workHistoryAutomation
            ? defaultReasonForLeaving
            : null,
          errors: !!(
            workFormValidations &&
            workFormValidations.reasonForLeaving &&
            !workHistoryAutomation
          ),
          errorText:
            workFormValidations && workFormValidations.reasonForLeaving
              ? workFormValidations.reasonForLeaving[0]
              : "",
        },
        isTempJob: {
          value: prevState.workExperiences[index].isTempJob
            ? prevState.workExperiences[index].staffingAgency &&
              prevState.workExperiences[index].isTempJob === "false"
              ? "true"
              : workHistoryAutomation
              ? "true"
              : prevState.workExperiences[index].isTempJob
            : workHistoryAutomation
            ? "true"
            : null,
          errors: !!(workFormValidations && workFormValidations.isTempJob),
          errorText:
            workFormValidations && workFormValidations.isTempJob
              ? workFormValidations.isTempJob[0]
              : "",
        },
        staffingAgency: {
          value: prevState.workExperiences[index].staffingAgency
            ? prevState.workExperiences[index].staffingAgency
            : workHistoryAutomation
            ? this.props.t("onBoarding.workExp.defaultStaffingAgency")
            : "",
          errors: !!(workFormValidations && workFormValidations.staffingAgency),
          errorText:
            workFormValidations && workFormValidations.staffingAgency
              ? workFormValidations.staffingAgency[0]
              : "",
        },
        facilityType: {
          value: prevState.workExperiences[index].facilityType
            ? prevState.workExperiences[index].facilityType
            : "",
          errors: !!(workFormValidations && workFormValidations.facilityType),
          errorText:
            workFormValidations && workFormValidations.facilityType
              ? workFormValidations.facilityType[0]
              : "",
        },
        patientRatio: {
          value: prevState.workExperiences[index].patientRatio
            ? prevState.workExperiences[index].patientRatio
            : "",
          errors: !!(workFormValidations && workFormValidations.patientRatio),
          errorText:
            workFormValidations && workFormValidations.patientRatio
              ? workFormValidations.patientRatio[0]
              : "",
        },
        teachingFacility: {
          value: prevState.workExperiences[index].teachingFacility
            ? prevState.workExperiences[index].teachingFacility.toString()
            : null,
          errors: !!(workFormValidations && workFormValidations.teachingFacility),
          errorText:
            workFormValidations && workFormValidations.teachingFacility
              ? workFormValidations.teachingFacility[0]
              : "",
        },
        chargeExperience: {
          value: prevState.workExperiences[index].chargeExperience
            ? prevState.workExperiences[index].chargeExperience.toString()
            : null,
          errors: !!(workFormValidations && workFormValidations.chargeExperience),
          errorText:
            workFormValidations && workFormValidations.chargeExperience
              ? workFormValidations.chargeExperience[0]
              : "",
        },
        hideWorkExpForm: false,
      }));
    }
  }

  async addWorkExperience() {
    await this.setState({ hideWorkExpForm: false, disableCancelButton: false });
  }

  checkSaveBtnStatus = async () => {
    const workRecord = {};
    await this.state.workExpFields.forEach((field) => {
      workRecord[field] =
        this.state[field].value && this.state[field].value.trim() !== ""
          ? this.state[field].value
          : field === "isVerified"
          ? "true"
          : null;
    });
    const validators = await validationsService.validateSetOfFields(
      workRecord,
      workExpConstraints.workExpFormConstraints
    );
    if (validators) {
      await this.setState({ disableUpdateButton: true });
      return true;
    } else {
      await this.setState({ disableUpdateButton: false });
      return false;
    }
  };

  closePopover(e) {
    this.setState({
      canBeSavedPartially: true,
      btnType: "",
    });
  }

  handleLeaveBtnClick() {
    this.props.updateActiveFlag(1);
    this.props.history.push(EDU_SPEC_PAGE);
  }

  handleIsVerifyClicked() {
    const isVerifyNowClicked = !!this.props?.history?.location?.state?.isVerifyNowClicked;
    if (isVerifyNowClicked) {
      const filteredWorkExp = this.state?.workExperiences?.filter(
        (val) => val?.isVerified == "false"
      );
      const index = this.state?.workExperiences?.indexOf(filteredWorkExp[0]);
      if (index >= 0) this.editRecord(index);
    }
  }

  displayVerifyNow(item) {
    if (!!item) {
      const { workAutomationNotificationDate, isVerified, endDate } = item;

      if (!isVerified && !!workAutomationNotificationDate && workHistoryAutomation) {
        return (
          moment(workAutomationNotificationDate).isValid() &&
          moment(workAutomationNotificationDate).isSameOrBefore(moment().toDate())
        );
      } else if (!isVerified && !endDate && workHistoryAutomation) return true;
    }
  }

  itemOrDash(item) {
    let itemValue;

    if (item) {
      itemValue = item;
      return itemValue;
    }

    itemValue = "-";
    return itemValue;
  }

  dateOrDash(item) {
    let date;

    if (item) {
      date = moment.utc(item).format("MM/DD/YYYY");
      return date;
    }

    date = "-";
    return date;
  }

  render() {
    const isUpdateNowClicked = this.props?.history?.location?.state?.isUpdateNowClicked;
    const source = this.props?.history?.location?.state?.source;
    const image = this.props?.history?.location?.state?.image;
    return (
      <div>
        {isUpdateNowClicked && (
          <div className="returnToSourceWrapper">
            <SnackbarReturnToSource
              topRight
              message={this.props.t("QuickApplyCard.QuickApplyBackToJobDetail")}
              source={source}
              image={image}
            />
          </div>
        )}
        {this.state.openModal ? (
          <OnBoardingConfirmModal enabled={this.state.openModal} source={source} />
        ) : (
          <></>
        )}
        {/*  My Profile Navigation */}
        <Container fluid className="myProfileWrap">
          <Row>
            <MyProfileNavWrapper />
            <div className="myProfilePageWrap">
              {!this.state.canBeSavedPartially && this.state.btnType === "next" ? (
                <>
                  <Container>
                    <Row>
                      <Col sm={12} xs={12} md={12} lg={12} className="onBServErrWrap">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="#fff"
                          width="20px"
                          height="20px"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                        </svg>
                        <span className="onBServErrTxt">
                          &nbsp;
                          {this.props.t("onBoarding.basicInfo.validationErrorText")}
                        </span>
                        <div className="onBServErrSubTxt">
                          {this.props.t("onBoarding.basicInfo.validationErrorText1")}
                        </div>
                        <ul className="onBServErrList">
                          {this.state.errorMessages.map((message) => (
                            <li>{message}</li>
                          ))}
                        </ul>
                      </Col>
                    </Row>
                  </Container>
                </>
              ) : null}
              <Container className="onBFormBGMobile">
                <Row>
                  <Col sm={12} xs={12} md={12} lg={12} className="onBWorkExpBanner" />
                </Row>

                <div className="onBMainTxtField ">
                  <Row>
                    <Col sm={12} xs={12} md={12} lg={12} className="mt-2">
                      {/* Row 1 */}
                      <span className="onBWarningText">
                        {this.props.t("onBoarding.workExp.instructionTxt1")}{" "}
                      </span>
                      <span className="onBWorkExpText mb-2">
                        {this.props.t("onBoarding.workExp.instructionTxt2")}
                      </span>
                      <div> {this.renderAddMoreExperienceBtn(this, this._onRowSelection)}</div>
                      {this.state.workExperiences.length < 1 ? (
                        <Row className="onBaddOneRecord">
                          {this.props.t("onBoarding.workExp.emptyCellLabel")}
                        </Row>
                      ) : (
                        ""
                      )}
                      {/* Row 2 start */}
                      {!this.state.hideWorkExpForm ? (
                        <Container className="onBboxShadow mt-2">
                          <div>
                            <Row className="onBoardingTable mb-4">
                              <Col
                                sm={12}
                                xs={12}
                                md={4}
                                lg={4}
                                className={
                                  this.state.facilityName.errors
                                    ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                                    : "onBtextfiled txtFldMBMob"
                                }
                              >
                                <TextField
                                  label={this.props.t("onBoarding.workExp.facilityNameLbl")}
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  type="TextField"
                                  value={this.state.facilityName.value}
                                  onChange={this.handleChange.bind(this, "facilityName")}
                                  error={this.state.facilityName.errors}
                                  helperText={
                                    this.state.facilityName.errors
                                      ? this.state.facilityName.errorText
                                      : ""
                                  }
                                  autoComplete="new-facilityName"
                                />
                              </Col>
                              <Col
                                sm={12}
                                xs={12}
                                md={4}
                                lg={4}
                                className={
                                  this.state.city.errors
                                    ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                                    : "onBtextfiled txtFldMBMob"
                                }
                              >
                                <TextField
                                  label={this.props.t("onBoarding.workExp.cityStateLbl")}
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  type="TextField"
                                  value={this.state.city.value}
                                  onChange={this.handleChange.bind(this, "city")}
                                  error={this.state.city.errors}
                                  helperText={
                                    this.state.city.errors ? this.state.city.errorText : ""
                                  }
                                  autoComplete="new-city"
                                />
                              </Col>
                              <Col
                                sm={12}
                                xs={12}
                                md={4}
                                lg={4}
                                className={
                                  this.state.profession.errors
                                    ? "onBTextFieldReq onBtextfiled Mui-error"
                                    : "onBtextfiled"
                                }
                              >
                                <div className="onBAutoContInfo">
                                  {" "}
                                  {this.renderAutocomplete("profession")}
                                </div>
                              </Col>
                            </Row>
                            {/* Row 2 Ends */}

                            {/* Row 3 start */}
                            <Row className={isMobile ? "mb-3" : "mb-4"}>
                              <Col
                                sm={12}
                                xs={12}
                                md={4}
                                lg={4}
                                className={
                                  this.state.specialty.errors
                                    ? "onBTextFieldReq onBtextfiled txtFldMBMob Mui-error"
                                    : "onBtextfiled txtFldMBMob"
                                }
                              >
                                <div className="onBAutoContInfo">
                                  {this.renderAutocomplete("specialty")}
                                </div>
                              </Col>

                              <Col
                                sm={12}
                                xs={12}
                                md={4}
                                lg={4}
                                className={
                                  this.state.position.errors
                                    ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                                    : "onBtextfiled txtFldMBMob"
                                }
                              >
                                <div>
                                  <MultipleSelect
                                    fieldValue={this.state.position}
                                    fieldLabel={this.props.t(
                                      "onBoarding.workExp.typeOfPositionLbl"
                                    )}
                                    fieldName={"position"}
                                    fieldOptions={
                                      this.state.typeOfPositionValues
                                        ? this.state.typeOfPositionValues.sort((a, b) =>
                                            a.toLowerCase() > b.toLowerCase()
                                              ? 1
                                              : b.toLowerCase() > a.toLowerCase()
                                              ? -1
                                              : 0
                                          )
                                        : null
                                    }
                                    handleMultipleSelect={this.handleMultipleSelect}
                                    isMultiple={false}
                                    error={
                                      this.state.position.value === ""
                                        ? false
                                        : !!this.state.position.errors
                                    }
                                    helperText={
                                      this.state.position.errors
                                        ? this.state.position.errorText
                                        : null
                                    }
                                  />
                                </div>
                              </Col>
                              <Col
                                sm={12}
                                xs={12}
                                md={4}
                                lg={4}
                                className={
                                  this.state.shiftWorked.errors
                                    ? "onBTextFieldReq onBtextfiled"
                                    : "onBtextfiled "
                                }
                              >
                                <TextField
                                  label={this.props.t("onBoarding.workExp.shiftWorkedLbl")}
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  type="Shift Worked"
                                  value={this.state.shiftWorked.value}
                                  onChange={this.handleChange.bind(this, "shiftWorked")}
                                  error={!!this.state.shiftWorked.errors}
                                  helperText={
                                    this.state.shiftWorked.errors
                                      ? this.state.shiftWorked.errorText
                                      : ""
                                  }
                                  autoComplete="new-shiftWork"
                                />
                              </Col>
                            </Row>
                            {/* Row 3 ends */}

                            {/* Row 4 starts */}
                            <Row className={isMobile ? "mb-3" : "mb-4"}>
                              <Col
                                sm={12}
                                xs={12}
                                md={4}
                                lg={4}
                                className={
                                  this.state.facilityType.errors
                                    ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                                    : "onBtextfiled txtFldMBMob"
                                }
                              >
                                <div>
                                  <MultipleSelect
                                    fieldValue={this.state.facilityType}
                                    fieldLabel={this.props.t("onBoarding.workExp.facilityType")}
                                    fieldName={"facilityType"}
                                    fieldOptions={
                                      facilityType.types
                                        ? facilityType.types.sort((a, b) =>
                                            a.toLowerCase() > b.toLowerCase()
                                              ? 1
                                              : b.toLowerCase() > a.toLowerCase()
                                              ? -1
                                              : 0
                                          )
                                        : null
                                    }
                                    handleMultipleSelect={this.handleMultipleSelect}
                                    isMultiple={false}
                                    error={
                                      this.state.facilityType.value === ""
                                        ? false
                                        : !!this.state.facilityType.errors
                                    }
                                    helperText={
                                      this.state.facilityType.errors
                                        ? this.state.facilityType.errorText
                                        : ""
                                    }
                                  />
                                </div>
                              </Col>
                              <Col
                                sm={12}
                                xs={12}
                                md={4}
                                lg={4}
                                className={
                                  this.state.emrTechnology.errors
                                    ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                                    : "onBtextfiled txtFldMBMob"
                                }
                              >
                                <div>
                                  <MultipleSelect
                                    fieldValue={this.state.emrTechnology}
                                    fieldLabel={this.props.t("onBoarding.workExp.addEmrTechLbl")}
                                    fieldName={"emrTechnology"}
                                    fieldOptions={
                                      emrExpList.emrExp
                                        ? emrExpList.emrExp.sort((a, b) =>
                                            a.toLowerCase() > b.toLowerCase()
                                              ? 1
                                              : b.toLowerCase() > a.toLowerCase()
                                              ? -1
                                              : 0
                                          )
                                        : null
                                    }
                                    handleMultipleSelect={this.handleMultipleSelect}
                                    isMultiple={false}
                                    error={
                                      this.state.emrTechnology.value === ""
                                        ? false
                                        : !!this.state.emrTechnology.errors
                                    }
                                    helperText={
                                      this.state.emrTechnology.errors
                                        ? this.state.emrTechnology.errorText
                                        : ""
                                    }
                                  />
                                </div>
                              </Col>
                              <Col
                                sm={12}
                                xs={12}
                                md={4}
                                lg={4}
                                className={
                                  this.state.patientRatio.errors
                                    ? "onBTextFieldReq onBtextfiled"
                                    : "onBtextfiled "
                                }
                              >
                                <TextField
                                  label={this.props.t("onBoarding.workExp.patientRatio/CaseLoad")}
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={this.handleChange.bind(this, "patientRatio")}
                                  value={this.state.patientRatio.value}
                                  error={
                                    this.state.patientRatio.value === ""
                                      ? false
                                      : !!this.state.patientRatio.errors
                                  }
                                  helperText={
                                    this.state.patientRatio.errors
                                      ? this.state.patientRatio.errorText
                                      : ""
                                  }
                                  autoComplete="new-patientRatio"
                                />
                              </Col>
                            </Row>
                            {/* Row 4 ends */}
                            {/* Row 5 starts */}
                            <Row className="mt-2 mb-4">
                              <Col
                                sm={12}
                                xs={12}
                                md={4}
                                lg={4}
                                className={
                                  this.state.floatingPlace.errors
                                    ? "onBTextFieldReq onBtextfiled Mui-error"
                                    : "onBtextfiled "
                                }
                              >
                                <div> {this.renderAutocomplete("floatingPlace")}</div>
                              </Col>
                            </Row>
                            {/* Row 5 ends */}

                            {/* Row 6 starts */}
                            <Row className="onBPermanentAddMain onBWorkExpMt">
                              <Col sm={12} xs={12} md={4} lg={4} className="pl-0">
                                <div className="onBWorkExpRadio">
                                  <label className="onBchkBoxLabel">
                                    {this.props.t("onBoarding.workExp.teachingFacility")}
                                  </label>
                                  <RadioGroup
                                    row
                                    value={this.value}
                                    onChange={this.radioBtnHandleChange.bind(
                                      this,
                                      "teachingFacility"
                                    )}
                                  >
                                    <FormControlLabel
                                      value="false"
                                      className="onBAddRadioBtn"
                                      control={
                                        <Radio
                                          name="checkedno"
                                          checked={this.state.teachingFacility.value === "false"}
                                        />
                                      }
                                      label={this.props.t("onBoarding.basicInfo.travellingOption1")}
                                    />
                                    <FormControlLabel
                                      value="true"
                                      className="onBAddRadioBtn"
                                      control={
                                        <Radio
                                          name="checkedyes"
                                          checked={this.state.teachingFacility.value === "true"}
                                        />
                                      }
                                      label={this.props.t("onBoarding.basicInfo.travellingOption2")}
                                    />
                                  </RadioGroup>
                                </div>
                              </Col>
                            </Row>

                            {/* Row 6 ends */}

                            {/* Row 7 starts */}
                            <Row className="onBPermanentAddMain">
                              <Col sm={12} xs={12} md={5} lg={5} className="pl-0">
                                <div className="onBWorkExpRadio">
                                  <label className="onBchkBoxLabel">
                                    {this.props.t("onBoarding.workExp.chargeExperience")}
                                  </label>
                                  <RadioGroup
                                    row
                                    value={this.value}
                                    onChange={this.radioBtnHandleChange.bind(
                                      this,
                                      "chargeExperience"
                                    )}
                                  >
                                    <FormControlLabel
                                      value="false"
                                      className="onBAddRadioBtn"
                                      control={
                                        <Radio
                                          name="checkedno"
                                          checked={this.state.chargeExperience.value === "false"}
                                        />
                                      }
                                      label={this.props.t("onBoarding.basicInfo.travellingOption1")}
                                    />
                                    <FormControlLabel
                                      value="true"
                                      className="onBAddRadioBtn"
                                      control={
                                        <Radio
                                          name="checkedyes"
                                          checked={this.state.chargeExperience.value === "true"}
                                        />
                                      }
                                      label={this.props.t("onBoarding.basicInfo.travellingOption2")}
                                    />
                                  </RadioGroup>
                                </div>
                              </Col>
                            </Row>
                            {/* Row 7 ends */}

                            {/* Row 8 starts */}
                            <Row className="onBPermanentAddMain">
                              <Col sm={12} xs={12} md={12} lg={12} className="pl-0 pr-0">
                                <div className="onBWorkExpRadio onBTextFieldReq">
                                  <label className="onBchkBoxLabel">
                                    {this.props.t("onBoarding.workExp.areYouCurrentlyWorking")}
                                  </label>
                                  <RadioGroup
                                    name="currently working"
                                    row
                                    value={this.value}
                                    onChange={this.radioBtnHandleChange.bind(
                                      this,
                                      "isCurrentlyWorking"
                                    )}
                                  >
                                    <FormControlLabel
                                      value="false"
                                      control={
                                        <Radio
                                          checked={this.state.isCurrentlyWorking.value === "false"}
                                        />
                                      }
                                      label={this.props.t("onBoarding.workExp.isWorkingQn1")}
                                    />
                                    <FormControlLabel
                                      value="true"
                                      control={
                                        <Radio
                                          checked={this.state.isCurrentlyWorking.value === "true"}
                                        />
                                      }
                                      label={this.props.t("onBoarding.workExp.isWorkingQn2")}
                                    />
                                    <FormHelperText className="onBWorkExpErrorChkBox">
                                      {this.state.isCurrentlyWorking.errors
                                        ? this.state.isCurrentlyWorking.errorText
                                        : null}
                                    </FormHelperText>
                                  </RadioGroup>
                                </div>
                                <Row>
                                  {this.state.isCurrentlyWorking.value ? (
                                    <Col
                                      sm={12}
                                      xs={12}
                                      md={4}
                                      lg={4}
                                      className={
                                        this.state.startDate.errors
                                          ? "onBTextFieldReq onBtextfiled"
                                          : "onBtextfiled "
                                      }
                                    >
                                      <div className="onBAutoContInfo ">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                          <Grid container justifyContent="space-around">
                                            <KeyboardDatePicker
                                              inputVariant="outlined"
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              className="datePick"
                                              views={["year", "month", "date"]}
                                              allowKeyboardControl={false}
                                              autoOk="false"
                                              variant="inline"
                                              format="MM/dd/yyyy"
                                              margin="normal"
                                              id="Start-Date-workExp"
                                              label={this.props.t(
                                                "onBoarding.workExp.startDateLbl"
                                              )}
                                              value={this.convertToDateFormat(
                                                this.state.startDate.value
                                              )}
                                              onChange={this.handleDateChange.bind(
                                                this,
                                                "startDate"
                                              )}
                                              maxDate={
                                                this.state.endDate.value
                                                  ? this.state.endDate.value
                                                  : new Date()
                                              }
                                              maxDateMessage={this.props.t(
                                                "onBoarding.workExp.startDateMessage"
                                              )}
                                              invalidDateMessage={this.props.t(
                                                "onBoarding.errorMessage.validDateError"
                                              )}
                                              {...(this.state.startDate.errors
                                                ? {
                                                    helperText: this.state.startDate.errorText,
                                                    error: !!this.state.startDate.errors,
                                                  }
                                                : {})}
                                            />
                                          </Grid>
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </Col>
                                  ) : null}
                                  {this.state.isCurrentlyWorking.value === "false" ? (
                                    <Col
                                      sm={12}
                                      xs={12}
                                      md={4}
                                      lg={4}
                                      className={
                                        this.state.endDate.errors
                                          ? "onBTextFieldReq onBtextfiled"
                                          : "onBtextfiled "
                                      }
                                    >
                                      <div className="onBAutoContInfo">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                          <Grid container justifyContent="space-around">
                                            <KeyboardDatePicker
                                              inputVariant="outlined"
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              className="datePick"
                                              views={["year", "month", "date"]}
                                              allowKeyboardControl={false}
                                              autoOk="false"
                                              variant="inline"
                                              format="MM/dd/yyyy"
                                              margin="normal"
                                              id="End-Date-workExp"
                                              label={this.props.t("onBoarding.workExp.endDateLbl")}
                                              value={this.convertToDateFormat(
                                                this.state.endDate.value
                                              )}
                                              onChange={this.handleDateChange.bind(this, "endDate")}
                                              maxDate={new Date()}
                                              minDate={this.state.startDate.value}
                                              maxDateMessage={this.props.t(
                                                "onBoarding.workExp.endDateMaxMessage"
                                              )}
                                              minDateMessage={this.props.t(
                                                "onBoarding.workExp.endDateMinMessage"
                                              )}
                                              invalidDateMessage={this.props.t(
                                                "onBoarding.errorMessage.validDateError"
                                              )}
                                              {...(this.state.endDate.errors
                                                ? {
                                                    helperText: this.state.endDate.errorText,
                                                    error: !!this.state.endDate.errors,
                                                  }
                                                : {})}
                                            />
                                          </Grid>
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </Col>
                                  ) : null}
                                  {this.state.isCurrentlyWorking.value === "false" ? (
                                    <Col
                                      sm={12}
                                      xs={12}
                                      md={4}
                                      lg={4}
                                      className={
                                        this.state.reasonForLeaving.errors
                                          ? "onBTextFieldReq onBtextfiled"
                                          : "onBtextfiled txtFldMBMob"
                                      }
                                    >
                                      <div
                                        className={
                                          this.state.reasonForLeaving.errors ? "Mui-error" : ""
                                        }
                                      >
                                        <MultipleSelect
                                          fieldValue={this.state.reasonForLeaving}
                                          fieldLabel={this.props.t(
                                            "onBoarding.workExp.reasonOfLeavingLbl"
                                          )}
                                          fieldName={"reasonForLeaving"}
                                          fieldOptions={
                                            reasonForLeavingList.reasonForLeaving
                                              ? reasonForLeavingList.reasonForLeaving.sort((a, b) =>
                                                  a.toLowerCase() > b.toLowerCase()
                                                    ? 1
                                                    : b.toLowerCase() > a.toLowerCase()
                                                    ? -1
                                                    : 0
                                                )
                                              : null
                                          }
                                          handleMultipleSelect={this.handleMultipleSelect}
                                          error={!!this.state.reasonForLeaving.errors}
                                          helperText={
                                            this.state.reasonForLeaving.errors
                                              ? this.state.reasonForLeaving.errorText
                                              : null
                                          }
                                          isMultiple={false}
                                        />
                                      </div>
                                    </Col>
                                  ) : null}
                                </Row>
                              </Col>
                              {/* If Currently working here is NO */}
                            </Row>

                            {/* Row 9 starts */}
                            <Row className="onBPermanentAddMain">
                              <Col sm={12} xs={12} md={12} lg={12} className="pl-0">
                                <div className="onBWorkExpRadio onBTextFieldReq">
                                  <label className="onBchkBoxLabel">
                                    {this.props.t("onBoarding.workExp.workStaffAgency")}
                                  </label>
                                  <RadioGroup
                                    name="staffingAgency"
                                    row
                                    value={this.value}
                                    onChange={this.radioBtnHandleChange.bind(this, "isTempJob")}
                                  >
                                    <FormControlLabel
                                      value="false"
                                      control={
                                        <Radio checked={this.state.isTempJob.value === "false"} />
                                      }
                                      label={this.props.t("onBoarding.workExp.isTempJobQn1")}
                                    />
                                    <FormControlLabel
                                      value="true"
                                      control={
                                        <Radio checked={this.state.isTempJob.value === "true"} />
                                      }
                                      label={this.props.t("onBoarding.workExp.isTempJobQn2")}
                                    />
                                    <FormHelperText className="onBWorkExpErrorChkBox">
                                      {this.state.isTempJob.errors
                                        ? this.state.isTempJob.errorText
                                        : null}
                                    </FormHelperText>
                                  </RadioGroup>
                                </div>
                                {/* If Staff Agency is YES */}
                                <Row>
                                  {this.state.isTempJob.value === "true" ? (
                                    <Col sm={12} xs={12} md={4} lg={4}>
                                      <div
                                        className={
                                          this.state.staffingAgency.errors
                                            ? "onBAutoContInfo pt-2 pb-2 onBTextFieldReq"
                                            : "onBAutoContInfo pt-2 pb-2 "
                                        }
                                      >
                                        <TextField
                                          className="mt-4"
                                          label={this.props.t(
                                            "onBoarding.workExp.staffingAgencyLbl"
                                          )}
                                          type="Add Staffing Agency"
                                          value={this.state.staffingAgency.value}
                                          onChange={this.handleChange.bind(this, "staffingAgency")}
                                          variant="outlined"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          error={!!this.state.staffingAgency.errors}
                                          helperText={
                                            this.state.staffingAgency.errors
                                              ? this.state.staffingAgency.errorText
                                              : ""
                                          }
                                          autoComplete="new-staffingAgency"
                                        />
                                      </div>
                                    </Col>
                                  ) : null}
                                </Row>
                              </Col>
                            </Row>
                            {/* Row 10 start */}

                            <Row>
                              <Col sm={12} xs={12} md={12} lg={12}>
                                <div className="onBSubHeading">
                                  {this.props.t("onBoarding.workExp.supervisorInfo")}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                sm={12}
                                xs={12}
                                md={4}
                                lg={4}
                                className={
                                  this.state.supervisor.errors
                                    ? "onBTextFieldReq onBtextfiled"
                                    : "onBtextfiled "
                                }
                              >
                                <TextField
                                  label={this.props.t("onBoarding.workExp.supervisorNameandTitle")}
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  type="TextField"
                                  value={this.state.supervisor.value}
                                  onChange={this.handleChange.bind(this, "supervisor")}
                                  helperText={
                                    this.state.supervisor.errors
                                      ? this.state.supervisor.errorText
                                      : ""
                                  }
                                  autoComplete="new-supervisor"
                                  error={this.state.supervisor.errors}
                                />
                              </Col>
                              <Col
                                sm={12}
                                xs={12}
                                md={4}
                                lg={4}
                                className={
                                  this.state.supervisorPhone.errors
                                    ? "onBTextFieldReq onBtextfiled"
                                    : "onBtextfiled "
                                }
                              ></Col>
                            </Row>
                          </div>
                          {/* SAVE BUTTON */}
                          <div>{this.renderButtons(this, this._onRowSelection)}</div>
                        </Container>
                      ) : null}

                      {/* Work Experience Table Starts */}
                      <Row className="onBoardingTable" id="onBoardingTableId">
                        <Col sm={12} md={12} lg={12} xs={12}>
                          <div className="tableWrapper">
                            {!this.state.workExperiences.length < 1 ? (
                              <div className="table tableCollapse" id="OnBWorkExp">
                                <div className="tableRow tableRowHead">
                                  <div className="tableCell workExpStatusColumn columnHeads">
                                    {this.props.t("onBoarding.workExp.statusLbl")}
                                  </div>
                                  <div className="tableCell workExpFacilityColumn columnHeads">
                                    {this.props.t("onBoarding.workExp.facilityLbl")}
                                  </div>
                                  <div className="tableCell workExpCityColumn columnHeads">
                                    {this.props.t("onBoarding.workExp.cityStateLbl").slice(0, -2)}
                                  </div>
                                  <div className="tableCell workExpDateColumn columnHeads">
                                    {this.props.t("onBoarding.workExp.startDateLbl")}
                                  </div>
                                  <div className="tableCell workExpDateColumn columnHeads">
                                    {this.props.t("onBoarding.workExp.endDateLbl")}
                                  </div>
                                  <div className="tableCell workExpActionColumn columnHeads">
                                    {this.props.t("onBoarding.workExp.actionLbl")}
                                  </div>
                                </div>
                                {this.state.workExperiences.map((record, index) => (
                                  <div
                                    className={`tableRow ${
                                      record.showVerifyNowButton ? "workExpVerifyRow" : ""
                                    }`}
                                    key={index}
                                  >
                                    {!validationsService.validateSetOfFields(
                                      record,
                                      workExpConstraints.workExpFormConstraints
                                    ) ? (
                                      <div className="tableCell workExpStatusColumn">
                                        <div className="tableHeads">
                                          {this.props.t("onBoarding.edu&spec.statusColLabel")}
                                        </div>
                                        <div className="table-content">
                                          <Chip
                                            label={this.props.t("onBoarding.onBCompleteStatus")}
                                            className="onBSpeCompleteStatus"
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="tableCell workExpStatusColumn">
                                        {record.showVerifyNowButton ? (
                                          <div className="workExpJustAdded">
                                            <Image src={OnBWorkExperienceVerify} />
                                            {this.props.t("onBoarding.workExp.justAddedText")}
                                          </div>
                                        ) : (
                                          <Chip
                                            label={this.props.t("onBoarding.onBIncompleteStatus")}
                                            className="onBSpeInCompleteStatus"
                                          />
                                        )}
                                      </div>
                                    )}

                                    <div className="tableCell workExpFacilityColumn">
                                      <div className="tableHeads">
                                        {this.props.t("onBoarding.workExp.facilityLbl")}
                                      </div>
                                      <div className="table-content ">
                                        {this.itemOrDash(record.facilityName)}
                                      </div>
                                    </div>
                                    <div className="tableCell workExpCityColumn">
                                      <div className="tableHeads">
                                        {this.props
                                          .t("onBoarding.workExp.cityStateLbl")
                                          .slice(0, -2)}
                                      </div>
                                      <div className="table-content">
                                        {this.itemOrDash(record.city)}
                                      </div>
                                    </div>
                                    <div className="tableCell workExpDateColumn">
                                      <div className="tableHeads">
                                        {this.props.t("onBoarding.workExp.startDateLbl")}
                                      </div>
                                      <div className="table-content">
                                        {this.dateOrDash(record.startDate)}
                                      </div>
                                    </div>
                                    <div className="tableCell workExpDateColumn">
                                      <div className="tableHeads">
                                        {this.props.t("onBoarding.workExp.endDateLbl")}
                                      </div>
                                      <div className="table-content">
                                        {this.dateOrDash(record.endDate)}
                                      </div>
                                    </div>
                                    <div className="tableCell workExpActionColumn onBTableIcons">
                                      <div className="tableHeads">
                                        {this.props.t("onBoarding.edu&spec.actionColLabel")}
                                      </div>
                                      <div className="table-content ">
                                        {record.showVerifyNowButton ? (
                                          <SuccessButton
                                            onClick={this.editRecord.bind(this, index)}
                                            size="small"
                                          >
                                            {this.props.t(
                                              "onBoarding.workExp.verifyWorkExperienceButtonTxt"
                                            )}
                                          </SuccessButton>
                                        ) : (
                                          <Button
                                            className="onBEditIcon"
                                            onClick={this.editRecord.bind(this, index)}
                                            disabled={this.state.disableActions}
                                          >
                                            <div>
                                              <svg
                                                className="onBEditIconSVG"
                                                xmlns="http://www.w3.org/2000/svg"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                width="24"
                                              >
                                                <path d="M0 0h24v24H0V0z" fill="none" />
                                                <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM5.92 19H5v-.92l9.06-9.06.92.92L5.92 19zM20.71 5.63l-2.34-2.34c-.2-.2-.45-.29-.71-.29s-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41z" />
                                              </svg>
                                            </div>
                                          </Button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      {/* Work Experience Table Ends */}

                      <Row className="onBPermanentAddMain workExpBoxMargin">
                        <Col sm={12} xs={12} md={12} lg={12} className="onBFNSelected pl-0">
                          <div className="onBWorkExpRadio onBTextFieldReq">
                            <label className="onBchkBoxLabel">
                              {this.props.t("onBoarding.edu&spec.legalQue2")}
                            </label>
                            <RadioGroup
                              row
                              value={this.value}
                              onChange={this.radioBtnHandleChange.bind(this, "malpractices")}
                            >
                              <FormControlLabel
                                value="false"
                                control={
                                  <Radio checked={this.state.malpractices.value === "false"} />
                                }
                                label={this.props.t("onBoarding.edu&spec.legalQueOpt2")}
                              />
                              <FormControlLabel
                                value="true"
                                control={
                                  <Radio checked={this.state.malpractices.value === "true"} />
                                }
                                label={this.props.t("onBoarding.edu&spec.legalQueOpt1")}
                              />
                              <FormHelperText className="onBerrorMarginChkBox d-inline">
                                {this.state.malpractices.errors
                                  ? this.state.malpractices.errorText
                                  : ""}
                              </FormHelperText>
                            </RadioGroup>
                          </div>
                        </Col>
                      </Row>
                      {enableIsLegalQuestion &&
                        <Row className="onBPermanentAddMain workExpBoxMargin align-items-center">
                          <Col sm={12} xs={12} md={12} lg={10} className="onBFNSelected pl-0">
                            <div className="onBWorkExpRadio onBTextFieldReq">
                              <label className="onBchkBoxLabel">
                                {this.props.t("onBoarding.edu&spec.legalQue3")}
                              </label>
                            </div>
                          </Col>
                          <Col sm={12} xs={12} md={12} lg={2} className="onBFNSelected pl-0">
                            <div>
                              <RadioGroup
                                row
                                value={this.value}
                                onChange={this.radioBtnHandleChange.bind(this, "isLegalQuestion")}
                              >
                                <FormControlLabel
                                  value="false"
                                  className="onBAddRadioBtn"
                                  control={
                                    <Radio checked={this.state.isLegalQuestion.value === "false"} />
                                  }
                                  label={this.props.t("onBoarding.edu&spec.legalQueOpt2")}
                                />
                                <FormControlLabel
                                  value="true"
                                  className="onBAddRadioBtn"
                                  control={
                                    <Radio checked={this.state.isLegalQuestion.value === "true"} />
                                  }
                                  label={this.props.t("onBoarding.edu&spec.legalQueOpt1")}
                                />
                                <FormHelperText className="onBerrorMarginChkBoxLegalQn d-inline">
                                  {this.state.isLegalQuestion.errors
                                    ? this.state.isLegalQuestion.errorText
                                    : ""}
                                </FormHelperText>
                              </RadioGroup>
                            </div>
                          </Col>
                        </Row>
                      }
                      {/* Back and Next Buttons */}
                      <Row>
                        <Col sm={12} xs={12} md={12} lg={12} className="onLastRowMargin">
                          <div className="onBNextBtn d-flex">
                            {!this.state.backButtonClicked && (
                              <>
                                <TertiaryButton
                                  size="medium"
                                  className={this.props.classes.outlineBtnColor}
                                  onClick={this.handleBackBtnClick.bind(this)}
                                >
                                  {this.props.t("onBoarding.workExp.backBtnLbl")}
                                </TertiaryButton>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {(enableReferences &&
                                  this.props.onBoardingCompletedSteps[0] &&
                                  this.props.onBoardingCompletedSteps[1] &&
                                  this.props.onBoardingCompletedSteps[2] &&
                                  this.props.onBoardingCompletedSteps[3] &&
                                  this.props.onBoardingCompletedSteps[4]) ||
                                (!enableReferences &&
                                  this.props.onBoardingCompletedSteps[0] &&
                                  this.props.onBoardingCompletedSteps[1] &&
                                  this.props.onBoardingCompletedSteps[2] &&
                                  this.props.onBoardingCompletedSteps[3]) ? (
                                  <SecondaryButton
                                    size="medium"
                                    className={this.props.classes.nextBtnMarginleft}
                                    onClick={this.handleBtnClick.bind(this, "Next")}
                                  >
                                    {this.props.t("onBoarding.basicInfo.btnLabel")}
                                  </SecondaryButton>
                                ) : (enableReferences &&
                                    this.props.onBoardingCompletedSteps[0] &&
                                    this.props.onBoardingCompletedSteps[1] &&
                                    this.props.onBoardingCompletedSteps[3] &&
                                    this.props.onBoardingCompletedSteps[4]) ||
                                  (!enableReferences &&
                                    this.props.onBoardingCompletedSteps[0] &&
                                    this.props.onBoardingCompletedSteps[1] &&
                                    this.props.onBoardingCompletedSteps[3]) ? (
                                  <SecondaryButton
                                    size="medium"
                                    className={this.props.classes.nextBtnMarginleft}
                                    onClick={this.handleBtnClick.bind(this, "Done")}
                                  >
                                    {this.props.t("onBoarding.skillChecklist.completeAppBtnLbl")}
                                  </SecondaryButton>
                                ) : (
                                  <SecondaryButton
                                    size="medium"
                                    className={this.props.classes.nextBtnMarginleft}
                                    onClick={this.handleBtnClick.bind(this, "Next")}
                                  >
                                    {this.props.t("onBoarding.basicInfo.btnLabel")}
                                  </SecondaryButton>
                                )}
                              </>
                            )}
                            {this.state.backButtonClicked && (
                              <div className="onBBackBtnPopUp" id="backButtonPopUp">
                                <IconButton
                                  aria-label="Close"
                                  className="onBPopUpCloseBtn"
                                  onClick={this.handleClose.bind(this)}
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                                <div className="onBBackBtnPopUpTxt textCenter">
                                  {this.props.t("onBoarding.edu&spec.yourChangesPopupTxt")}
                                  <span className="onBBackBtnPopUpNote">
                                    {this.props.t("onBoarding.edu&spec.yourChangesPopupTxt1")}
                                  </span>
                                </div>
                                <div className="textCenter">
                                  <Button
                                    className="onBPopupPrimaryBtn"
                                    onClick={this.handleYesClicked.bind(this)}
                                  >
                                    {this.props.t("onBoarding.skillChecklist.yesBtnLbl")}
                                  </Button>
                                  &nbsp;&nbsp;
                                  <Button
                                    className="onBPopupBtn"
                                    onClick={this.handleNoClicked.bind(this)}
                                  >
                                    {this.props.t("onBoarding.skillChecklist.noBtnLbl")}
                                  </Button>
                                </div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Container>

              <PopupModal
                open={!this.state.canBeSavedPartially && this.state.btnType === "back"}
                closePopover={this.closePopover.bind(this)}
                handleLeaveBtnClick={this.handleLeaveBtnClick.bind(this)}
              />
            </div>
            {/* Parent Column Ends  */}
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  specialtyAutosuggest: state.globalSearch.specialty,
  professionAutosuggest: state.profession.profession,
  locationAutosuggest: state.globalSearch.location,
  company: state.company.company,
  companyCode: state.company.company,
  email: state.auth.email,
  onboardingWorkExperience: state.onBoarding.workExperience,
  userId: state.auth.userId,
  applicantId: state.candidate.applicantId,
  candidateSpecialties: state.auth.candidateSpecialties,
  candidateId: state.auth.candidateId,
  accessToken: state.candidate.accessToken,
  saveBasicInfoResponse: state.onBoarding.saveBasicInfoResponse,
  firstName: state.auth.firstName,
  onBoardingCompletedSteps: state.onBoarding.completedSteps,
  onboardingSpecialtiesAndEducation: state.onBoarding.specialtiesAndEducation,
  lastName: state.auth.lastName,
  phone: state.auth.phone,
  recruiterDetails: state.consultant.recruiterDetails,
  onboardingBasicInfo: state.onBoarding.basicInfo,
  onBoardingReferences: state.onBoarding.references,
  atsBullhornCandidateId: state.candidate.atsBullhornCandidateId,
});

const mapDispatchToProps = (dispatch) => ({
  getSpecialties: (term, isInitialSpecialty, companyId) =>
    dispatch(fetchSpecialties(term, isInitialSpecialty, companyId)),
  getProfessions: (term, companyId) => dispatch(fetchProfessions(term, companyId)),
  updateCompleteFlag: (completedSteps) => dispatch(updateCompleteFlag(completedSteps)),
  updateActiveFlag: (activeSteps) => dispatch(updateActiveFlag(activeSteps)),
  clearBasicInfoResponse: () => dispatch(clearBasicInfoResponse()),
  saveUserProfile: (reqObject, referenceObj, workExperience, basicInfo) =>
    dispatch(saveUserProfile(reqObject, referenceObj, workExperience, basicInfo)),
  updateOnboardingCompletedSections: (onBoardingCompletedSections) =>
    dispatch(updateOnboardingCompletedSections(onBoardingCompletedSections)),
  updateWorkExperience: (workExperience) => dispatch(updateWorkExperience(workExperience)),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(withRouter(OnBoardingWorkExperience)))
);
