import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSelector, useDispatch } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { async } from "validate.js";
import Analytics from "../../../../services/Analytics";
import jobSearchQueryBuilder from "../../../../services/QueryBuilder/JobSearchQueryBuilder";
import SaveSearchService from "./Services/Api/SaveSearchService";
import SavedSearchesTitle from "./SavedSearchesTitle";
import EmailNotificationRadio from "./EmailNotificationRadio";
import NotificationSwitch from "./NotificationSwitch";
import { setToggleRelatedSpecialties, clearSpecsAndRelatedSpecsMapList } from "src/redux/globalSearch/action";

const useStyles = makeStyles((theme) => ({
  saveSearchRow: {
    display: "flex",
    flexWrap: "wrap",
    "@media(min-width: 0px) and (max-width: 768px)": {
      flexDirection: "column",
    },
  },

  searchNameCol: {
    width: "40%",
    borderRight: "1px solid #00000033",
    margin: "10px 0px",
    "@media(min-width: 0px) and (max-width: 768px)": {
      borderRight: "none",
      marginTop: "5px",
      marginLeft: "8px",
      width: "100%",
    },
  },
  viewJobsCol: {
    width: "15%",
    borderRight: "1px solid #00000033",
    margin: "10px 0px",
    textAlign: "center",
    "@media(min-width: 0px) and (max-width: 768px)": {
      borderRight: "none",
      padding: "0px 20px",
      width: "100%",
      textAlign: "left",
    },
  },
  emailNotiCol: {
    width: "40%",
    display: "flex",
    justifyContent: "space-around",
    borderRight: "1px solid #00000033",
    margin: "0px",
    padding: "17px 20px 10px 20px",
    "@media(min-width: 0px) and (max-width: 768px)": {
      borderRight: "none",
      display: "block",
      width: "100%",
    },
  },
  deleteCol: {
    width: "5%",
    margin: "10px 0px",
    textAlign: "center",
    "@media(min-width: 0px) and (max-width: 768px)": {
      borderRight: "none",
      width: "100%",
      padding: "0px 20px",
    },
  },
  saveSearchViewJobsTxt: {
    marginTop: "14px",
    color: "#626C7D",
    cursor: "pointer",
    fontSize: "1rem",
    "&:hover": {
      color: "#D7282F",
    },
    "@media(min-width: 0px) and (max-width: 768px)": {
      borderRight: "none",
      fontSize: "1rem",
      marginTop: "0px",
    },
    "@media(min-width: 1024px) and (max-width: 1280px)": {
      fontSize: "0.9rem",
    },
  },
  saveSearchDeleteIcon: {
    color: "#373C73",
    marginTop: "14px",
    cursor: "pointer",
    "& :hover": {
      color: theme.palette.colorSecondary,
    },
    "@media(min-width: 0px) and (max-width: 768px)": {
      textAlign: "right",
      marginTop: "0px",
    },
  },
  saveSearchWrap: {
    backgroundColor: "#fff",
    width: "100%",
    marginLeft: "1%",
    borderRadius: "5px",
    marginBottom: "10px",
    boxShadow: "0 0 6px 0 rgb(63 63 68 / 15%)",
  },
  arrowForwardIcon: {
    fontSize: "0.875rem !important",
    marginLeft: "5px",
  },
  emailNotiMl: {
    marginLeft: "1.5rem",
    "@media(min-width: 0px) and (max-width: 768px)": {
      marginLeft: "0rem",
    },
  },
}));

function SavedSearchesMaster(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const candidateId = useSelector((state) => state.auth.candidateId);
  const [savedSearchesRecords, setSavedSearchesRecords] = useState({});
  const [isTitleInputDisabled, setTitleInputDisableFlag] = useState(null);
  const originalObj = Object.entries(cloneDeep(props.results));
  const atsBullhornCandidateId = useSelector((state) => state.candidate.atsBullhornCandidateId);
  const onboardingSpecialties = useSelector((state) => state.onBoarding.specialtiesAndEducation);
  const profession = onboardingSpecialties?.specialty?.profession

  useEffect(() => {
    setSavedSearchesRecords(props.results);
  }, [props.results]);

  useEffect(() => {
    if (props.isOutSideClicked) {
      setSavedSearchesRecords(props.results);
      setTitleInputDisableFlag(null);
      props.setOutSideClickedFlag(false);
    }
  }, [props.isOutSideClicked]);

  const handleDeleteRecord = async (index) => {
    const filteredArray = Object.entries(savedSearchesRecords).filter((_, i) => i !== index);
    setSavedSearchesRecords(Object.fromEntries(filteredArray));
    saveRecord(Object.fromEntries(filteredArray));
  };

  const handleOnChange = (event, rowIndex, type) => {
    const obj = cloneDeep(savedSearchesRecords);
    const resultsArray = Object.entries(obj);
    const prevIndex = isTitleInputDisabled;

    if (type === "isEmailNotification" || type === "emailNotificationType") {
      const val = type === "isEmailNotification" ? event.target.checked : event.target.value;
      resultsArray[rowIndex][1][type] = val;
      if (prevIndex !== null && prevIndex >= 0) {
        resultsArray[prevIndex][1]["name"] = originalObj[prevIndex][1]["name"];
        setTitleInputDisableFlag(null);
      }
    } else if (type === "name") {
      resultsArray[rowIndex][1][type] = event.target.value;
    }
    setSavedSearchesRecords(Object.fromEntries(resultsArray));

    if (type === "isEmailNotification" || type === "emailNotificationType") {
      saveRecord(Object.fromEntries(resultsArray));
    }
  };

  const handleIconClick = (type) => {
    setTitleInputDisableFlag(null);
    if (type === "save") {
      saveRecord(savedSearchesRecords);
    } else {
      props.setOutSideClickedFlag(true);
    }
  };

  const handleInputDisableFlag = (index) => {
    if (isTitleInputDisabled !== index) {
      setSavedSearchesRecords(props.results);
    }
    setTitleInputDisableFlag(index);
  };

  const viewJob = (result) => {
    let relatedSpecialtiesArray = []
    if(result?.searchCriteria?.specialtyMapping){
      relatedSpecialtiesArray = Object.values(result.searchCriteria.specialtyMapping)?.flat();
    }
    if (relatedSpecialtiesArray.length === 0) {
      dispatch(clearSpecsAndRelatedSpecsMapList())
      dispatch(setToggleRelatedSpecialties(false));
    }
    else {
      dispatch(setToggleRelatedSpecialties(true));
    }
    if (result && !isEmpty(result.searchCriteria)) {
      jobSearchQueryBuilder.handleParamsChange({
        location: JSON.stringify(result.searchCriteria.location),
        specialty: JSON.stringify(result.searchCriteria.specialty),
        geoLocationRadius: result.searchCriteria.geoLocationRadius,
        startDate: result.searchCriteria.startDate,
        shiftTypes: result.searchCriteria.shiftTypes,
        refreshPayFilter: "true",
        shiftLengths: result.searchCriteria.shiftLengths,
        durations: result.searchCriteria.durations,
        salaryMax: result.searchCriteria.salaryMax,
        sort: result.searchCriteria.sort,
        msp: result.searchCriteria.msp,
        jobDetails: result.searchCriteria.jobDetails,
        datePosted: result.searchCriteria.datePosted,
        shouldFilterByQuickApplyEligibility: result?.searchCriteria?.shouldFilterByQuickApplyEligibility?.toString() ?? "",
        relatedSpecialties: result?.searchCriteria?.relatedSpecialties,
        specsWithRelSpecs: result?.searchCriteria?.specialtyMapping ? JSON.stringify(result.searchCriteria.specialtyMapping) : ""
      });
    }
  };

  const saveRecord = (updatedData) => {
    const reqObj = {
      preferences: {
        bullhornId: atsBullhornCandidateId,
        profession: profession,
        savedSearches: updatedData,
      },
    };
    SaveSearchService.createOrUpdate(candidateId, reqObj, "savedSearches")
      .then((result) => {
        if (result.statusCode === 200) {
          props.UpdateSavedSearches(updatedData);
          const segmentEventProps = {
            savedSearches: Object.values(updatedData),
            segment_source_name: `${process.env.NODE_ENV}-web-gateway`,
          };
          Analytics.segmentTrackEvent(t("segment.jobSavedSearch"), segmentEventProps);
        }
      })
      .catch((error) => {
        console.error("Error occurred while updating savedSearches:", error);
      });
  };

  return (
    <>
      {!isEmpty(savedSearchesRecords) &&
        !isEmpty(originalObj) &&
        Object.values(savedSearchesRecords).map((result, index) => (
          <div className={classes.saveSearchWrap}>
            <div className={classes.saveSearchRow}>
              <div className={classes.searchNameCol}>
                <SavedSearchesTitle
                  title={result.name}
                  index={index}
                  disabled={isTitleInputDisabled}
                  originalName={originalObj[index] ? originalObj[index][1]["name"] : ""}
                  handleInputDisableFlag={() => handleInputDisableFlag(index)}
                  onChange={(e) => handleOnChange(e, index, "name")}
                  handleIconClick={(type) => handleIconClick(type)}
                />
              </div>

              <div className={classes.viewJobsCol}>
                <div className={classes.saveSearchViewJobsTxt} onClick={() => viewJob(result)}>
                  {t("savedSearches.viewJobs")}
                  <ArrowForwardIosIcon className={classes.arrowForwardIcon} />
                </div>
              </div>

              <div className={classes.emailNotiCol}>
                <span>
                  <NotificationSwitch
                    fontsizesavesearch={"true"}
                    checked={result.isEmailNotification}
                    onChange={(e) => handleOnChange(e, index, "isEmailNotification")}
                  />
                </span>
              </div>

              <div className={classes.deleteCol}>
                <div className={classes.saveSearchDeleteIcon}>
                  <DeleteOutlinedIcon onClick={() => handleDeleteRecord(index)} />
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
}
export default SavedSearchesMaster;
