import { Box, Checkbox, Divider, InputAdornment, makeStyles, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import orderBy from "lodash/orderBy";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

type OptionType = {
  optionName: string;
  isActive: boolean;
  displayOptionName: string;
};

interface OptionsComponentProps {
  allOptions: OptionType[];
  handleChange;
  globalFilterClearClicked: boolean;
  showSkeleton: boolean;
  filterOptionsName: string;
  filterTitle: string;
  enableSelectAll: boolean;
  hideFilterIfNoOptions: boolean;
  showFilterDividerInBottom: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px",
  },
  optionSearchFieldWidthWithInput: {
    width: "243px",
    padding: "5px",
  },
  optionSearchFieldWidthWithoutInput: {
    width: "270px",
    padding: "5px",
  },
  optionsContainerWidth: { width: isMobile ? "345px" : "100%" },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginLeft: isMobile ? "-5px" : "-10px",
  },
  noOptionTxt: {
    color: "#003366",
    fontFamily: "Nunito Sans",
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "left",
  },
  optionsBox: {
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "260px",
    marginTop: "5px",
    width: "325px",

    [theme.breakpoints.down("sm")]: {
      width: "345px",
      height: "260px"
    },
  }
}));

const FilterOptions = ({
  allOptions,
  handleChange,
  globalFilterClearClicked,
  showSkeleton,
  filterOptionsName,
  filterTitle,
  enableSelectAll,
  hideFilterIfNoOptions,
  showFilterDividerInBottom,
}: OptionsComponentProps) => {
  const [options, setOptions] = useState<OptionType[]>(allOptions);
  const [optionsToRender, setOptionsToRender] = useState<OptionType[]>(allOptions);
  const [showOptionsSearchBox, setShowOptionsSearchBox] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isSearchBoxFocused, setIsSearchBoxFocused] = useState<boolean>(false);
  const [showClear, setShowClear] = useState<boolean>(false);

  const localStyleClasses = useStyles();

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const toggleIsOptionChecked = (
    toggleOptions: OptionType[],
    optionName: string,
    isActive: boolean
  ): OptionType[] =>  toggleOptions.map((o) => (o.optionName === optionName ? { ...o, isActive } : o));

  const toggleAllOptions = (toggleOptions: OptionType[], isActive: boolean): OptionType[] => toggleOptions.map(op => { return {...op, isActive} });

  useEffect(() => {
    if (allOptions && allOptions !== null && allOptions !== undefined) {
      setOptions(orderBy(Object.values(allOptions), ["displayOptionName"], ["asc"]));
      if (searchTerm.length > 0) {
        const filteredOptions = options?.filter((option) =>
          option.displayOptionName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
        );
        setOptionsToRender(filteredOptions);
      } else {
        setOptionsToRender(options);
      }
      setShowOptionsSearchBox(options?.length > 5);
      if (optionsToRender && optionsToRender?.length)
        setShowClear(optionsToRender?.some((anOption) => anOption.isActive));
    }
  }, [allOptions]);

  useEffect(() => {
    if(selectedOptions.length === 0) {
      setShowClear(false);
    }
      handleChange(filterOptionsName, selectedOptions);
  }, [selectedOptions]);

    useEffect(() => {
      const filteredOptions = options?.filter(option => option.displayOptionName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
      setOptionsToRender(filteredOptions);
    }, [searchTerm]);

    useEffect(() => {
      if (options && options.length)
      setOptions(toggleAllOptions(options, false));
      setSelectedOptions([]);
    }, [params.get("specialty")])

    useEffect(() => {
      if (options && options.length) {
        setOptions(toggleAllOptions(options, false));
      }
      const optionsFromUrl = (params.get(filterOptionsName) || "").split(",");
    if (optionsFromUrl && optionsFromUrl.length !== 0) {
      setSelectedOptions(optionsFromUrl);
      setShowClear(true);
    }
    }, [params.get(filterOptionsName), params.get("specsWithRelSpecs")])


  const getNoDataMessage = () => {
    if (optionsToRender.length === 0) return "No results to show";
    if (optionsToRender.length === 0) return "No matches found";
    return "";
  };
  

  const onOptionCheckBoxClick = (optionName, isActive) => {
    const updatedOptions = toggleIsOptionChecked(options, optionName, isActive);
    if (isActive) {
      setSelectedOptions([...selectedOptions, optionName]);
      setShowClear(true);
    } else {
      setSelectedOptions(selectedOptions.filter((option) => option !== optionName));
    }
    setSearchTerm("");
    setOptions(updatedOptions);
  };

  const handleClearAllOptions  = () => {
    setShowClear(false);
    setSelectedOptions([]);
    setOptions(toggleAllOptions(options, false));
    setSearchTerm("");
  }

  const handleSelectAllOptions  = () => {
      setSelectedOptions(optionsToRender.map(anOption => anOption.optionName));
      setOptions(toggleAllOptions(options, true));
      setShowClear(true);
    }
  
  useEffect(() => {
    if (globalFilterClearClicked) {
      setShowClear(false);
      setSelectedOptions([]);
      setOptions(toggleAllOptions(options, false));
      setSearchTerm("");
    }
  }, [globalFilterClearClicked]);

  if (hideFilterIfNoOptions && options.length === 0)
  return <></>;
  return (
    <div>
      <div>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ marginBottom: !isMobile ? "15px" : "" }}
        >
          <div>
            <span className="filterSubHeads">{filterTitle}</span>
          </div>
          {showClear && Array.isArray(optionsToRender) && optionsToRender.length !== 0 && (
            <div>
              <button
                type="button"
                className="filterOptionsClearAll"
                onClick={handleClearAllOptions}
              >
                Clear
              </button>
            </div>
          )}
          {enableSelectAll && !showClear && (
            <div>
              <button
                type="button"
                className="filterOptionsClearAll"
                onClick={handleSelectAllOptions}
              >
                Select All
              </button>
            </div>
          )}
        </div>
        {showOptionsSearchBox && !showSkeleton && (
          <TextField
            InputProps={{
              disableUnderline: true,
              style: {
                border: `1px solid ${isSearchBoxFocused ? "#003366" : "#9EA4B0"}`,
                borderRadius: "4px",
                height: "56px",
              },
              classes: {
                input: searchTerm.length
                  ? localStyleClasses.optionSearchFieldWidthWithInput
                  : localStyleClasses.optionSearchFieldWidthWithoutInput,
                root: localStyleClasses.optionsContainerWidth,
              },
              startAdornment: (
                <InputAdornment position="end" style={{ padding: "0px 10px 0px 2px" }}>
                  <Search />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment
                  position="start"
                  variant="outlined"
                  onClick={() => {
                    setSearchTerm("");
                  }}
                >
                  <svg
                    role="button"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="19" height="19" rx="9.5" fill="#D6D8DC" />
                    <path
                      d="M13.4429 6.33011C13.6415 6.1382 13.6468 5.82169 13.4549 5.62315C13.263 5.42462 12.9465 5.41924 12.7479 5.61115L5.55792 12.5607C5.35938 12.7526 5.354 13.0692 5.54592 13.2677C5.73784 13.4662 6.05438 13.4716 6.25292 13.2797L13.4429 6.33011Z"
                      fill="#656E7E"
                    />
                    <path
                      d="M6.38097 5.61815C6.18571 5.4229 5.86913 5.4229 5.67387 5.61815C5.4786 5.8134 5.4786 6.12996 5.67387 6.32521L12.7449 13.3959C12.9402 13.5911 13.2568 13.5911 13.452 13.3959C13.6473 13.2006 13.6473 12.884 13.452 12.6888L6.38097 5.61815Z"
                      fill="#656E7E"
                    />
                  </svg>
                </InputAdornment>
              ),
            }}
            onFocus={() => setIsSearchBoxFocused(true)}
            onBlur={() => setIsSearchBoxFocused(false)}
            onChange={(event) => setSearchTerm(event.target.value)}
            value={searchTerm}
            placeholder={`Find ${filterTitle}`}
            variant="standard"
          />
        )}
        {!showSkeleton && (
          <div className={localStyleClasses.optionsBox}>
            {optionsToRender?.map((anOption) => {
              return (
                <div className="row">
                  <Checkbox
                    checked={anOption.isActive}
                    onChange={(event) => {
                      onOptionCheckBoxClick(anOption.optionName, event.target.checked);
                    }}
                    className="col-2 align-content-center justify-content-end"
                  />
                  <div className={`${localStyleClasses.ellipsis} col-10 align-content-center`}>
                    {anOption.displayOptionName}
                  </div>
                </div>
              );
            })}
            {optionsToRender && !showSkeleton && optionsToRender.length === 0 && (
          <p className={`${localStyleClasses.noOptionTxt} mt-2 ml-1`}> {getNoDataMessage()} </p>
        )}
          </div>
        )}
        {showSkeleton && (
          <div>
            <div>
              <div>
                <Skeleton variant="rect" width={isMobile ? 345 : 300} height={30} />
                <Box display="flex" alignItems="center" style={{ marginTop: 10 }}>
                  <Skeleton variant="rect" width={25} height={25} />
                  <Skeleton variant="text" width={265} height={35} style={{ marginLeft: 10 }} />
                </Box>
                <Box display="flex" alignItems="center" style={{ marginTop: 10 }}>
                  <Skeleton variant="rect" width={25} height={25} />
                  <Skeleton variant="text" width={265} height={35} style={{ marginLeft: 10 }} />
                </Box>
                <Box display="flex" alignItems="center" style={{ marginTop: 10 }}>
                  <Skeleton variant="rect" width={25} height={25} />
                  <Skeleton variant="text" width={265} height={35} style={{ marginLeft: 10 }} />
                </Box>
              </div>
            </div>
          </div>
        )}
      </div>
      { showFilterDividerInBottom && <Divider variant="middle" className="filterDivider" />}
    </div>
  );
};

export default withTranslation()(FilterOptions);
