import React, { useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { Link, Divider, useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector, connect } from "react-redux";
import { useTranslation } from "react-i18next";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import LocalNavV2 from "src/components/common/Navigation/LocalNavV2";
import UserInfoIcon from "src/imagesV2/UserInfoIcon.svg";
import LogoutIcon from "src/images/Hamburger-Nav-Icon/Logout.svg";
import { DASHBOARD, LOGOUT, SAVED_SEARCHES_PAGE } from "src/router/routes";
import HamburgerMenu from "src/components/common/Navigation/HamburgerMenu";
import ChangePswIcon from "src/imagesV2/Hamburger-Nav-IconV2/ChangePswIcon.svg";
import SavedSearchesIcon from "src/imagesV2/Hamburger-Nav-IconV2/SavedSearchesIcon.svg";
import ChangePasswordPopup from "src/components/common/auth/ChangePasswordPopup";
import config from "src/config";
import Analytics from "src/services/Analytics";
import Notification from "./Notification/Notification";
import { revokeRefreshToken, isPasswordPresent } from "src/redux/auth/action.js";
import Logo from "src/components/common/Header/Logo";
import { getAccessToken } from "axios-jwt";
import { clearSpecsAndRelatedSpecsMapList, setToggleRelatedSpecialties } from "src/redux/globalSearch/action";

function HeaderV2(props) {
  const history = useHistory();
  const { t: translate } = useTranslation();
  const firstName = useSelector((state: { auth: { firstName: string } }) => state.auth.firstName);
  const [openPasswordModal, setOpenPasswordModal] = React.useState(false);
  const [showPasswordUpdateSuccessMessage, setShowPwdUpdateSuccessMsg] = React.useState(false);
  const [passwordPresent, setPasswordPresent] = React.useState("");;
  const enableSavedSearch = config.ENABLE_SAVED_SEARCH === "true";
  const enableNotification = config.ENABLE_NOTIFICATION === "true";
  const enableMyTeam = config.ENABLE_MY_TEAM === "true";
  const enablePasswordLessAuth = config.ENABLE_PASSWORD_LESS_AUTH === "true";
  const isLarge = useMediaQuery("(min-width:900px)")

  const trackEvents = (parent_text: string, text: string) => {
    const segmentEventName = translate("segment.clickedHeader");
    const segmentEventProps = {
      segment_source_name: `${process.env.NODE_ENV}-web-gateway`,
      parent_text: translate(parent_text),
      text: translate(text),
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
  };

  useEffect(() => {
    const authToken = getAccessToken();
    if (authToken) 
      {
        const password = isPasswordPresent(authToken)
        setPasswordPresent(password)
      }
  }, []);

  const handleNavigation = () => {
    trackEvents("footer.profile.dashboard", "footer.profile.dashboard");
    history.push(DASHBOARD);
  };

  const handleLogout = () => {
    trackEvents("segment.myAccount", "footer.profile.logout");
    props.clearSpecsAndRelatedSpecsMapList();
    props.setToggleRelatedSpecialties(true)
    props.revokeRefreshToken();
    history.push(LOGOUT);
  };

  const handlePasswordChange = (action) => {
    if (action) trackEvents("segment.myAccount", "headerV2.changePassword");
    setOpenPasswordModal(action);
  };

  const handlePasswordSuccess = (action) => {
    setShowPwdUpdateSuccessMsg(action);
  };

  const saveSearchNavigation = () => {
    trackEvents("segment.myAccount", "headerV2.mySavedSearches");
    history.push(SAVED_SEARCHES_PAGE);
  };

  const pageTitle = (): string | null => {
    const urlStringContainer = {
      "my-jobs": translate("header.jobsLink"),
      tasks: translate("headerV2.myTasks"),
      payroll: translate("footer.profile.payroll"),
      "need-help": translate("headerV2.benefitsTitle"),
      "my-team": translate("headerV2.myTeamTitle"),
      dashboard: translate("headerV2.dashboardTitle"),
      "my-profile": translate("footer.profile.profileDocs"),
      "certifications-and-licenses": translate("footer.profile.profileDocs"),
    };
    const urlText = filterTranslatedText(urlStringContainer);

    return urlStringContainer[urlText] || null;
  };

  const filterTranslatedText = (urlStringContainer: { [key: string]: string }) => {
    if (history.location.pathname === '/') {
        return translate("headerV2.dashboardTitle").trim().toLowerCase()
    }
    return history.location.pathname.split("/").filter((item) => {
        if (item === "my-team" && !enableMyTeam) {
            return false;
        }
        return urlStringContainer.hasOwnProperty(item);
    })[0];
};

  const renderPasswordPopUp = () => (
    <>
      {openPasswordModal ? (
        <ChangePasswordPopup
          handlePopup={handlePasswordChange}
          openModal={openPasswordModal}
          handlePasswordSuccess={handlePasswordSuccess}
        />
      ) : null}
      {showPasswordUpdateSuccessMessage ? (
        <div
          className={
            showPasswordUpdateSuccessMessage ? "successMsgFadeInV2" : "successMsgFadeOutV2"
          }
        >
          <CheckCircleOutlineOutlinedIcon className="successIcon" />
          {translate("accountSettingsForm.passwordChangeSuccessful")}
        </div>
      ) : null}
    </>
  );

  if (!isLarge) {
    return (
      <div>
        <Row>
          <Col sm={2} xs={2} md={2} lg={2}>
            <div onClick={handleNavigation}>
              <Logo mobile />
            </div>
          </Col>
          <Col sm={8} xs={8} lg={8} md={8} className="textCenter">
            <div className="pageTitleMob">{pageTitle()}</div> 
          </Col>
          <Col sm={2} xs={2} lg={2} md={2} className="pl-0 hamMenuV2">
            <div className="notificationBellIcon">
              {enableNotification &&  <Notification/>}
            </div>
            <HamburgerMenu handlePasswordPopup={handlePasswordChange} />
          </Col>
        </Row>
        {renderPasswordPopUp()}
      </div>
    );
  } else {
    return (
      <div>
        <>
          <div>
            <Row>
              <Col sm={2} xs={0} md={1} lg={2}>
                <div onClick={handleNavigation}>
                  <Logo />
                </div>
              </Col>

              <Col sm={8} xs={12} lg={7} md={9} >
                <LocalNavV2 />
              </Col>
              <Col sm={2} xs={2} md={2} lg={3}  className="userWrapV2">
                {enableNotification && <Notification />}
                <div className="userDropdownWrap">
                  <div className="helloUserV2">
                    {translate("headerV2.helloText")}
                    <>
                      {" "}
                      {firstName}!
                      <span>
                        <Image className="userIcon" src={UserInfoIcon} />
                      </span>
                    </>
                  </div>
                  <div className="userDropdown">
                    <div className="userDropdownArrowUp1" />
                    <div className="userDropdownArrowUp" />
                    <div className="userDropdownContent">
                      {enableSavedSearch && (
                        <Link className="userPopUpLinks" onClick={saveSearchNavigation}>
                          {" "}
                          <Image src={SavedSearchesIcon} className="logoutIconV2 pr-2" />
                          {translate("headerV2.mySavedSearches")}
                        </Link>
                      )}

                      {(enablePasswordLessAuth && passwordPresent === "true") && <Link className="userPopUpLinks" onClick={() => handlePasswordChange(true)}>
                        {" "}
                        <Image src={ChangePswIcon} className="logoutIconV2 pr-2" />
                        {translate("headerV2.changePassword")}
                      </Link>}
                      <Divider className="profileNavLine" />
                      <Link onClick={handleLogout} className="userPopUpLinks">
                        {" "}
                        <Image src={LogoutIcon} className="logoutIconV2 pr-2" />
                        {translate("footer.profile.logout")}
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {renderPasswordPopUp()}
        </>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  revokeRefreshToken: () => dispatch(revokeRefreshToken()),
  clearSpecsAndRelatedSpecsMapList: () => dispatch(clearSpecsAndRelatedSpecsMapList()),
  setToggleRelatedSpecialties: (payload) => dispatch(setToggleRelatedSpecialties(payload))
});

export default connect(null, mapDispatchToProps)(HeaderV2);
