import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import update from "immutability-helper";
import { withRouter } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import Chip from "@material-ui/core/Chip";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { isMobile } from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";
import config from "../../../config";
import { fetchProfessions } from "../../../redux/profession/action";
import { fetchSpecialties, fetchLocation } from "../../../redux/globalSearch/action";
import {
  saveBasicInformation,
  updateCompleteFlag,
  updateActiveFlag,
  clearBasicInfoResponse,
  saveUserProfile,
} from "../../../redux/onBoarding/action";
import usStates from "../../../constants/onBoardingUSStates.json";
import countries from "../../../constants/trainingCountries.json";
import degrees from "../../../constants/onBoardingDegree.json";
import Analytics from "../../../services/Analytics";
import { WORK_EXP_PAGE, BASIC_INFO_PAGE, DASHBOARD } from "../../../router/routes";
import educationConstraints from "../../../services/EducationConstraint";
import { SPECIALTIES_AND_EDUCATION } from "../../../constants/onboardingEventName";
import validationService from "../../../services/Validations";
import formFieldNames from "../../../constants/onboardingFieldNames.json";
import PopupModal from "./OnBoardingErrorMsgModal";
import { OnBoardingConfirmModal } from "./OnBoardingConfirmModal";
import analyticsSegmentIdentify from "../../../services/SegmentIdentify";
import { PrimaryButton, SecondaryButton, TertiaryButton } from "../Buttons";
import "./OnBoardingStyles/SpecialtyAndEducation.scss";
import MyProfileNavWrapper from "./MyProfileNavWrapper";

const enableReferences = config.ENABLE_REFERENCES === "true";
const enableIsLegalQuestion = config.ENABLE_IS_LEGAL_QUESTION === "true";
const perStepPercentage = enableReferences ? 20 : 25;

const SelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: "40vh",
    },
  },
};
const styles = (theme) => ({
  nextBtnMarginleft: {
    marginLeft: "auto",
  },
  outlineBtnColor: {
    border: "1px solid #707070 !important",
    color: "#707070",
  },
  iconPaddingBottom: {
    paddingBottom: 2,
  },
});

class OnBoardingSpecialties extends Component {
  constructor(props) {
    super(props);
    validationService.init();
    validationService.setCustomEducationValidations();
    const onboardingEduFromDbArray = [];
    if (
      this.props.onboardingSpecialtiesAndEducation &&
      this.props.onboardingSpecialtiesAndEducation.educationList &&
      this.props.onboardingSpecialtiesAndEducation.educationList.length > 0
    ) {
      this.props.onboardingSpecialtiesAndEducation.educationList.forEach((list) => {
        const onboardingEduFromDb = {};
        formFieldNames.educationSpecialtiesForm.forEach((field) => {
          if (field === "trainingCountry") {
            onboardingEduFromDb[field] = validationService.verifyDropdownValues(
              "single",
              "trainingCountry",
              list.trainingCountry,
              countries.country,
              "specialtiesAndEducation"
            );
          } else if (field === "degree") {
            onboardingEduFromDb[field] = validationService.verifyDropdownValues(
              "single",
              "degree",
              list.degree,
              degrees.degrees,
              "specialtiesAndEducation"
            );
          } else onboardingEduFromDb[field] = list[field];
        });
        onboardingEduFromDbArray.push(onboardingEduFromDb);
      });
    }
    const onBoardingSpecialties = this.props.onboardingSpecialtiesAndEducation
      ? this.props.onboardingSpecialtiesAndEducation.specialty
        ? this.props.onboardingSpecialtiesAndEducation.specialty
        : null
      : null;
    this.state = {
      backButtonClicked: false,
      openPrimarySpecialty: false,
      openSecondarySpecialty: false,
      openProfession: false,
      openPopover: false,
      clickedValue: "",
      preType: "",
      onChangeFinalValue: "#",
      deleteRowIndex: 0,
      canBeSavedPartially: true,
      errorMessages: [],
      btnType: "",
      profession: {
        value:
          onBoardingSpecialties && onBoardingSpecialties.profession
            ? onBoardingSpecialties.profession
            : "",
        code: "",
        errors: !(onBoardingSpecialties && onBoardingSpecialties.profession),
        errorText:
          onBoardingSpecialties && onBoardingSpecialties.profession
            ? ""
            : this.props.t("onBoarding.errorMessage.required"),
      },
      primarySpecialty: {
        value:
          onBoardingSpecialties && onBoardingSpecialties.primarySpecialty
            ? onBoardingSpecialties.primarySpecialty
            : null,
        code: "",
      },
      secondarySpecialty: {
        value:
          onBoardingSpecialties && onBoardingSpecialties.secondarySpecialty
            ? onBoardingSpecialties.secondarySpecialty
            : null,
        code: "",
      },
      yearsOfExperience: {
        value:
          onBoardingSpecialties && onBoardingSpecialties.yearsOfExperience
            ? onBoardingSpecialties && onBoardingSpecialties.yearsOfExperience.toString()
            : "",
        errors: !(onBoardingSpecialties && onBoardingSpecialties.yearsOfExperience),
        errorText:
          onBoardingSpecialties && onBoardingSpecialties.yearsOfExperience
            ? ""
            : this.props.t("onBoarding.errorMessage.required"),
      },
      degree: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      graduationDate: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      vocationalSchool: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      city: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      state: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      nameOnDegree: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      course: {
        value: "",
        errors: false,
        errorText: "",
      },
      trainingCountry: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      certificationExpirationDate: {
        value: null,
        errors: false,
        errorText: "",
      },
      visaScreenExpiration: {
        value: null,
        errors: false,
        errorText: "",
      },
      educationRecords: onboardingEduFromDbArray,
      index: 0,
      isRecordToEdit: false,
      isRecordUpdated: false,
      addEduDisableButton: true,
      addEdButtonClicked: onboardingEduFromDbArray.length > 0,
      disableUpdateButton: true,
      disableCancelButton: true,
      disableActions: false,
      primarySpecialtyName:
        onBoardingSpecialties && onBoardingSpecialties.primarySpecialty
          ? onBoardingSpecialties.primarySpecialty
          : "",
      professionName:
        onBoardingSpecialties && onBoardingSpecialties.profession
          ? onBoardingSpecialties.profession
          : "",
      secondarySpecialtyName:
        onBoardingSpecialties && onBoardingSpecialties.secondarySpecialty
          ? onBoardingSpecialties.secondarySpecialty
          : "",
      hideEducationForm: onboardingEduFromDbArray.length > 0,
      openModal: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.userId && this.props.email) {
      analyticsSegmentIdentify(this.props);
    }
    const profileProgress = this.profileProgressInPercentage();
    const segmentEventName = this.props.t("segment.profileViewed");
    const segmentEventProps = {
      section: this.props.t("myProfileSubNav.specialties&Education"),
      progress: profileProgress,
      origin: document.referrer,
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
  }

  profileProgressInPercentage() {
    let completedPercentage = 0;
    let noOfStepsCompleted = 0;
    for (const prop in this.props.onBoardingCompletedSteps) {
      if (Object.prototype.hasOwnProperty.call(this.props.onBoardingCompletedSteps, prop)) {
        noOfStepsCompleted = noOfStepsCompleted + 1;
      }
    }

    completedPercentage = noOfStepsCompleted * perStepPercentage;

    return completedPercentage;
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.email !== prevProps.email ||
      this.props.recruiterDetails !== prevProps.recruiterDetails
    ) {
      analyticsSegmentIdentify(this.props);
    }
    if (
      this.props.onboardingSpecialtiesAndEducation !==
        prevProps.onboardingSpecialtiesAndEducation ||
      this.props.saveBasicInfoResponse !== prevProps.saveBasicInfoResponse
    ) {
      const onboardingEduFromDbArray = [];
      if (
        this.props.onboardingSpecialtiesAndEducation &&
        this.props.onboardingSpecialtiesAndEducation.educationList &&
        this.props.onboardingSpecialtiesAndEducation.educationList.length > 0
      ) {
        this.props.onboardingSpecialtiesAndEducation.educationList.forEach((list) => {
          const onboardingEduFromDb = {};
          formFieldNames.educationSpecialtiesForm.forEach((field) => {
            if (field === "trainingCountry") {
              onboardingEduFromDb[field] = validationService.verifyDropdownValues(
                "single",
                "trainingCountry",
                list.trainingCountry,
                countries.country,
                "specialtiesAndEducation"
              );
            } else if (field === "degree") {
              onboardingEduFromDb[field] = validationService.verifyDropdownValues(
                "single",
                "degree",
                list.degree,
                degrees.degrees,
                "specialtiesAndEducation"
              );
            } else onboardingEduFromDb[field] = list[field];
          });
          onboardingEduFromDbArray.push(onboardingEduFromDb);
        });
        const onBoardingSpecialties = this.props.onboardingSpecialtiesAndEducation
          ? this.props.onboardingSpecialtiesAndEducation.specialty
            ? this.props.onboardingSpecialtiesAndEducation.specialty
            : null
          : null;
        await this.setState({
          profession: {
            value:
              onBoardingSpecialties && onBoardingSpecialties.profession
                ? onBoardingSpecialties.profession
                : "",
            code: "",
            errors: !(onBoardingSpecialties && onBoardingSpecialties.profession),
            errorText:
              onBoardingSpecialties && onBoardingSpecialties.profession
                ? ""
                : this.props.t("onBoarding.errorMessage.required"),
          },
          primarySpecialty: {
            value:
              onBoardingSpecialties && onBoardingSpecialties.primarySpecialty
                ? onBoardingSpecialties.primarySpecialty
                : null,
            code: "",
          },
          secondarySpecialty: {
            value:
              onBoardingSpecialties && onBoardingSpecialties.secondarySpecialty
                ? onBoardingSpecialties.secondarySpecialty
                : null,
            code: "",
          },
          yearsOfExperience: {
            value:
              onBoardingSpecialties && onBoardingSpecialties.yearsOfExperience
                ? onBoardingSpecialties && onBoardingSpecialties.yearsOfExperience.toString()
                : "",
            errors: !(onBoardingSpecialties && onBoardingSpecialties.yearsOfExperience),
            errorText:
              onBoardingSpecialties && onBoardingSpecialties.yearsOfExperience
                ? ""
                : this.props.t("onBoarding.errorMessage.required"),
          },
          course: {
            value: "",
            errors: !(onboardingEduFromDbArray.length > 0),
            errorText: "",
          },
          educationRecords: onboardingEduFromDbArray,
          addEdButtonClicked: onboardingEduFromDbArray.length > 0,
          primarySpecialtyName:
            onBoardingSpecialties && onBoardingSpecialties.primarySpecialty
              ? onBoardingSpecialties.primarySpecialty
              : "",
          professionName:
            onBoardingSpecialties && onBoardingSpecialties.profession
              ? onBoardingSpecialties.profession
              : "",
          secondarySpecialtyName:
            onBoardingSpecialties && onBoardingSpecialties.secondarySpecialty
              ? onBoardingSpecialties.secondarySpecialty
              : "",
          hideEducationForm: onboardingEduFromDbArray.length > 0,
          openModal: false,
        });
      }
    }
  }

  saveRecord = async () => {
    document.getElementById("onBoardingTableId").scrollIntoView({ block: "center" });
    this.setState({ gray: !this.state.gray, addEdButtonClicked: true });
    const record = {};
    formFieldNames.educationSpecialtiesForm.forEach((field) => {
      record[field] =
        this.state[field].value && this.state[field].value.trim() !== ""
          ? this.state[field].value
          : null;
    });
    await this.setState((preState) => ({
      educationRecords: [...preState.educationRecords, record],
    }));
    await this.setState({
      degree: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      graduationDate: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      vocationalSchool: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      city: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      state: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      nameOnDegree: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      course: {
        value: "",
        errors: false,
        errorText: "",
      },
      trainingCountry: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      certificationExpirationDate: {
        value: null,
        errors: false,
        errorText: "",
      },
      visaScreenExpiration: {
        value: null,
        errors: false,
        errorText: "",
      },
      addEduDisableButton: true,
      disableCancelButton: true,
      disableActions: false,
      hideEducationForm: true,
      openModal: true,
    });
  };

  handleBackBtnClick = async () => {
    const { reqObj, canBeSavedPartially } = await this.normalizeSpecialtiesAndEducationJson();

    this.setState({ openModal: false });
    if (canBeSavedPartially) {
      //Save Data PopUp
      this.setState({
        backButtonClicked: true,
        specialtiesAndEducationReqObj: reqObj,
      });
      this.disableScroll();
    } else {
      //Invalid Fields PopUp
      this.setState({
        btnType: "back",
        canBeSavedPartially,
      });
    }
  };

  normalizeSpecialtiesAndEducationJson = async () => {
    const specialtiesAndEducationReqObj = {
      specialty: {
        profession: this.state.profession.value,
        primarySpecialty:
          this.state.primarySpecialty.value && this.state.primarySpecialty.value.trim()
            ? this.state.primarySpecialty.value
            : null,
        secondarySpecialty:
          this.state.secondarySpecialty.value && this.state.secondarySpecialty.value.trim()
            ? this.state.secondarySpecialty.value
            : null,
        yearsOfExperience:
          this.state.yearsOfExperience.value && this.state.yearsOfExperience.value.trim()
            ? this.state.yearsOfExperience.value
            : null,
      },
      isMalpracticeClaim: this.props.specialtiesAndEducation.isMalpracticeClaim,
      ...(enableIsLegalQuestion && {isLegal: this.props.specialtiesAndEducation.isLegal}),
      educationList: this.state.educationRecords.map((rec) => ({
        degree: rec.degree,
        graduationDate: rec.graduationDate,
        vocationalSchool: rec.vocationalSchool,
        city: rec.city,
        state: rec.state,
        nameOnDegree: rec.nameOnDegree,
        trainingCountry: rec.trainingCountry,
        certificationExpirationDate: rec.certificationExpirationDate,
        visaScreenExpiration: rec.visaScreenExpiration,
        course: rec.course ? (rec.course.trim() ? rec.course : null) : null,
      })),
    };
    const { validators, canBeSavedPartially } = await this.validatePage(
      specialtiesAndEducationReqObj
    );
    let reqObj;
    if (canBeSavedPartially) {
      reqObj = {
        event: SPECIALTIES_AND_EDUCATION,
        candidateId: this.props.candidateId,
        email: this.props.email,
        companyId: parseInt(this.props.company.id),
        sqsEvent: true,
        profile: {
          meta: {
            lastVisitedPage: "1",
            completedSections: {
              basicInfo: !!this.props.onBoardingCompletedSteps[0],
              specialtiesAndEducation: !validators,
              workExperience: !!this.props.onBoardingCompletedSteps[2],
              skillsChecklists: !!(
                (enableReferences && this.props.onBoardingCompletedSteps[4]) ||
                (!enableReferences && this.props.onBoardingCompletedSteps[3])
              ),
              references: !!(enableReferences && this.props.onBoardingCompletedSteps[3]),
            },
          },
          specialtiesAndEducation: specialtiesAndEducationReqObj,
        },
      };
    }
    return { reqObj, canBeSavedPartially, validators };
  };

  validatePage = async (specialtiesAndEducationReqObj) => {
    const validators = validationService.validateSetOfFields(
      specialtiesAndEducationReqObj,
      educationConstraints.educationPageConstraints
    );

    let canBeSavedPartially;
    if (
      validators &&
      validators["educationList"] &&
      !validators["educationList"].includes("* Required")
    ) {
      canBeSavedPartially = false;
      validators["educationList"] = this.props.t("onBoarding.errorMessage.educationErrorMsg");
    } else if (
      validators &&
      validators["specialty.profession"] &&
      !validators["specialty.profession"].includes("* Required")
    ) {
      canBeSavedPartially = false;
    } else if (
      validators &&
      validators["specialty.yearsOfExperience"] &&
      !validators["specialty.yearsOfExperience"].includes("* Required")
    ) {
      canBeSavedPartially = false;
    } else canBeSavedPartially = true;

    return { validators, canBeSavedPartially };
  };

  saveData(redirection) {
    if (
      this.state.specialtiesAndEducationReqObj &&
      this.state.specialtiesAndEducationReqObj.profile &&
      this.state.specialtiesAndEducationReqObj.profile.specialtiesAndEducation &&
      this.state.specialtiesAndEducationReqObj.profile.specialtiesAndEducation.specialty &&
      this.props.onboardingSpecialtiesAndEducation &&
      this.props.onboardingSpecialtiesAndEducation.specialty &&
      (this.props.onboardingSpecialtiesAndEducation.specialty.profession !==
        this.state.specialtiesAndEducationReqObj.profile.specialtiesAndEducation.specialty
          .profession ||
        this.props.onboardingSpecialtiesAndEducation.specialty.primarySpecialty !==
          this.state.specialtiesAndEducationReqObj.profile.specialtiesAndEducation.specialty
            .primarySpecialty)
    ) {
      this.triggerSegmentIdentify();
    }
    const references = this.props.onBoardingReferences;
    this.props
      .saveUserProfile(
        this.state.specialtiesAndEducationReqObj,
        references,
        this.props.onboardingWorkExperience,
        this.props.onboardingBasicInfo
      )
      .then(
        (res) => {
          if (redirection === "Back") {
            this.props.updateActiveFlag(0);
            this.props.history.push(BASIC_INFO_PAGE);
          } else {
            const dimensions = {
              dimension9:
                this.props.candidateSpecialties && this.props.candidateSpecialties.length > 0
                  ? this.props.candidateSpecialties.toString()
                  : null,
            };
            const event = {
              category: this.props.t("googleAnalytics.category.onboarding"),
              action: this.props.t("googleAnalytics.action.submitEducation"),
              label: this.props.t("googleAnalytics.label.education"),
            };
            Analytics.sendPageview(this.props.history.location, dimensions);
            Analytics.sendEvent(event);
            const onBoardingCompletedStepsFinal = this.props.onBoardingCompletedSteps;
            let isSpecComplete = false;
            if (
              this.state.profession.value &&
              this.state.profession.value !== "" &&
              this.state.educationRecords.length > 0 &&
              this.state.yearsOfExperience.value &&
              this.state.yearsOfExperience.value !== ""
            ) {
              onBoardingCompletedStepsFinal[1] = true;
              isSpecComplete = true;
            }
            this.props.updateCompleteFlag(onBoardingCompletedStepsFinal);
            if (redirection === "Next") {
              this.props.updateActiveFlag(2);
              this.props.history.push(WORK_EXP_PAGE);
            } else if (redirection === "Done") {
              if (isSpecComplete) {
                //Complete Application event triggered
                const event = {
                  category: this.props.t("googleAnalytics.category.onboarding"),
                  action: this.props.t("googleAnalytics.action.completeApplication"),
                  label: this.props.t("googleAnalytics.label.application"),
                };
                const dimensions = {
                  dimension9:
                    this.props.candidateSpecialties && this.props.candidateSpecialties.length > 0
                      ? this.props.candidateSpecialties.toString()
                      : null,
                };
                Analytics.sendPageview(this.props.history.location, dimensions);
                Analytics.sendEvent(event);
              }
              this.props.history.push(DASHBOARD);
            }
          }
          const profileProgress = this.profileProgressInPercentage();
          const segmentEventName = this.props.t("segment.profileUpdated");
          const lineItem = [];
          if (
            this.state.specialtiesAndEducationReqObj &&
            this.state.specialtiesAndEducationReqObj.profile &&
            this.state.specialtiesAndEducationReqObj.profile.specialtiesAndEducation &&
            this.state.specialtiesAndEducationReqObj.profile.specialtiesAndEducation
              .educationList &&
            this.state.specialtiesAndEducationReqObj.profile.specialtiesAndEducation.educationList
              .length > 0
          ) {
            this.state.specialtiesAndEducationReqObj.profile.specialtiesAndEducation.educationList.forEach(
              (education) => {
                lineItem.push(education.degree);
              }
            );
          }
          const segmentEventProps = {
            section: this.props.t("myProfileSubNav.specialties&Education"),
            progress: profileProgress,
            origin: document.referrer,
            lineItem,
          };
          Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
        },
        (err) => {
          //error message for failure
        }
      );
  }

  handleYesClicked = async () => {
    this.saveData("Back");
    if (
      this.props.saveBasicInfoResponse &&
      this.props.saveBasicInfoResponse.content &&
      this.props.saveBasicInfoResponse.content.length > 0
    ) {
      this.props.clearBasicInfoResponse();
    }
    this.enableScroll();
  };

  triggerSegmentIdentify() {
    const obj = {
      userId: this.props.userId,
      email: this.props.email,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      phone: this.props.phone,
      company: this.props.company,
      profession: this.state.specialtiesAndEducationReqObj.profession,
      specialties: this.state.specialtiesAndEducationReqObj.specialty,
      streetAddress:
        this.props.onboardingBasicInfo &&
        this.props.onboardingBasicInfo.permanentAddress &&
        this.props.onboardingBasicInfo.permanentAddress.streetAddress
          ? this.props.onboardingBasicInfo.permanentAddress.streetAddress
          : null,
      city:
        this.props.onboardingBasicInfo &&
        this.props.onboardingBasicInfo.permanentAddress &&
        this.props.onboardingBasicInfo.permanentAddress.city
          ? this.props.onboardingBasicInfo.permanentAddress.city
          : null,
      state:
        this.props.onboardingBasicInfo &&
        this.props.onboardingBasicInfo.permanentAddress &&
        this.props.onboardingBasicInfo.permanentAddress.state
          ? this.props.onboardingBasicInfo.permanentAddress.state
          : null,
      zip:
        this.props.onboardingBasicInfo &&
        this.props.onboardingBasicInfo.permanentAddress &&
        this.props.onboardingBasicInfo.permanentAddress.zip
          ? this.props.onboardingBasicInfo.permanentAddress.zip
          : null,
      recruiterFname:
        this.props.recruiterDetails && this.props.recruiterDetails.firstName
          ? this.props.recruiterDetails.firstName
          : null,
      recruiterLname:
        this.props.recruiterDetails && this.props.recruiterDetails.lastName
          ? this.props.recruiterDetails.lastName
          : null,
      recruiterEmail:
        this.props.recruiterDetails && this.props.recruiterDetails.emailId
          ? this.props.recruiterDetails.emailId
          : null,
    };
    Analytics.segmentIdentify(obj);
  }

  handleClose = async () => {
    this.setState({
      backButtonClicked: false,
    });
  };

  handleNoClicked = async () => {
    await this.setState({
      backButtonClicked: false,
      openModal: false,
    });
    this.props.updateActiveFlag(0);
    this.enableScroll();
    this.props.history.push(BASIC_INFO_PAGE);
  };

  disableScroll = async () => {
    window.addEventListener("scroll", this.noScroll.bind(this));
  };

  enableScroll = async () => {
    window.removeEventListener("scroll", this.noScroll.bind(this));
  };

  noScroll = async () => {
    document.getElementById("backButtonPopUp") &&
      document.getElementById("backButtonPopUp").scrollIntoView({ block: "center" });
  };

  handleBtnClick = async (redirection) => {
    const { reqObj, canBeSavedPartially, validators } =
      await this.normalizeSpecialtiesAndEducationJson();

    if (canBeSavedPartially) {
      this.setState({
        specialtiesAndEducationReqObj: reqObj,
        openModal: false,
      });
      this.saveData(redirection);
    } else {
      //Show Error Message
      const fieldsWithError = Object.keys(validators);
      const errorMessages = [];
      fieldsWithError.forEach((field) => {
        if (!validators[field].includes("* Required")) {
          if (validators[field][0].includes("* ")) {
            validators[field] = validators[field][0].replace("* ", "");
          }
          errorMessages.push(validators[field]);
        }
      });
      await this.setState({
        btnType: "next",
        canBeSavedPartially,
        errorMessages,
        openModal: false,
      });
      if (!this.state.canBeSavedPartially) {
        window.scrollTo(0, 0);
      }
    }
  };

  editRecord = async (index, type) => {
    const educationData = this.state.educationRecords[index];
    formFieldNames.educationSpecialtiesForm.forEach((field) => {
      if (educationData[field] && educationData[field].trim() === "") {
        educationData[field] = null;
      }
    });
    const eduFormValidations = validationService.validateSetOfFields(
      educationData,
      educationConstraints.educationFormConstraints
    );
    if (!isNaN(index)) {
      this.setState((prevState) => ({
        degree: {
          value: educationData.degree,
          errors: !!(eduFormValidations && eduFormValidations.degree),
          errorText:
            eduFormValidations && eduFormValidations.degree ? eduFormValidations.degree[0] : "",
        },
        graduationDate: {
          value: educationData.graduationDate,
          errors: !!(eduFormValidations && eduFormValidations.graduationDate),
          errorText:
            eduFormValidations && eduFormValidations.graduationDate
              ? eduFormValidations.graduationDate[0]
              : "",
        },
        vocationalSchool: {
          value: educationData.vocationalSchool,
          errors: !!(eduFormValidations && eduFormValidations.vocationalSchool),
          errorText:
            eduFormValidations && eduFormValidations.vocationalSchool
              ? eduFormValidations.vocationalSchool[0]
              : "",
        },
        city: {
          value: educationData.city,
          errors: !!(eduFormValidations && eduFormValidations.city),
          errorText:
            eduFormValidations && eduFormValidations.city ? eduFormValidations.city[0] : "",
        },
        state: {
          value: educationData.state,
          errors: !!(eduFormValidations && eduFormValidations.state),
          errorText:
            eduFormValidations && eduFormValidations.state ? eduFormValidations.state[0] : "",
        },
        nameOnDegree: {
          value: educationData.nameOnDegree,
          errors: !!(eduFormValidations && eduFormValidations.nameOnDegree),
          errorText:
            eduFormValidations && eduFormValidations.nameOnDegree
              ? eduFormValidations.nameOnDegree[0]
              : "",
        },
        course: {
          value: educationData.course,
          errors: !!(eduFormValidations && eduFormValidations.course),
          errorText:
            eduFormValidations && eduFormValidations.course ? eduFormValidations.course[0] : "",
        },
        trainingCountry: {
          value: educationData.trainingCountry,
          errors: !!(eduFormValidations && eduFormValidations.trainingCountry),
          errorText:
            eduFormValidations && eduFormValidations.trainingCountry
              ? eduFormValidations.trainingCountry[0]
              : "",
        },
        certificationExpirationDate: {
          value: educationData.certificationExpirationDate,
          errors: !!(eduFormValidations && eduFormValidations.certificationExpirationDate),
          errorText:
            eduFormValidations && eduFormValidations.certificationExpirationDate
              ? eduFormValidations.certificationExpirationDate[0]
              : "",
        },
        visaScreenExpiration: {
          value: educationData.visaScreenExpiration,
          errors: !!(eduFormValidations && eduFormValidations.visaScreenExpiration),
          errorText:
            eduFormValidations && eduFormValidations.visaScreenExpiration
              ? eduFormValidations.visaScreenExpiration[0]
              : "",
        },
        isRecordToEdit: true,
        isRecordUpdated: false,
        disableCancelButton: false,
        disableActions: true,
        disableUpdateButton: true,
        openModal: false,
        index,
        hideEducationForm: false,
      }));
    }
  };

  handleChange = async (fieldType, e) => {
    this.setState({ openModal: true });
    const fieldValue = e.target.value && e.target.value.trim() !== "" ? e.target.value : "";
    await this.setState((prevState) => {
      const validators = validationService.validateSingleField(
        fieldValue,
        educationConstraints.educationFormConstraints[fieldType]
      );
      return {
        [fieldType]: {
          value: fieldValue,
          errors: !!validators,
          errorText: validators ? validators[0] : null,
        },
      };
    });
    const educationRecord = {};
    formFieldNames.educationSpecialtiesForm.forEach((field) => {
      educationRecord[field] =
        this.state[field].value && this.state[field].value.trim() !== ""
          ? this.state[field].value
          : null;
    });
    const validators = validationService.validateSetOfFields(
      educationRecord,
      educationConstraints.educationFormConstraints
    );
    this.setState({
      disableCancelButton: false,
      addEduDisableButton: !!validators,
      disableUpdateButton: !!validators,
    });
  };

  async addEducation() {
    await this.setState({
      hideEducationForm: false,
      disableCancelButton: false,
    });
  }

  renderAddEductionBtn(type, index) {
    if (this.state.hideEducationForm) {
      return (
        <Row>
          <Col sm={12} xs={12} md={12} lg={12} className="onBtextfiled">
            <PrimaryButton
              onClick={this.addEducation.bind(this)}
              size="medium"
              className="onBFullWidthMob"
            >
              <AddIcon fontSize="small" className={this.props.classes.iconPaddingBottom} />
              <span>{this.props.t("onBoarding.edu&spec.addBtnLabel")}</span>
            </PrimaryButton>
          </Col>
        </Row>
      );
    }
  }

  renderButtons(type, index) {
    if (this.state.isRecordToEdit && !this.state.isRecordUpdated) {
      return (
        <Row>
          <Col sm={12} xs={12} md={12} lg={12}>
            <div className="onBNextBtn d-flex justify-content-center">
              <PrimaryButton
                onClick={this.updateRecord.bind(this, this.state.index)}
                disabled={this.state.disableUpdateButton}
                size="medium"
              >
                {this.props.t("onBoarding.edu&spec.updateBtnLabel")}
              </PrimaryButton>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <TertiaryButton
                size="medium"
                className={this.props.classes.outlineBtnColor}
                onClick={this.cancelUpdate.bind(this)}
              >
                {this.props.t("onBoarding.edu&spec.cancelBtnLabel")}
              </TertiaryButton>
            </div>
          </Col>
        </Row>
      );
    }
    return (
      <Row>
        <Col sm={12} xs={12} md={12} lg={12} className="onBtextfiled">
          <div className="onBFormSaveBtn">
            <div className="d-flex justify-content-center">
              {this.state.educationRecords.length > 0 ? (
                <div className="d-flex justify-content-center">
                  <PrimaryButton
                    onClick={this.saveRecord.bind(this)}
                    disabled={this.state.addEduDisableButton}
                    size="medium"
                  >
                    {this.props.t("onBoarding.edu&spec.saveBtnLabel")}
                  </PrimaryButton>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <TertiaryButton
                    size="medium"
                    className={this.props.classes.outlineBtnColor}
                    onClick={this.cancelUpdate.bind(this)}
                    disabled={this.state.disableCancelButton}
                  >
                    {this.props.t("onBoarding.edu&spec.cancelBtnLabel")}
                  </TertiaryButton>
                </div>
              ) : (
                <PrimaryButton
                  onClick={this.saveRecord.bind(this)}
                  disabled={this.state.addEduDisableButton}
                  size="medium"
                >
                  {this.props.t("onBoarding.edu&spec.saveBtnLabel")}
                </PrimaryButton>
              )}
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  cancelUpdate() {
    this.setState({
      isRecordToEdit: false,
      isRecordUpdated: false,
      disableCancelButton: true,
      openModal: false,
    });
    this.setState({
      degree: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      graduationDate: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      vocationalSchool: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      city: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      state: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      nameOnDegree: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      course: {
        value: "",
        errors: false,
        errorText: "",
      },
      trainingCountry: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      certificationExpirationDate: {
        value: null,
        errors: false,
        errorText: "",
      },
      visaScreenExpiration: {
        value: null,
        errors: false,
        errorText: "",
      },
      addEdButtonClicked: true,
      disableActions: false,
      index: 0,
      addEduDisableButton: true,
      hideEducationForm: true,
    });
  }

  updateRecord(index, type) {
    this.setState({ openModal: true });
    document.getElementById("onBoardingTableId").scrollIntoView({ block: "center" });
    const record = {};
    formFieldNames.educationSpecialtiesForm.forEach((field) => {
      record[field] =
        this.state[field].value && this.state[field].value.trim() !== ""
          ? this.state[field].value
          : null;
    });
    const updatedEdRecords = update(this.state.educationRecords, {
      $splice: [[index, 1, record]],
    });
    this.setState({
      educationRecords: updatedEdRecords,
      isRecordToEdit: false,
      isRecordUpdated: true,
      degree: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      graduationDate: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      vocationalSchool: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      city: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      state: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      nameOnDegree: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      course: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      trainingCountry: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      certificationExpirationDate: {
        value: null,
        errors: false,
        errorText: "",
      },
      visaScreenExpiration: {
        value: null,
        errors: false,
        errorText: "",
      },
      addEduDisableButton: true,
      disableUpdateButton: true,
      disableCancelButton: true,
      addEdButtonClicked: true,
      disableActions: false,
      index: 0,
      hideEducationForm: true,
    });
  }
  onInputChange = async (type, event, value) => {
    let validators = validationService.validateSingleField(
      value,
      educationConstraints.educationPageConstraints[`specialty.${type}`]
    );
    let fieldName;
    if (type === "primarySpecialty") fieldName = "openPrimarySpecialty";
    else if (type === "secondarySpecialty") fieldName = "openSecondarySpecialty";
    else if (type === "profession") fieldName = "openProfession";
    if (event && event.type.trim().toString() !== "blur") {
      const eventType = event.type.trim().toString();
      this.setState({
        [fieldName]: !(validators || value.trim() === ""),
        [type]: {
          value: value.trim() !== "" ? value : "",
          errors: !!validators,
          errorText: validators ? validators[0] : null,
        },
        openModal: true,
      });
      if (!validators) {
        if (type === "primarySpecialty" || type === "secondarySpecialty")
          this.props.getSpecialties(
            value,
            false,
            this.props.company.id,
            this.state.profession.code
          );
        else if (type === "profession") this.props.getProfessions(value, this.props.company.id);
        else this.props.getLocation(value);
      }
      if (eventType === "click") {
        await this.setState({
          preType: type,
          clickedValue: value.trim() !== "" ? value : "",
        });
      }
      if (eventType === "change") {
        await this.setState({
          onChangeFinalValue: value,
        });
      }
    } else if (event && event.type.trim().toString() === "blur") {
      validators = validationService.validateSingleField(
        this.state.clickedValue && type === this.state.preType
          ? this.state.clickedValue
          : this.state[`${type}Name`],
        educationConstraints.educationPageConstraints[`specialty.${type}`]
      );
      if (value.trim()) {
        await this.setState({
          [type]: {
            value:
              this.state.clickedValue && type === this.state.preType
                ? this.state.clickedValue
                : this.state[`${type}Name`],
            code: this.state[type].code,
            errors: !!validators,
            errorText: validators ? validators[0] : null,
            openModal: true,
          },
          [fieldName]: false,
        });
      } else if (!value.trim() && value.trim() === this.state.onChangeFinalValue) {
        validators = validationService.validateSingleField(
          "",
          educationConstraints.educationPageConstraints[`specialty.${type}`]
        );
        await this.setState({
          [type]: {
            value: "",
            code: "",
            errors: !!validators,
            errorText: validators ? validators[0] : null,
          },
          [fieldName]: false,
          openModal: true,
        });
      } else {
        await this.setState({
          [type]: {
            value:
              this.state.clickedValue && type === this.state.preType
                ? this.state.clickedValue
                : this.state[`${type}Name`],
            code: this.state[type].code,
            errors: !!validators,
            errorText: validators ? validators[0] : null,
          },
          [fieldName]: false,
          openModal: true,
        });
      }
    }
  };

  onExperienceChange = async (event, value) => {
    const validator = validationService.validateSingleField(
      event.target.value,
      educationConstraints.educationPageConstraints["specialty.yearsOfExperience"]
    );

    this.setState({ openModal: true });
    await this.setState({
      yearsOfExperience: {
        value: event.target.value,
        errors: !!validator,
        errorText: validator ? validator[0] : "",
      },
    });
  };

  renderOption(type, option) {
    return (
      <div>
        <>{option.name}</>
      </div>
    );
  }
  onChange = async (type, event, value) => {
    let fieldName;
    if (type === "primarySpecialty") fieldName = "openPrimarySpecialty";
    else if (type === "secondarySpecialty") fieldName = "openSecondarySpecialty";
    else if (type === "profession") fieldName = "openProfession";
    if (value) {
      const validator = validationService.validateSingleField(
        value,
        educationConstraints.educationPageConstraints[`specialty.${type}`]
      );
      if (type === "yearsOfExperience") {
        await this.setState({
          [type]: {
            value,
            errors: !!validator,
            errorText: validator ? validator[0] : null,
          },
        });
      } else {
        await this.setState({
          [type]: {
            value: value.name,
            code: value.code,
            errors: !!validator,
            errorText: validator ? validator[0] : null,
          },
          [fieldName]: false,
          primarySpecialtyName: value.name,
          openModal: true,
          [`${type}Name`]: value.name,
        });
      }
    }
  };

  handleDateChange = async (type, date, value) => {
    this.setState({ openModal: true });
    const fieldValidator = validationService.validateSingleField(
      value,
      educationConstraints.educationFormConstraints[type]
    );
    await this.setState({
      [type]: {
        value,
        errors: !!fieldValidator,
        errorText: fieldValidator ? fieldValidator[0] : null,
      },
      disableCancelButton: false,
    });
    if (Object.prototype.toString.call(date) === "[object Date]" && type === "graduationDate") {
      if (isNaN(date.getTime())) {
        await this.setState({
          [type]: {
            value,
            errors: false,
            errorText: null,
          },
          disableCancelButton: false,
        });
      }
    }
    const educationRecord = {};
    formFieldNames.educationSpecialtiesForm.forEach((field) => {
      educationRecord[field] =
        this.state[field].value && this.state[field].value.trim() !== ""
          ? this.state[field].value
          : null;
    });
    const validators = validationService.validateSetOfFields(
      educationRecord,
      educationConstraints.educationFormConstraints
    );
    this.setState({
      addEduDisableButton: !!validators,
      disableUpdateButton: !!validators,
    });
  };

  handleRemove(index) {
    this.setState({ openModal: true });
    const filteredArray = this.state.educationRecords.filter((_, i) => i !== index);
    if (filteredArray.length !== this.state.educationRecords.length) {
      this.setState({
        validationMap: new Map(),
      });
    }

    this.setState({
      educationRecords: filteredArray,
      openPopover: false,
      addEdButtonClicked: filteredArray.length !== 0,
      disableCancelButton: true,
      degree: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      graduationDate: {
        value: null,
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      vocationalSchool: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      city: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      state: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      nameOnDegree: {
        value: "",
        errors: true,
        errorText: this.props.t("onBoarding.errorMessage.required"),
      },
      hideEducationForm: filteredArray.length > 0,
    });
  }

  removeRec(type, index) {
    const filteredArray = this.state.educationRecords.filter((_, i) => i !== index);
    if (filteredArray.length !== this.state.educationRecords.length) {
      this.setState({
        validationMap: new Map(),
      });
    }
    this.setState({
      educationRecords: filteredArray,
    });
  }

  renderStateDropDown(type) {
    return (
      <div className={this.state.state.errors ? "Mui-error" : ""}>
        <FormControl variant="outlined">
          <InputLabel id="state" shrink>
            {this.props.t("onBoarding.basicInfo.stateLabel")}
          </InputLabel>
          <Select
            label={this.props.t("onBoarding.basicInfo.stateLabel")}
            notched
            labelId="state-label"
            id="state"
            value={this.state.state.value}
            onChange={this.onDropDownInputChange.bind(this, type)}
            MenuProps={SelectMenuProps}
            error={!!this.state.state.errors}
          >
            {usStates.states
              ? usStates.states
                  .sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase()
                      ? 1
                      : b.name.toLowerCase() > a.name.toLowerCase()
                      ? -1
                      : 0
                  )
                  .map((stateCode) => (
                    <MenuItem key={stateCode.code} value={stateCode.code}>
                      {stateCode.name}
                    </MenuItem>
                  ))
              : null}
          </Select>
          <FormHelperText>
            {this.state.state.errors ? this.state.state.errorText : ""}
          </FormHelperText>
        </FormControl>
      </div>
    );
  }

  renderCountryDropDown(type) {
    return (
      <div className={this.state.trainingCountry.errors ? "Mui-error" : ""}>
        <FormControl variant="outlined">
          <InputLabel id="trainingCountry" shrink>
            {this.props.t("onBoarding.edu&spec.countryOfTrainingLbl")}
          </InputLabel>
          <Select
            label={this.props.t("onBoarding.edu&spec.countryOfTrainingLbl")}
            notched
            labelId="trainingCountry"
            id="trainingCountry"
            value={this.state.trainingCountry.value}
            onChange={this.onDropDownInputChange.bind(this, type)}
            MenuProps={SelectMenuProps}
            error={!!this.state.trainingCountry.errors}
          >
            {countries
              ? countries.country
                  .sort((a, b) =>
                    a.toLowerCase() > b.toLowerCase()
                      ? 1
                      : b.toLowerCase() > a.toLowerCase()
                      ? -1
                      : 0
                  )
                  .map((country) => (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  ))
              : null}
          </Select>
          <FormHelperText>
            {this.state.trainingCountry.errors ? this.state.trainingCountry.errorText : ""}
          </FormHelperText>
        </FormControl>
      </div>
    );
  }

  renderDegreeDropDown(type) {
    return (
      <div className={this.state.degree.errors ? "Mui-error" : ""}>
        <FormControl variant="outlined">
          <InputLabel id="degree" shrink>
            {this.props.t("onBoarding.edu&spec.degreeLabel")}
          </InputLabel>
          <Select
            label={this.props.t("onBoarding.edu&spec.degreeLabel")}
            notched
            labelId="degree-label"
            id="degree"
            value={this.state.degree.value}
            onChange={this.onDropDownInputChange.bind(this, type)}
            displayEmpty
            MenuProps={SelectMenuProps}
            error={!!this.state.degree.errors}
          >
            {degrees.degrees
              ? degrees.degrees
                  .sort((a, b) =>
                    a.toLowerCase() > b.toLowerCase()
                      ? 1
                      : b.toLowerCase() > a.toLowerCase()
                      ? -1
                      : 0
                  )
                  .map((degree) => (
                    <MenuItem key={degree} value={degree}>
                      {degree}
                    </MenuItem>
                  ))
              : null}
          </Select>
          <FormHelperText>
            {this.state.degree.errors ? this.state.degree.errorText : ""}
          </FormHelperText>
        </FormControl>
      </div>
    );
  }

  onDropDownInputChange = async (type, event) => {
    this.setState({ openModal: true });
    const value = event.target.value;
    const fieldValidator = validationService.validateSingleField(
      value,
      educationConstraints.educationFormConstraints[type]
    );
    await this.setState({
      [type]: {
        value,
        errors: !!fieldValidator,
        errorText: fieldValidator ? fieldValidator[0] : null,
      },
      disableCancelButton: false,
    });
    const educationRecord = {};
    formFieldNames.educationSpecialtiesForm.forEach((field) => {
      educationRecord[field] =
        this.state[field].value && this.state[field].value.trim() !== ""
          ? this.state[field].value
          : null;
    });
    const validators = validationService.validateSetOfFields(
      educationRecord,
      educationConstraints.educationFormConstraints
    );
    this.setState({
      addEduDisableButton: !!validators,
      disableUpdateButton: !!validators,
    });
  };

  getSecondarySpecialty(type, options) {
    const filteredArray = options.filter((spec) => spec.name !== this.state.primarySpecialtyName);
    return filteredArray;
  }

  openPopoverPopup(index) {
    if (!this.state.openPopover) {
      this.setState({
        openPopover: !this.state.openPopover,
        deleteRowIndex: index,
      });
    }
  }

  closePopover(e) {
    this.setState({
      openPopover: false,
      openModal: false,
      canBeSavedPartially: true,
      btnType: "",
    });
  }

  handleLeaveBtnClick() {
    this.props.updateActiveFlag(0);
    this.props.history.push(BASIC_INFO_PAGE);
  }

  render() {
    return (
      <div>
        {this.state.openModal ? <OnBoardingConfirmModal enabled={this.state.openModal} /> : <></>}
        <Container fluid className="myProfileWrap">
          <Row>
            <MyProfileNavWrapper />
            <div className="myProfilePageWrap">
              <Container className="onBMainTxtField">
                {!this.state.canBeSavedPartially && this.state.btnType === "next" ? (
                  <>
                    <Container>
                      <Row>
                        <Col sm={12} xs={12} md={12} lg={12} className="onBServErrWrap">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="#fff"
                            width="20px"
                            height="20px"
                          >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                          </svg>
                          <span className="onBServErrTxt">
                            &nbsp;
                            {this.props.t("onBoarding.basicInfo.validationErrorText")}
                          </span>
                          <div className="onBServErrSubTxt">
                            {this.props.t("onBoarding.basicInfo.validationErrorText1")}
                          </div>
                          <ul className="onBServErrList">
                            {this.state.errorMessages.map((message) => (
                              <li>{message}</li>
                            ))}
                          </ul>
                        </Col>
                      </Row>
                    </Container>
                  </>
                ) : null}

                <Row>
                  <Col sm={12} xs={12} md={12} lg={12} className="onBSpecialtyBanner" />
                </Row>
                <Row>
                  <Col sm={12} xs={12} md={12} lg={12}>
                    <Row className={isMobile ? "mb-3" : "mb-4"}>
                      <Col
                        sm={12}
                        xs={12}
                        md={4}
                        lg={4}
                        className={
                          this.state.profession.errors
                            ? "onBTextFieldReq onBtextfiled"
                            : "onBtextfiled "
                        }
                      >
                        <div className="onBAutoContInfo">
                          <FormControl>
                            <Autocomplete
                              id="profession"
                              inputValue={
                                this.state.profession.value
                                  ? this.state.profession.value.charAt(0).toUpperCase() +
                                    this.state.profession.value.slice(1)
                                  : this.state.profession.value
                              }
                              autoHighlight
                              options={this.props.professionAutosuggest.slice(0, 5)}
                              getOptionLabel={(option) => option.name}
                              renderOption={(option) => <>{option.name}</>}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={this.props.t("onBoarding.edu&spec.profLabel")}
                                  labelWidth={200}
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  error={!!this.state.profession.errors}
                                  helperText={
                                    this.state.profession.errors
                                      ? this.state.profession.errorText
                                      : ""
                                  }
                                />
                              )}
                              onInputChange={this.onInputChange.bind(this, "profession")}
                              onChange={this.onChange.bind(this, "profession")}
                              open={this.state.openProfession}
                            />
                          </FormControl>
                        </div>
                      </Col>
                    </Row>
                    <Row className={isMobile ? "mb-3" : "mb-4"}>
                      <Col sm={12} xs={12} md={4} lg={4} className="onBtextfiled txtFldMBMob">
                        <div className="onBAutoContInfo">
                          <FormControl>
                            <Autocomplete
                              id="primarySpecialty"
                              inputValue={
                                this.state.primarySpecialty.value
                                  ? this.state.primarySpecialty.value
                                  : ""
                              }
                              autoHighlight
                              options={this.props.specialtyAutosuggest.slice(0, 5)}
                              getOptionLabel={(option) => option.name}
                              renderOption={(option) => <>{option.name}</>}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={this.props.t("onBoarding.edu&spec.specLabel1")}
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  error={
                                    this.state.primarySpecialty.value === ""
                                      ? false
                                      : !!this.state.primarySpecialty.errors
                                  }
                                />
                              )}
                              onInputChange={this.onInputChange.bind(this, "primarySpecialty")}
                              onChange={this.onChange.bind(this, "primarySpecialty")}
                              open={this.state.openPrimarySpecialty}
                              disabled={!this.state.profession.value}
                            />
                          </FormControl>
                        </div>
                      </Col>
                      <Col
                        sm={12}
                        xs={12}
                        md={4}
                        lg={4}
                        className={
                          this.state.yearsOfExperience.errors
                            ? "onBTextFieldReq onBtextfiled"
                            : "onBtextfiled "
                        }
                      >
                        <TextField
                          label={this.props.t("onBoarding.workExp.yearsofExperience")}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={this.state.yearsOfExperience.value}
                          onChange={this.onExperienceChange.bind(this)}
                          error={!!this.state.yearsOfExperience.errors}
                          helperText={
                            this.state.yearsOfExperience.errors
                              ? this.state.yearsOfExperience.errorText
                              : ""
                          }
                          autoComplete="new-yearsofExperience"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} xs={12} md={4} lg={4} className="onBtextfiled">
                        <div className="onBAutoContInfo">
                          <FormControl>
                            <Autocomplete
                              id="secondarySpecialty"
                              inputValue={
                                this.state.secondarySpecialty.value
                                  ? this.state.secondarySpecialty.value
                                  : ""
                              }
                              autoHighlight
                              options={this.getSecondarySpecialty(
                                this,
                                this.props.specialtyAutosuggest.slice(0, 5)
                              )}
                              getOptionLabel={(option) => option.name}
                              renderOption={(option) => <>{option.name}</>}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={this.props.t("onBoarding.edu&spec.specLabel2")}
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  error={
                                    this.state.secondarySpecialty.value === ""
                                      ? false
                                      : !!this.state.secondarySpecialty.errors
                                  }
                                />
                              )}
                              onInputChange={this.onInputChange.bind(this, "secondarySpecialty")}
                              onChange={this.onChange.bind(this, "secondarySpecialty")}
                              open={this.state.openSecondarySpecialty}
                              disabled={!this.state.primarySpecialty.value}
                            />
                          </FormControl>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <br />
                </Row>
              </Container>
              <Container>
                <Row>
                  <Col sm={12} xs={12} md={12} lg={12} className="onBEducationBanner" />
                </Row>
                {this.state.educationRecords.length < 1 ? (
                  <Row className="onBaddOneRecord">
                    {this.props.t("onBoarding.edu&spec.emptyCellLabel")}
                  </Row>
                ) : (
                  ""
                )}
              </Container>

              <Container className="onBFormBGMobile">
                <div className="onBMainTxtField">
                  {this.renderAddEductionBtn(this, this._onRowSelection)}
                  {!this.state.hideEducationForm ? (
                    <div className="onBboxShadow">
                      <Row className="onBoardingTable mb-4 txtFldMBMob">
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.vocationalSchool.errors
                              ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                              : "onBtextfiled txtFldMBMob"
                          }
                        >
                          <TextField
                            label={this.props.t("onBoarding.edu&spec.schoolLabel")}
                            type="Nursing/ Vocational School"
                            id="Nursing/ Vocational School"
                            onChange={this.handleChange.bind(this, "vocationalSchool")}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            helperText={
                              this.state.vocationalSchool.errors
                                ? this.state.vocationalSchool.errorText
                                : ""
                            }
                            error={!!this.state.vocationalSchool.errors}
                            value={
                              this.state.vocationalSchool.value
                                ? this.state.vocationalSchool.value.charAt(0).toUpperCase() +
                                  this.state.vocationalSchool.value.slice(1)
                                : this.state.vocationalSchool.value
                            }
                            autoComplete="new-vocationalSchool"
                          />
                        </Col>

                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.city.errors
                              ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                              : "onBtextfiled txtFldMBMob"
                          }
                        >
                          <TextField
                            id="City"
                            label={this.props.t("onBoarding.basicInfo.cityLabel")}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="City"
                            onChange={this.handleChange.bind(this, "city")}
                            helperText={this.state.city.errors ? this.state.city.errorText : ""}
                            error={!!this.state.city.errors}
                            value={
                              this.state.city.value
                                ? this.state.city.value.charAt(0).toUpperCase() +
                                  this.state.city.value.slice(1)
                                : this.state.city.value
                            }
                            autoComplete="new-city"
                          />
                        </Col>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.state.errors
                              ? "onBTextFieldReq onBtextfiled"
                              : "onBtextfiled "
                          }
                        >
                          <div className="onBAutoContInfo">
                            <div>{this.renderStateDropDown("state")}</div>
                          </div>
                        </Col>
                      </Row>
                      <Row className={isMobile ? "mb-3" : "mb-4"}>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.degree.errors
                              ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                              : "onBtextfiled txtFldMBMob"
                          }
                        >
                          <div className="onBAutoContInfo">
                            <FormControl>
                              <div>{this.renderDegreeDropDown("degree")}</div>
                            </FormControl>
                          </div>
                        </Col>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.graduationDate.errors
                              ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                              : "onBtextfiled txtFldMBMob"
                          }
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                inputVariant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                className="datePick"
                                views={["year", "month", "date"]}
                                allowKeyboardControl={false}
                                autoOk="false"
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-Specialty"
                                label={this.props.t("onBoarding.edu&spec.gradDateLabel")}
                                value={this.state.graduationDate.value}
                                onChange={this.handleDateChange.bind(this, "graduationDate")}
                                maxDate={new Date()}
                                maxDateMessage={this.props.t(
                                  "onBoarding.edu&spec.gradMaxDateMessage"
                                )}
                                invalidDateMessage={this.props.t(
                                  "onBoarding.errorMessage.validDateError"
                                )}
                                {...(this.state.graduationDate.errors
                                  ? {
                                      helperText: this.state.graduationDate.errorText,
                                      error: !!this.state.graduationDate.errors,
                                    }
                                  : {})}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Col>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.nameOnDegree.errors
                              ? "onBTextFieldReq onBtextfiled"
                              : "onBtextfiled"
                          }
                        >
                          <TextField
                            label={this.props.t("onBoarding.edu&spec.degreeNameLabel")}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="Name on Degree"
                            onChange={this.handleChange.bind(this, "nameOnDegree")}
                            helperText={
                              this.state.nameOnDegree.errors
                                ? this.state.nameOnDegree.errorText
                                : ""
                            }
                            error={!!this.state.nameOnDegree.errors}
                            value={
                              this.state.nameOnDegree.value
                                ? this.state.nameOnDegree.value.charAt(0).toUpperCase() +
                                  this.state.nameOnDegree.value.slice(1)
                                : this.state.nameOnDegree.value
                            }
                            autoComplete="new-nameOnDegree"
                          />
                        </Col>
                      </Row>
                      <Row className={isMobile ? "mb-3" : "mb-4"}>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.trainingCountry.errors
                              ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                              : "onBtextfiled txtFldMBMob"
                          }
                        >
                          <div className="onBAutoContInfo">
                            <div>{this.renderCountryDropDown("trainingCountry")}</div>
                          </div>
                        </Col>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.course.errors
                              ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                              : "onBtextfiled txtFldMBMob"
                          }
                        >
                          <TextField
                            label={this.props.t("onBoarding.edu&spec.certificationLabel")}
                            type="Certification"
                            onChange={this.handleChange.bind(this, "course")}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            helperText={this.state.course.errors ? this.state.course.errorText : ""}
                            error={
                              this.state.course.value === "" ? false : !!this.state.course.errors
                            }
                            value={
                              this.state.course.value
                                ? this.state.course.value.charAt(0).toUpperCase() +
                                  this.state.course.value.slice(1)
                                : this.state.course.value
                            }
                            autoComplete="new-certification"
                          />
                        </Col>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.certificationExpirationDate.errors
                              ? "onBTextFieldReq onBtextfiled "
                              : "onBtextfiled"
                          }
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                inputVariant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                className="datePick"
                                views={["year", "month", "date"]}
                                allowKeyboardControl={false}
                                autoOk="false"
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-certification-date"
                                label={this.props.t("onBoarding.edu&spec.certificationExpDateLbl")}
                                value={this.state.certificationExpirationDate.value}
                                onChange={this.handleDateChange.bind(
                                  this,
                                  "certificationExpirationDate"
                                )}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                {...(this.state.certificationExpirationDate.errors
                                  ? {
                                      helperText: this.state.certificationExpirationDate.errorText,
                                    }
                                  : {})}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.visaScreenExpiration.errors
                              ? "onBTextFieldReq onBtextfiled"
                              : "onBtextfiled"
                          }
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                inputVariant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                className="datePick"
                                views={["year", "month", "date"]}
                                allowKeyboardControl={false}
                                autoOk="false"
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id=""
                                label={this.props.t("onBoarding.edu&spec.visaScreenExpirationLbl")}
                                value={this.state.visaScreenExpiration.value}
                                onChange={this.handleDateChange.bind(this, "visaScreenExpiration")}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                {...(this.state.visaScreenExpiration.errors
                                  ? {
                                      helperText: this.state.visaScreenExpiration.errorText,
                                    }
                                  : {})}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Col>
                      </Row>
                      {this.renderButtons(this, this._onRowSelection)}
                    </div>
                  ) : null}

                  <Row>
                    <Col sm={12} xs={12} md={12} lg={12}>
                      <Row className="onBoardingTable" id="onBoardingTableId">
                        <Col sm={12} md={12} lg={12} xs={12}>
                          <div className="tableWrapper">
                            {!this.state.educationRecords.length < 1 ? (
                              <div className="table tableCollapse">
                                <div className="tableRow tableRowHead">
                                  <div className="tableCell statusColumn columnHeads">
                                    {this.props.t("onBoarding.edu&spec.statusColLabel")}
                                  </div>
                                  <div className="tableCell degreeColumn columnHeads">
                                    {this.props.t("onBoarding.edu&spec.degreeLabel").slice(0, -2)}
                                  </div>
                                  <div className="tableCell schoolNameColumn columnHeads">
                                    {this.props.t("onBoarding.edu&spec.schoolColLabel")}
                                  </div>
                                  <div className="tableCell cityColumn columnHeads">
                                    {this.props.t("onBoarding.basicInfo.cityLabel").slice(0, -2)}
                                  </div>
                                  <div className="tableCell stateColumn columnHeads">
                                    {this.props.t("onBoarding.edu&spec.stateColLabel")}
                                  </div>
                                  <div className="tableCell actionColumn columnHeads">
                                    {this.props.t("onBoarding.edu&spec.actionColLabel")}
                                  </div>
                                </div>

                                {this.state.educationRecords.map((record, index) => (
                                  <div className="tableRow" key={index}>
                                    {!validationService.validateSetOfFields(
                                      record,
                                      educationConstraints.educationFormConstraints
                                    ) ? (
                                      <div className="tableCell statusColumn">
                                        <div className="tableHeads">
                                          {this.props.t("onBoarding.edu&spec.statusColLabel")}
                                        </div>
                                        <div className="table-content">
                                          <Chip label="Complete" className="onBSpeCompleteStatus" />
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="tableCell statusColumn">
                                        <Chip
                                          label="Incomplete"
                                          className="onBSpeInCompleteStatus"
                                        />
                                      </div>
                                    )}
                                    <div className="tableCell degreeColumn">
                                      <div className="tableHeads">
                                        {this.props
                                          .t("onBoarding.edu&spec.degreeLabel")
                                          .slice(0, -2)}
                                      </div>
                                      <div className="table-content ">{record.degree}</div>
                                    </div>
                                    <div className="tableCell schoolNameColumn">
                                      <div className="tableHeads">
                                        {this.props.t("onBoarding.edu&spec.schoolColLabel")}
                                      </div>
                                      <div className="table-content ">
                                        {record.vocationalSchool}
                                      </div>
                                    </div>
                                    <div className="tableCell cityColumn">
                                      <div className="tableHeads">
                                        {this.props
                                          .t("onBoarding.basicInfo.cityLabel")
                                          .slice(0, -2)}
                                      </div>
                                      <div className="table-content">{record.city}</div>
                                    </div>
                                    <div className="tableCell stateColumn">
                                      <div className="tableHeads">
                                        {this.props.t("onBoarding.edu&spec.stateColLabel")}
                                      </div>
                                      <div className="table-content ">{record.state}</div>
                                    </div>
                                    <div className="tableCell actionColumn onBTableIcons">
                                      <div className="tableHeads">
                                        {this.props.t("onBoarding.edu&spec.actionColLabel")}
                                      </div>
                                      <div className="table-content ">
                                        {record.action}
                                        <Button
                                          className="onBEditIcon"
                                          onClick={this.editRecord.bind(this, index)}
                                          disabled={this.state.disableActions}
                                        >
                                          <div className="editIconMobile">
                                            <svg
                                              className="onBEditIconSVG"
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              width="24"
                                            >
                                              <path d="M0 0h24v24H0V0z" fill="none" />
                                              <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM5.92 19H5v-.92l9.06-9.06.92.92L5.92 19zM20.71 5.63l-2.34-2.34c-.2-.2-.45-.29-.71-.29s-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41z" />
                                            </svg>
                                          </div>
                                        </Button>
                                        <Button
                                          className="onBDeleteIcon"
                                          onClick={this.openPopoverPopup.bind(this, index)}
                                          disabled={this.state.disableActions}
                                        >
                                          <div>
                                            <svg
                                              className="onBDeleteIconSVG"
                                              xmlns="http://www.w3.org/2000/svg"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              width="24"
                                            >
                                              <path d="M0 0h24v24H0V0z" fill="none" />
                                              <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
                                            </svg>
                                          </div>
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} xs={12} md={12} lg={12}>
                          {this.state.openPopover ? (
                            <div className="onBDeletePopupWrap onBSpecialtyPopupMob">
                              <Button
                                className="onBdeletePopoverClose"
                                onClick={this.closePopover.bind(this)}
                              >
                                x
                              </Button>
                              <div className="onBDeletePopupTxt">
                                {" "}
                                {this.props.t("onBoarding.edu&spec.recordDeleteTxt1")}
                              </div>
                              {this.props.t("onBoarding.edu&spec.recordDeleteTxt2")}
                              <br />
                              <br />
                              <div className="d-flex justify-content-center">
                                <SecondaryButton
                                  onClick={this.handleRemove.bind(this, this.state.deleteRowIndex)}
                                >
                                  {" "}
                                  {this.props.t("onBoarding.edu&spec.deleteConfirmLbl")}{" "}
                                </SecondaryButton>
                                &nbsp; &nbsp;
                                <PrimaryButton onClick={this.closePopover.bind(this)}>
                                  {this.props.t("onBoarding.edu&spec.cancelBtnLabel")}
                                </PrimaryButton>
                              </div>
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* Education section Ends */}

              {/* Legal Section Starts */}
              <div>
                <Container className="onBFormBGMobile">
                  <Row className="pt-3 pb-3">
                    <Col sm={12} xs={12} md={12} lg={12}>
                      <Row>
                        <Col sm={12} xs={12} md={12} lg={12}>
                          <div className="onBNextBtn d-flex">
                            {!this.state.backButtonClicked && (
                              <>
                                <TertiaryButton
                                  size="medium"
                                  className={this.props.classes.outlineBtnColor}
                                  onClick={this.handleBackBtnClick.bind(this)}
                                >
                                  {this.props.t("onBoarding.edu&spec.backBtnLabel")}
                                </TertiaryButton>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {this.props.onBoardingCompletedSteps[0] &&
                                this.props.onBoardingCompletedSteps[1] &&
                                this.props.onBoardingCompletedSteps[2] &&
                                this.props.onBoardingCompletedSteps[3] &&
                                this.props.onBoardingCompletedSteps[4] ? (
                                  <SecondaryButton
                                    size="medium"
                                    className={this.props.classes.nextBtnMarginleft}
                                    onClick={this.handleBtnClick.bind(this, "Next")}
                                  >
                                    {this.props.t("onBoarding.basicInfo.btnLabel")}
                                  </SecondaryButton>
                                ) : this.props.onBoardingCompletedSteps[0] &&
                                  this.props.onBoardingCompletedSteps[2] &&
                                  this.props.onBoardingCompletedSteps[3] &&
                                  this.props.onBoardingCompletedSteps[4] ? (
                                  <SecondaryButton
                                    size="medium"
                                    className={this.props.classes.nextBtnMarginleft}
                                    onClick={this.handleBtnClick.bind(this, "Done")}
                                  >
                                    {this.props.t("onBoarding.skillChecklist.completeAppBtnLbl")}
                                  </SecondaryButton>
                                ) : (
                                  <SecondaryButton
                                    size="medium"
                                    className={this.props.classes.nextBtnMarginleft}
                                    onClick={this.handleBtnClick.bind(this, "Next")}
                                  >
                                    {this.props.t("onBoarding.basicInfo.btnLabel")}
                                  </SecondaryButton>
                                )}
                              </>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            <PopupModal
              open={!this.state.canBeSavedPartially && this.state.btnType === "back"}
              closePopover={this.closePopover.bind(this)}
              handleLeaveBtnClick={this.handleLeaveBtnClick.bind(this)}
            />
          </Row>
        </Container>
        {this.state.backButtonClicked && (
          <div className="onBBackBtnPopUpPosition" id="backButtonPopUp">
            <IconButton
              aria-label="Close"
              className="onBPopUpCloseBtn"
              onClick={this.handleClose.bind(this)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            <div className="onBBackBtnPopUpTxt textCenter">
              {this.props.t("onBoarding.edu&spec.yourChangesPopupTxt")}
              <span className="onBBackBtnPopUpNote">
                {this.props.t("onBoarding.edu&spec.yourChangesPopupTxt1")}
              </span>
            </div>
            <div className="textCenter">
              <Button className="onBPopupPrimaryBtn" onClick={this.handleYesClicked.bind(this)}>
                Yes
              </Button>
              &nbsp;&nbsp;
              <Button className="onBPopupBtn" onClick={this.handleNoClicked.bind(this)}>
                No
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  specialtyAutosuggest: state.globalSearch.specialty,
  professionAutosuggest: state.profession.profession,
  locationAutosuggest: state.globalSearch.location,
  company: state.company.company,
  companyCode: state.company.company,
  email: state.auth.email,
  onboardingSpecialtiesAndEducation: state.onBoarding.specialtiesAndEducation,
  onBoardingCompletedSteps: state.onBoarding.completedSteps,
  specialtiesAndEducation: state.onBoarding.specialtiesAndEducation,
  userId: state.auth.userId,
  applicantId: state.candidate.applicantId,
  candidateSpecialties: state.auth.candidateSpecialties,
  candidateId: state.auth.candidateId,
  saveBasicInfoResponse: state.onBoarding.saveBasicInfoResponse,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  phone: state.auth.phone,
  recruiterDetails: state.consultant.recruiterDetails,
  onboardingBasicInfo: state.onBoarding.basicInfo,
  onBoardingReferences: state.onBoarding.references,
  onboardingWorkExperience: state.onBoarding.workExperience,
});

const mapDispatchToProps = (dispatch) => ({
  getSpecialties: (term, isInitialSpecialty, companyId, professionCode) =>
    dispatch(fetchSpecialties(term, isInitialSpecialty, companyId, professionCode)),
  getProfessions: (term, companyId) => dispatch(fetchProfessions(term, companyId)),
  getLocation: (term) => dispatch(fetchLocation(term)),
  saveBasicInformation: (data, pageRedirectedFrom, pageToRedirect, triggeringPoint) =>
    dispatch(saveBasicInformation(data, pageRedirectedFrom, pageToRedirect, triggeringPoint)),
  updateCompleteFlag: (completedSteps) => dispatch(updateCompleteFlag(completedSteps)),
  updateActiveFlag: (activeSteps) => dispatch(updateActiveFlag(activeSteps)),
  clearBasicInfoResponse: () => dispatch(clearBasicInfoResponse()),
  saveUserProfile: (reqObject, references, workExperience, basicInfo) =>
    dispatch(saveUserProfile(reqObject, references, workExperience, basicInfo)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(OnBoardingSpecialties)))
);
